export const getACTIVITY = /* GraphQL */ `
    query GetACTIVITY($id: ID!) {
        getACTIVITY(id: $id) {
            id
            entity
            entityID
            userFullName
            userID
            action
            field
            value
            createdAt
            updatedAt
        }
    }
`;

export const activityByEntityID = /* GraphQL */ `
    query ActivityByEntityID(
        $entityID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelACTIVITYFilterInput
        $limit: Int
        $nextToken: String
    ) {
        activityByEntityID(
            entityID: $entityID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                entity
                entityID
                userFullName
                userID
                action
                field
                value
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const createACTIVITY = /* GraphQL */ `
    mutation CreateACTIVITY(
        $input: CreateACTIVITYInput!
        $condition: ModelACTIVITYConditionInput
    ) {
        createACTIVITY(input: $input, condition: $condition) {
            id
            entity
            entityID
            userFullName
            userID
            action
            field
            value
            createdAt
            updatedAt
        }
    }
`;
