import React, {useState, useEffect, useMemo} from 'react';

import {
  IconButton,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  Box,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import InputAdornment from '@mui/material/InputAdornment';
import TablePagination from "@mui/material/TablePagination";

import { useNavigate, useLocation, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { clientActions, categoryState } from "redux/clientSlice";

import SortableTHeadCell from "components/shared/SortableTHeadCell";
import AddCategoryModal from "./AddCategoryModal"
import Snackbar from "components/shared/Snackbar";
import TableLoadingIndicator from "components/shared/TableLoadingIndicator";

import {
  getNavigationPath,
  useNavPath,
} from "constants/routes";
import {findInStringsIgnoreCase} from "utils/stringUtils";

function Row(props) {
  const { row = {}, onClick } = props;
  const { id, name, vendors, createdAt, updatedAt } = row;
  const createdDate = new Date(createdAt).toLocaleDateString('en-US');
  const updatedDate = new Date(updatedAt).toLocaleDateString('en-US');
  let coverageTypes = []
  if(typeof row?.requiredCoverages === "string") {
    coverageTypes = JSON.parse(row.requiredCoverages)?.map(requiredCoverage => requiredCoverage.insuranceType)
  }

  return (
    <React.Fragment>
      <TableRow onClick={()=>onClick(id)} sx={{
        '&:hover': {
          cursor: "pointer",
          backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
      }}>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left">{vendors?.items?.filter(vendor=>vendor.clientVendor !== null).length || "0"}</TableCell>
        <TableCell align="left">{coverageTypes.join(", ")}</TableCell>
        <TableCell align="left">{createdDate !== 'Invalid Date' ? createdDate : ""}</TableCell>
        <TableCell align="left">{updatedDate !== 'Invalid Date' ? updatedDate : ""}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CategoriesTable() {
  const [sortBy, setSortBy] = useState("createdAt")
  const [sortAsc, setSortAsc] = useState(false)
  const theadProps = {sortBy, setSortBy, sortAsc, setSortAsc}
  const [searchPhrase, setSearchPhrase] = useState("")
  const navigate = useNavigate()

  const { userOrgType, selectedClient } = useSelector(organizationState)
  const categories = useSelector((state) => state.client.categories[selectedClient?.clientID]) || []

  const NAV_PATH = useNavPath(userOrgType)
  const location = useLocation()
  const { state } = location
  const { clientId } = useParams()
  const dispatch = useDispatch()
  const [snackOpen, setSnackOpen] = useState(!!state?.showNew)
  const [newCategoryName, setNewCategoryName] = useState(false)
  const [message, setMessage] = useState();
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [initialLoading, setInitialLoading] = useState(false)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navToCategory = (categoryId) => {
    const destination = getNavigationPath(NAV_PATH.CLIENT_CATEGORY_DETAILS, {clientId, categoryId})
    navigate(destination)
  }
  useEffect(() => {
    if(selectedClient?.clientID) {
      fetchCategories()
    } else {
      // todo: nav away
    }
  }, [selectedClient?.clientID])

  async function fetchCategories() {
    setInitialLoading(true)
    await dispatch(clientActions.fetchCategories(selectedClient.clientID))
    setInitialLoading(false)
  }

  const filteredCategories = useMemo(() => {
    if(categories?.length) {
      let toSort = [...categories]
      if(searchPhrase) {
        toSort = toSort.filter((category) => findInStringsIgnoreCase(searchPhrase, [
          category.name,
          new Date(category.createdAt).toLocaleDateString('en-US'),
          new Date(category.updatedAt).toLocaleDateString('en-US'),
          JSON.parse(category.requiredCoverages)?.map(requiredCoverage => requiredCoverage.insuranceType).join("")
        ]))
      }
      const sorted = toSort.sort((a,b) => {
        let larger = (a[sortBy] || "") > (b[sortBy] || "")
        if(sortBy === "createdAt") {
          larger = (new Date(a[sortBy])) > (new Date(b[sortBy]))
        }
        if (sortBy === "updatedAt") {
          larger = (new Date(a[sortBy])) > (new Date(b[sortBy]))
        }
        if(sortBy === "numVendors") {
          larger = a?.vendors?.items?.length > b?.vendors?.items?.length
        }
        if(larger) {
          return sortAsc ? 1 : -1
        }
        return sortAsc ? -1 : 1
      })
      return sorted
    }
    return categories
  }, [categories.length, sortBy, sortAsc, searchPhrase])

  async function onCreateCategory() {
    if(newCategoryName) {
      setSaving(true)
      const result = await dispatch(clientActions.createCategory({name: newCategoryName, clientID: selectedClient.clientID}))
      if(result?.payload?.success) {
        setNewCategoryName(false)
        setMessage({
          severity: "success",
          text: "Successfully created category"
        })
      } else {
        setMessage({
          severity: "error",
          text: "Unable to create category"
        })
      }
      setSaving(false)
    }
  }

  return (
    <>
      <Snackbar message={message} setMessage={setMessage} />
      <AddCategoryModal
          open={newCategoryName !== false}
          loading={saving}
          categoryName={newCategoryName}
          onClose={()=>setNewCategoryName(false)}
          onSave={onCreateCategory}
          onUpdate={(updated) => setNewCategoryName(updated)}
      />
      <Box>
        <Typography component="span" variant="h5">Categories</Typography>
        <Box sx={{float: "right"}}>
          <Button startIcon={<AddIcon />} onClick={()=>setNewCategoryName("")}>
            Add New Category
          </Button>
        </Box>
      </Box>
      <TableContainer style={{overflow: "visible"}} className="categories-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{border: "none"}} />
              <TableCell colSpan={1} sx={{border: "none", pr: 0}}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search category"
                  id="fullWidth"
                  value={searchPhrase}
                  onChange={(e) => setSearchPhrase(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <SortableTHeadCell label="Category" sortId={"name"} align="left" {...theadProps} />
              <SortableTHeadCell label="Number of Subcontractors" sortId={"numVendors"} align="left" {...theadProps} />
              <TableCell align="left">Required Coverages</TableCell>
              <SortableTHeadCell label="Date Created" sortId={"createdAt"} align="left" {...theadProps} />
              <SortableTHeadCell label="Date Modified" sortId={"updatedAt"} align="left" {...theadProps} />
            </TableRow>
          </TableHead>
          <TableBody>
            { initialLoading &&
              <TableLoadingIndicator colSpan={4}/>
            }
            {
              !initialLoading &&
              filteredCategories
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, rowIndex) => (
                <Row
                  key={rowIndex}
                  row={row}
                  onClick={navToCategory} rowIndex={rowIndex} showNew={state?.showNew}
                />
              ))
            }
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={categories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Snackbar
        open={snackOpen}
        autoHideDuration={1000}
        onClose={()=>setSnackOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Successfully added vendor!
        </Alert>
      </Snackbar>
    </>
  );
}
