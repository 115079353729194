import React, {useState, useEffect} from "react";
import {
  Button,
  TextField,
  Stack,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  CircularProgress
} from "@mui/material";
import Modal from "components/shared/Modal";
import LoaderButton from "components/shared/LoaderButton";
import {getEmailFieldError} from "utils/validationUtils";

export default({open, onClose, onSubmit, loading = false, user, roles = []}) => {
  const [userData, setUserData] = useState({})
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if(user) {
      setUserData({
        ...user,
        role: user.role || roles[0]?.value
      })
    } else {
      setUserData({
        role: roles[0]?.value
      })
    }
  }, [user, roles])

  useEffect(()=>{
    if(!open) {
      setUserData({})
      setFormErrors({})
    }
  }, [open])

  function updateUserField(event, trim=false, skipValidation=false) {
    const key = event.target.name
    let value = event.target.value
    if(!skipValidation && key === "email") {
      value = String(event.target.value).trim().toLowerCase()
      const error = getEmailFieldError(event.target)
      const newFormErrors = {...formErrors}
      if(error) {
        newFormErrors.email = ""
      } else {
        delete newFormErrors.email
      }
      setFormErrors(newFormErrors)
    }
    setUserData({
      ...userData,
      [key]: trim ? String(value).trim() : value
    })
  }

  function validateForm(event) {
    updateUserField(event, true, true)

    const newFormErrors = {...formErrors}
    if(event.target.name === "email") {
      const error = getEmailFieldError(event.target)
      if(!error) {
        delete newFormErrors.email
      } else {
        newFormErrors.email = error
      }
    }
    setFormErrors(newFormErrors)
  }

  const fieldProps = {
    onChange: updateUserField,
    onBlur: validateForm,
    fullWidth: true
  }

  return (
    <Modal
      open={open}
      title={!!user ? "Update User" : "Add User"}
      content={
        <Stack spacing={2} mt={2}>
          <TextField
            {...fieldProps}
            name="email"
            required
            label={"Email Address"}
            inputProps={{pattern:"^\\S+@\\S+\\.\\S+$"}}
            error={!!formErrors.email}
            helperText={formErrors.email || "This is also the username they will use to log in."}
            disabled={!!user}
            value={userData.email}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              {...fieldProps}
              name="firstName"
              label="First Name"
              value={userData.firstName}
            />
            <TextField
              {...fieldProps}
              name="lastName"
              label="Last Name"
              value={userData.lastName}
            />
          </Stack>
          <FormControl>
            <FormLabel id="roles-group-label">User Role</FormLabel>
            <RadioGroup
              {...fieldProps}
              row
              aria-labelledby="roles-group-label"
              defaultValue={user?.role || roles[0]?.value}
              name="role"
            >
              {
                roles && roles.map(role => (
                  <FormControlLabel value={role.value} control={<Radio />} label={role.label} key={role.value}/>
                ))
              }
            </RadioGroup>
          </FormControl>
        </Stack>
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <LoaderButton
            loading={loading}
            variant="contained"
            onClick={() => onSubmit && onSubmit(userData)}
            disabled={loading || !userData.email || Object.keys(formErrors).length}
          >
            {
              !!user ? "Update" : "Invite User"
            }
          </LoaderButton>
        </>
      }
    />
  )
}
