import React, {useState, useEffect} from "react";
import { API } from "aws-amplify"
import {
  acord25ScanByCertificate,
  updateACORD25SCAN,
  onUpdateACORD25SCAN,
  tableRowAcord25ScanByCertificate
} from "graphql/vendor"

import {Storage} from "@aws-amplify/storage";

export default function useScanData(certificateID, repeatOnPending, showTableDataOnly) {
  const [scanData, setScanData] = useState()
  let sub;

  async function getScanData(callback) {
    let result;
    const query = showTableDataOnly ? tableRowAcord25ScanByCertificate : acord25ScanByCertificate;
    try {
      result = await API.graphql({
        query: query,
        variables: {
          certificateID,
          sortDirection: "DESC",
          limit: 1
        },
      })
    } catch (error) {
      result = error;
    }
    if(result?.data?.acord25ScanByCertificate?.items?.length) {
      const scan = result.data.acord25ScanByCertificate.items[0]
      if(scan.status === "COMPLETED") {
        const content = await Storage.get(`input/${certificateID}/${scan.id}/${scan.acord25File}`, {
          level: 'protected',
          identityId: 'scanning'
        })
        scan.files = [{name: scan.acord25File, content}]
      }
      await setScanData(scan)
      if(scan.status === "PENDING" && repeatOnPending) {
        sub = API.graphql({
          query: onUpdateACORD25SCAN,
          variables: {
            filter: {
              id: { eq: scan.id }
            }
          }
        }).subscribe({
          next: ({ value }) => {
            if(value?.data?.onUpdateACORD25SCAN) {
              setScanData({
                ...scan,
                ...value.data.onUpdateACORD25SCAN,
              })

              if(value.data.onUpdateACORD25SCAN.status !== "PENDING") {
                sub.unsubscribe()
              }
            }
          },
        })
      }
      callback && callback()
    } else {
      setScanData(undefined)
    }
  }

  async function discardScanData() {
    if(scanData?.id) {
      const result = await API.graphql({
        query: updateACORD25SCAN,
        variables: {
          input: {
            id: scanData.id,
            status: "CANCELED",
          }
        }
      })
      if(result?.data?.updateACORD25SCAN) {
        getScanData()
      }
    }
  }

  useEffect(() => {
    if(certificateID && certificateID !== "create") {
      getScanData()
      return () => {
        if(sub) {
          sub.unsubscribe();
        }
      }
    }
  }, [certificateID])

  return {scanData, getScanData, discardScanData}
}
