import React from "react";
import { Button, TextField, Typography, ButtonGroup, Stack, Box, Checkbox, FormControlLabel, Select, MenuItem } from "@mui/material";
import { SubtitleInline, SectionTitle } from "components/shared/Typography";
import OrganizationDetailsForm from "components/shared/OrganizationDetailsForm";
import { getCertificateHolder, getClientLabel } from "constants/organization_types";

export default function AffiliateTypeOrganizationConfiguration({
  organization = {},
  validationErrors = {},
  editOrganization = false,
  updatedCertificateHolder,
  updatedCertificateHolderAddress,
  certificateHolderDetailsStyles = {},
  haveUpdates = () => {},
  setEditOrganization = () => { },
  setUpdatedClientLabel = () => {},
  setUpdatedCertificateHolder = () => {},
  handleCancelUpdateOrganization = () => {},
  handleConfirmUpdateOrganization = () => {},
  setUpdatedCertificateHolderAddress = () => {},
}) {
  return (
    <Box>
      <SectionTitle sx={{ mb: 2 }}>
        Configuration
        {editOrganization ? (
          <ButtonGroup sx={{ ml: 2 }}>
            <Button onClick={handleCancelUpdateOrganization}>Cancel</Button>
            <Button disabled={!haveUpdates() || Object.entries(validationErrors).length > 0} onClick={handleConfirmUpdateOrganization}>
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <Button onClick={() => setEditOrganization(true)}>Edit</Button>
        )}
      </SectionTitle>
      <Stack spacing={3} direction="column">
        <Stack spacing={10} direction="column">
          <div>
            <SubtitleInline>Client Label:</SubtitleInline>
            {editOrganization ? (
              <TextField
                fullWidth
                required
                variant="standard"
                defaultValue={getClientLabel(organization)}
                onChange={(e) => setUpdatedClientLabel(e.target.value)}
              />
            ) : (
              <Typography variant="body1">{getClientLabel(organization)}</Typography>
            )}
          </div>
        </Stack>
        <Stack spacing={1} direction="column">
          <div>
            <SubtitleInline>Global Certificate Holder:</SubtitleInline>
            {editOrganization ? (
              <TextField
              fullWidth
              required
              variant="standard"
              placeholder="NOT SET"
              defaultValue={getCertificateHolder(organization)}
              onChange={(e) => setUpdatedCertificateHolder(e?.target?.value?.trim())}
              />
            ) : getCertificateHolder(organization) ? (
              <Typography variant="body1">{getCertificateHolder(organization)}</Typography>
            ) : (
              <Typography color="textSecondary" variant="body1">
                NOT SET
              </Typography>
            )}
          </div>
          {
            updatedCertificateHolder &&
            <Stack sx={{ ...(!editOrganization && { maxWidth: "800px" }) }} spacing={2}>
            <SubtitleInline>Global Certificate Holder Address:</SubtitleInline>
            <OrganizationDetailsForm
              organizationDetails={updatedCertificateHolderAddress}
              setOrganizationDetails={setUpdatedCertificateHolderAddress}
              businessLabelProp={" "}
              variant={"standard"}
              isEditable={editOrganization}
              showDetails={false}
              textFieldStyles={certificateHolderDetailsStyles}
              />
          </Stack>
            }
        </Stack>

      </Stack>
    </Box>
  );
}
