import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {organizationState,organizationActions} from "redux/organizationSlice";
import { AmplifyS3Image } from '@aws-amplify/ui-react-v1/legacy';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Box,
  Typography,
  Button,
} from '@mui/material';
import AddIcon from "@mui/icons-material/Add"
import { useNavigate } from "react-router-dom";
import {ADMIN_NAV_PATH, getNavigationPath} from "constants/routes";
import Snackbar from "components/shared/Snackbar";
import AddOrganizationModal from "components/shared/AddOrganizationModal"
import TableLoadingIndicator from "components/shared/TableLoadingIndicator";

export default() => {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [showAddAgencyModal, setShowAddAgencyModal] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { organizations } = useSelector(organizationState)

  useEffect(() => {
    dispatch(organizationActions.resetOrganizations())
  }, [])

  useEffect(() => {
    if(!organizations?.length) {
      loadAgencies()
    }
  }, [organizations])

  async function loadAgencies() {
    setInitialLoading(true)
    await dispatch(organizationActions.getAgencies())
    setInitialLoading(false)
  }

  function handleAgencySelect(id) {
    dispatch(organizationActions.setSelectedAgency({id: id}))
    navigate(getNavigationPath(ADMIN_NAV_PATH.AGENCY_CLIENTS, {agencyId: id}))
  }

  async function createAgency(inputs) {
    setLoading(true)
    const result = await dispatch(organizationActions.createAgency(inputs))

    if(result?.payload?.success) {
      dispatch(organizationActions.getAgencies())
      setShowAddAgencyModal(false)
      setMessage({
        severity: "success",
        text: "Successfully created affiliate"
      })
    } else {
      setMessage({
        severity: "error",
        text: "Unable to create affiliate"
      })
    }
    setLoading(false)
  }

  return (
    <>
      <Snackbar message={message} setMessage={setMessage} />
      <AddOrganizationModal
        open={showAddAgencyModal}
        onClose={()=>setShowAddAgencyModal(false)}
        onSave={(inputs)=>createAgency(inputs)}
        loading={loading}
        isClient={false}
      />
      <Box sx={{mb: 2}}>
        <Typography variant={"h5"} component={"span"}>Affiliates</Typography>
        <Button
          sx={{float: "right"}}
          startIcon={<AddIcon />}
          onClick={()=>setShowAddAgencyModal(true)}
        >Add An Affiliate</Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell /> {/* avatar */}
              <TableCell>Organization Name</TableCell>
              <TableCell>Logo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              initialLoading &&
              <TableLoadingIndicator colSpan={3}/>
            }
            {
              !initialLoading &&
              organizations?.map((organization, index) => (
                <TableRow
                  key={"table-row" + String(organization.name).replace(" ", "-") + index}
                  onClick={()=>handleAgencySelect(organization.id)}
                  className="clickable"
                >
                  <TableCell>
                    <Avatar sx={{ bgcolor: organization.themeColor || "default" }}>
                      {
                        organization.name.charAt(0)
                      }
                    </Avatar>
                  </TableCell>
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>
                    <Box sx={{maxHeight: "50px"}}>
                    {
                      organization.logo &&
                      <AmplifyS3Image
                        key={"logo" + String(organization.name).replace(" ", "-")}
                        alt={organization.name + " image"}
                        imgKey={"logos/" + organization.logo}
                        level="public"
                        imgProps={{
                          style: {
                            height: "50px"
                          }
                        }}
                      />
                    }
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
