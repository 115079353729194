import React, { useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import Modal from "components/shared/Modal";
import LoaderButton from "components/shared/LoaderButton";

export default function VendorInfoUpdateModal ({
  message="",
  open,
  onClose,
  onDelete,
  loading = false,
  content = "Once deleted, you will not be able to get it back.",
}) {
  const [, setDeleting] = useState();
  useEffect(() => {
    setDeleting(false)
  }, [open]);

  return (
    <Modal
      title={`Are you sure you want to delete ${message}?`}
      open={open}
      content={
        <Box sx={{ width: "100%" }}>
          {content}
        </Box>
      }
      actions={
        <>
          <Button variant="contained" onClick={() => onClose()} disabled={loading}>
            Cancel
          </Button>
          <LoaderButton
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="error"
            onClick={() => {
              onDelete();
              setDeleting(true);
            }
            }
            disabled={loading}
            loading={loading}
          >
            {loading ? "Deleting" : "Delete"}
          </LoaderButton>
        </>
      }
    />
  );
}
