import React, {useState, useEffect} from "react";
import {
  Alert,
  Snackbar,
  Portal,
} from "@mui/material";

export default ({ message, setMessage }) => {
  const [snackOpen, setSnackOpen] = useState(false)
  useEffect(() => {
    if(message) {
      setSnackOpen(true)
    }
  }, [message])

  useEffect(() => {
    if(!snackOpen) {
      setMessage && setMessage(undefined)
    }
  }, [snackOpen])

  return (
    <Portal>
      <Snackbar
        open={snackOpen}
        autoHideDuration={message?.duration || (message?.severity === "error" ? 2500 : 1000)}
        onClose={()=>setSnackOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={message?.severity} sx={{ width: '100%' }}>
          {message?.text}
        </Alert>
      </Snackbar>
    </Portal>
  )
}
