import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, Checkbox } from "@mui/material";
import { Menu, Popup } from "semantic-ui-react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SortableTHeadCell from "components/shared/SortableTHeadCell";
import StatusIndicator from "../shared/StatusIndicator";
import IconButton from "@mui/material/IconButton";
import IosShareIcon from "@mui/icons-material/IosShare";

import TableLoadingIndicator from "components/shared/TableLoadingIndicator";
import { awsDateToUS } from "utils/dateUtils";
import { findInStringsIgnoreCase, formatID } from "utils/stringUtils";
import ScanResultTableCell from "./ScanResultTableCell";
import { non_custom_insurance_types } from "constants/insurance_types";
import certificate_actions from "constants/certificate_actions";
import { getProjectLabel, getTableRowsPerPage, getVendorLabel } from "constants/organization_types";
import CertificatesActionModal from "./CertificatesActionModal";
import { vendorActions } from "redux/vendorSlice";
import { Certificatestatus } from "models";
import useCertificateActivities from "hooks/useCertificateActivities";
import Snackbar from "components/shared/Snackbar";

const columnConfig = {
  id: { label: "ID", noSort: true },
  insured: { label: "Insured" },
  vendor: { label: "Vendor", noSort: true },
  types: { label: "Coverage", noSort: true },
  subcontractorStatus: { label: "Subcontractor Status", noSort: true },
  producer: { label: "Producer" },
  status: { label: "Status", noSort: true, align: "center" },
  certificateDate: { label: "Certificate Date" },
  project: { label: "Project", noSort: true },
  lastModifiedDate: { label: "Last Modified" },
  createdDate: { label: "Created Date" },
  policyExp: { label: "Policy EXP" },
  scan: { label: "Scan Status", noSort: true },
  actions: { label: "", noSort: true, align: "right" },
  selected: { label: "", noSort: true, align: "center" },
};

const defaultColumns = ["status", "insured", "types", "producer", "certificateDate", "lastModifiedDate", "actions"];

function getFirstExp(coverages) {
  if (coverages?.length) {
    return (
      coverages
        .map((coverage) => coverage.policyExp)
        .flat()
        .filter((exp) => exp)
        .sort((a, b) => {
          if (new Date(a) > new Date(b)) {
            return -1;
          } else {
            return 1;
          }
        })
        .pop() || null
    );
  }
  return null;
}

function COIItemRow(props) {
  const {
    id,
    insured,
    coverages,
    status,
    producer,
    onView,
    onSendEmail,
    certificateDate,
    firstExp,
    updatedAt: lastModifiedDate,
    createdAt: createdDate,
    columns = defaultColumns,
    projectID,
    projects = {},
    subcontractors = [],
    compact = false,
    selected = {},
    clientVendorID,
    onSelectionChange = () => { },
    hasMultipleSelectionPermissions,
  } = props;

  const vendor = subcontractors.find((sub) => sub?.id === clientVendorID) || {};

  const getTypes = (coverages) =>
    (coverages?.items?.filter((coverage) => !!coverage.policyNumber && (coverage.insuranceType || coverage.name)) || [])
      .map((coverage) => {
        if (non_custom_insurance_types.includes(coverage.insuranceType)) {
          return coverage.insuranceType;
        }
        return coverage.name ? String(coverage.name).substring(0, 3).toUpperCase() : coverage.insuranceType;
      })
      .join(", ");

  const [rowData, setRowData] = useState({});

  useEffect(() => {
    setRowData({
      insuredName: insured && insured.length > 2 ? insured.split("\n")?.[0] : "",
      producerName: producer && producer.length > 2 ? producer.split("\n")?.[0] : "",
      types: getTypes(coverages),
      certificateDate: certificateDate !== "0000-01-01" ? certificateDate : null,
      lastModifiedDate: lastModifiedDate,
      firstExp: firstExp || null,
    });
  }, []);

  const onUpdateScanStatus = (scanData) => {
    if (scanData) {
      setRowData((prevRowData) => {
        return {
          insuredName: rowData.insuredName ? rowData.insuredName : scanData?.insured?.split("\n")?.[0],
          producerName: rowData.producerName ? rowData.producerName : scanData?.producer?.split("\n")?.[0],
          types: rowData.types ? rowData.types : getTypes(scanData.coverages),
          certificateDate: rowData.certificateDate ?? scanData.certificateDate,
          lastModifiedDate: rowData.lastModifiedDate ?? scanData.updatedAt ?? new Date(),
          firstExp: firstExp ?? getFirstExp(scanData?.coverages?.items),
        };
      });
    }
  };

  return (
    <TableRow>
      {hasMultipleSelectionPermissions && columns.includes("selected") && (
        <TableCell padding="checkbox" align="center">
          <Checkbox checked={!!selected?.[id]} onChange={(event) => onSelectionChange(id, event.target.checked)} />
        </TableCell>
      )}
      {columns.includes("id") && (
        <TableCell align="center">
          <Popup
            hoverable={true}
            trigger={
              <Link
                component={"button"}
                onClick={() => onView(id)}
                underline="none"
                className="clickable"
                color="inherit"
                sx={{ textAlign: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "50px" }}
              >
                {formatID(id)}
              </Link>
            }
          >
            {id}
          </Popup>
        </TableCell>
      )}
      {columns.includes("status") && (
        <TableCell align="center">
          <StatusIndicator label={status || Certificatestatus.REVIEW} size="small" />
        </TableCell>
      )}
      {columns.includes("insured") && (
        <TableCell align="center" onClick={() => onView(id)}>
          <Link component={"button"} underline="none" className="clickable" color="inherit" sx={{ textAlign: "center" }}>
            {rowData.insuredName}
          </Link>
        </TableCell>
      )}
      {columns.includes("vendor") && <TableCell align="center">{vendor?.vendor?.name}</TableCell>}
      {columns.includes("subcontractorStatus") && (
        <TableCell align="center">
          <StatusIndicator label={vendor?.status || "NO STATUS"} size="large" />
        </TableCell>
      )}
      {columns.includes("types") && <TableCell align="center">{rowData.types}</TableCell>}
      {columns.includes("producer") && (
        <TableCell align="center" sx={compact ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "60px" } : {}}>
          {compact ? (
            <Popup position="bottom left" trigger={<span>{rowData.producerName}</span>}>
              {rowData.producerName}
            </Popup>
          ) : (
            rowData.producerName
          )}
        </TableCell>
      )}
      {columns.includes("certificateDate") && <TableCell align="center">{rowData.certificateDate && awsDateToUS(rowData.certificateDate)}</TableCell>}
      {columns.includes("lastModifiedDate") && (
        <TableCell align="center">{rowData.lastModifiedDate && new Date(rowData.lastModifiedDate).toLocaleDateString("en-US")}</TableCell>
      )}
      {columns.includes("createdDate") && <TableCell align="center">{createdDate && new Date(createdDate).toLocaleDateString("en-US")}</TableCell>}
      {columns.includes("policyExp") && <TableCell align="center">{rowData.firstExp && awsDateToUS(rowData.firstExp)}</TableCell>}
      {columns.includes("project") && <TableCell align="center">{projects[projectID] || ""}</TableCell>}
      {columns.includes("scan") && (
        <TableCell align="center">
          <ScanResultTableCell id={id} onUpdateScanData={onUpdateScanStatus} />
        </TableCell>
      )}
      {columns.includes("actions") && (
        <TableCell align="right">
          {
            <Popup
              position="bottom center"
              trigger={
                <IconButton onClick={() => onSendEmail(id)}>
                  <IosShareIcon />
                </IconButton>
              }
            >
              Share Certificate
            </Popup>
          }
        </TableCell>
      )}
    </TableRow>
  );
}

export default function CertificateTable({
  certificates = [],
  paginatedCertificates = [],
  certificatesCount = 0,
  onView = () => {},
  onSendEmail = () => {},
  columns = defaultColumns,
  compact = false,
  serverSide = false,
  onFetchData,
  projects = {},
  loading,
  defaultRows = 10,
  subcontractors = [],
  nextTokens,
  setNextTokens = () => { },
  hasMultipleSelectionPermissions = false,
}) {
  const dispatch = useDispatch();
  const { selectedClient } = useSelector(organizationState);
  const projectLabel = getProjectLabel(selectedClient);
  const vendorLabel = getVendorLabel(selectedClient);
  const tableRowsPerPage = getTableRowsPerPage(selectedClient);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [selectedCertificates, setSelectedCertificates] = useState({});
  const [isActionConfirmationModalOpen, setIsActionConfirmationModalOpen] = useState(false);
  const [onMultipleSelectedCertsActionLoader, setOnMultipleSelectedCertsActionLoader] = useState(false);
  const [, addCertificateActivity] = useCertificateActivities();
  const [message, setMessage] = useState();

  const coiActions = {
    onView: (id) => onView(id),
    onSendEmail: (id) => onSendEmail(id, certificate_actions.SHARE_CERTIFICATE),
  };
  const handleChangeRowsPerPage = async (event) => {
    setTableConfig({
      ...tableConfig,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
    const newTableConfig = { ...tableConfig };
    if (status === "ALL" && newTableConfig.status) {
      delete newTableConfig.status;
      delete newTableConfig.statusFilter;
    }
    setTableConfig({
      ...newTableConfig,
      page: 0,
      ...(status && status !== "ALL" && { status: status, statusFilter: "=" }),
    });
  };

  const [tableConfig, setTableConfig] = useState({
    sortBy: "lastModifiedDate",
    sortAsc: false,
    page: 0,
    rowsPerPage: tableRowsPerPage,
  });
  const { sortBy, sortAsc, page, rowsPerPage } = tableConfig;
  const setSort = (newSortBy, newSortAsc, pageNo) =>
    setTableConfig({
      ...tableConfig,
      sortBy: newSortBy,
      sortAsc: newSortAsc,
      ...(pageNo !== undefined && { page: pageNo }),
    });
  const setPage = (newPage) => setTableConfig({ ...tableConfig, page: newPage });
  const theadProps = { sortBy, sortAsc, setSort };
  const hasFilter = (!!searchPhrase && !!String(searchPhrase).trim()) || statusFilter !== "ALL";

  useEffect(() => {
    if (serverSide && onFetchData) {
      onFetchData({ ...tableConfig });
    }
  }, [tableConfig]);

  const { filteredCertificates, filteredCertificatesCount } = useMemo(() => {
    if (certificates?.length && (hasFilter || !(serverSide && onFetchData))) {
      let toSort = [...certificates];
      if (statusFilter !== "ALL" || searchPhrase) {
        toSort = toSort.filter(
          (certificate) =>
            (statusFilter === "ALL" || certificate.status === statusFilter) &&
            (!searchPhrase ||
              findInStringsIgnoreCase(searchPhrase, [
                certificate.id,
                certificate.insured,
                certificate.coverages.items?.map((coverage) => coverage.type).join(""),
                certificate.producer,
                certificate.status,
                awsDateToUS(certificate.certificateDate),
                columns.includes("project") ? projects[certificate.projectID] : null,
                new Date(certificate.updatedAt).toLocaleDateString("en-US"),
              ]))
        );
      }
      toSort.sort((a, b) => {
        let larger;
        if (sortBy === "lastModifiedDate" && a.updatedAt && b.updatedAt) {
          larger = new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime();
        } else if (sortBy === "createdDate" && a.createdAt && b.createdAt) {
          larger = new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime();
        } else if (sortBy === "policyExp") {
          larger = new Date(a.firstExp) > new Date(b.firstExp);
        } else if (sortBy === "project") {
          larger = (projects[a.projectID] || "") > (projects[b.projectID] || "");
        } else {
          larger = (a[sortBy] || "") > (b[sortBy] || "");
        }
        if (larger) {
          return sortAsc ? 1 : -1;
        }
        return sortAsc ? -1 : 1;
      });
      return {
        filteredCertificatesCount: toSort.length,
        filteredCertificates: toSort.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      };
    }
    return {};
  }, [certificates, certificates?.length, sortBy, sortAsc, statusFilter, searchPhrase, projects, page, rowsPerPage]);

  const certsToDisplay = hasFilter || !(serverSide && onFetchData) ? filteredCertificates : paginatedCertificates;

  const onSelectedCertificatesChange = (id, value) => {
    if (value) {
      setSelectedCertificates((prev) => {
        const selectedVendor = certsToDisplay.find((vendor) => vendor?.id === id && vendor?.notificationStatus !== "DISABLED");
        if (selectedVendor) {
          prev[id] = selectedVendor;
        }
        return { ...prev };
      });
    } else {
      setSelectedCertificates((prev) => {
        delete prev[id];
        return { ...prev };
      });
    }
  };

  const onSelectedActionHandler = () => {
    setIsActionConfirmationModalOpen(true);
  };

  const onMultipleSelectedCertificatesActionHandler = (data) => {
    setOnMultipleSelectedCertsActionLoader(true);
    Object.entries(selectedCertificates).forEach(async ([key, value], index) => {
      const updateCertResult = await dispatch(
        vendorActions.createOrUpdateCertificate({
          certificateData: {
            ...value,
            status: data.status,
          },
        })
      );
      if (updateCertResult?.payload?.success) {
        addCertificateActivity({ ...value, status: data?.status }, [], { ...value });
        setMessage({
          severity: "success",
          text: `Successfully updated certificates`,
        });
      } else {
        setMessage({
          severity: "error",
          text: `Error on certificate update`,
        });
      }
    });
    setIsActionConfirmationModalOpen(false);
    setSelectedCertificates([]);
    setOnMultipleSelectedCertsActionLoader(false);
  };

  return (
    <>
      <TableContainer style={{ overflow: "visible" }} className="compact-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} style={{ border: "none" }}>
                <Menu text className="status-filter" style={{ alignItems: "center", marginLeft: 0, marginTop: 0 }}>
                  <Menu.Item active={statusFilter === "ALL"} onClick={() => handleStatusFilterChange("ALL")}>
                    All
                  </Menu.Item>
                  <Menu.Item active={statusFilter === Certificatestatus.APPROVED} onClick={() => handleStatusFilterChange(Certificatestatus.APPROVED)}>
                    Approved
                  </Menu.Item>
                  <Menu.Item active={statusFilter === Certificatestatus.INACTIVE} onClick={() => handleStatusFilterChange(Certificatestatus.INACTIVE)}>
                    Inactive
                  </Menu.Item>
                  <Menu.Item active={statusFilter === Certificatestatus.NOT_APPROVED} onClick={() => handleStatusFilterChange(Certificatestatus.NOT_APPROVED)}>
                    Not Approved
                  </Menu.Item>
                  <Menu.Item active={statusFilter === Certificatestatus.REVIEW} onClick={() => handleStatusFilterChange(Certificatestatus.REVIEW)}>
                    Review
                  </Menu.Item>
                  <Menu.Item active={statusFilter === Certificatestatus.EXPIRED} onClick={() => handleStatusFilterChange(Certificatestatus.EXPIRED)}>
                    Expired
                  </Menu.Item>
                </Menu>
              </TableCell>
              <TableCell colSpan={columns.length - 3} style={{ border: "none" }}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search"
                  id="fullWidth"
                  value={searchPhrase}
                  onChange={(e) => setSearchPhrase(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table>
          <TableHead>
            <TableRow>
              {!!columns?.length &&
                columns.map((column, index) => {
                  const config = columnConfig[column];

                  if (column === "project") {
                    config.label = projectLabel;
                  } else if (column === "subcontractorStatus") {
                    config.label = `${vendorLabel} Status`;
                  } else if (column === "vendor") {
                    config.label = vendorLabel;
                  }
                  const label = config?.label === "" ? "" : config?.label || column;
                  const align = config?.align || "center";

                  if (column === "selected") {
                    if (!hasMultipleSelectionPermissions) {
                      return null;
                    }
                    return (
                      <TableCell padding="checkbox" align={align} key={"cert" + index + "column"}>
                        <Button size="small" disabled={Object.keys(selectedCertificates).length < 1} onClick={onSelectedActionHandler}>
                          <ExpandMore />
                          Action
                        </Button>
                      </TableCell>
                    );
                  } else if (config?.noSort) {
                    return (
                      <TableCell align={align} key={"cert" + index + "column"}>
                        {label}
                      </TableCell>
                    );
                  }
                  return (
                    <SortableTHeadCell
                      key={"cert" + index + "column"}
                      {...theadProps}
                      align={align}
                      label={label}
                      sortId={config?.sortId || column}
                      setNextTokens={setNextTokens}
                    />
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableLoadingIndicator colSpan={columns.length} />}
            {!loading &&
              certsToDisplay?.map((cert, index) => (
                <COIItemRow
                  key={"cert" + index + "row"}
                  {...cert}
                  {...coiActions}
                  {...{ index, columns, compact }}
                  projects={projects}
                  subcontractors={subcontractors}
                  selected={selectedCertificates}
                  onSelectionChange={onSelectedCertificatesChange}
                  hasMultipleSelectionPermissions={hasMultipleSelectionPermissions}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        nextIconButtonProps={{ style: { pointerEvents: serverSide && onFetchData && !hasFilter && page > 0 && !nextTokens[page + 1] ? "none" : null } }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={(serverSide && onFetchData && !hasFilter ? certificatesCount : filteredCertificatesCount) || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <CertificatesActionModal
        isOpen={isActionConfirmationModalOpen}
        onCancel={setIsActionConfirmationModalOpen}
        selectedCertificates={selectedCertificates}
        loader={onMultipleSelectedCertsActionLoader}
        onUpdateHandler={onMultipleSelectedCertificatesActionHandler}
      />
      <Snackbar message={message} setMessage={setMessage} />
    </>
  );
}
