import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Container, Image, Button } from 'semantic-ui-react'
import { Grid, Link } from "@mui/material";

import AmplitudeService from "services/amplitude";

import Navigation from "components/Navigation";
import AddVendorWizard from "components/AddVendorWizard";
import PageHeader from "components/shared/PageHeader";
import VendorDocumentUpload from "components/VendorDocumentUpload";

import ORGANIZATION_TYPES, {getHighestOrgType} from "constants/organization_types"
import {
  ADMIN_ROUTES,
  AGENCY_ROUTES,
  CLIENT_ROUTES
} from "constants/routes";

import { useDispatch, useSelector } from "react-redux";
import {vendorActions} from "redux/vendorSlice";
import {clientActions} from "redux/clientSlice";
import { loggedInUserState, userActions } from "redux/userSlice";
import { userOrganizationState, organizationActions, organizationState } from "redux/organizationSlice";

import {getLogo} from "services/appResources";

const routesMap = {
  [ORGANIZATION_TYPES.SYSTEM]: ADMIN_ROUTES,
  [ORGANIZATION_TYPES.AGENCY]: AGENCY_ROUTES,
  [ORGANIZATION_TYPES.CLIENT]: CLIENT_ROUTES,
}

const Loader = props => <div>{props.content || "loading"}</div>

export default function App() {
  const auth = useAuthenticator((context) => {
    return [context.user]
  });
  const {user, signOut} = auth
  const [addVendorWizard, setAddVendorWizard] = useState(false);
  const [logo, setLogo] = useState("")

  const dispatch = useDispatch()
  const { userOrganization, userOrgType } = useSelector(userOrganizationState);
  const { selectedClient } = useSelector(organizationState);
  const clientMode = selectedClient?.mode
  const { loggedInUser } = useSelector(loggedInUserState)
  const [signedOut, setSignedOut] = useState(false)

  const onIdle = async () => {
    setSignedOut(true)
    signOut()
  }

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
  });

  useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
      AmplitudeService.initialize(process.env.REACT_APP_AMPLITUDE_API_KEY, user);
    }
  }, []);

  useEffect(() => {
    if (signedOut) {
      setLogo("");
      dispatch(userActions.resetState());
      dispatch(vendorActions.resetState());
      dispatch(clientActions.resetState());
      dispatch(organizationActions.resetState());
      if (window.location.pathname !== "/") {
        window.history.pushState({}, "", "/");
      }
    }
  }, [signedOut]);

  useEffect(() => {
    if (user && user.challengeName !== "NEW_PASSWORD_REQUIRED") {
      const groups = user?.signInUserSession?.accessToken?.payload["cognito:groups"];
      const userEmail = user?.attributes?.email || user?.challengeParam?.userAttributes?.email; // first time login
      dispatch(userActions.setUserGroups(groups));
      dispatch(organizationActions.getOrganizationByUser(userEmail));
      setSignedOut(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.attributes?.email) {
      AmplitudeService.setUserId(user?.attributes?.email);
    }
  }, [user, AmplitudeService.isInitialized]);

  useEffect(() => {
    if(ORGANIZATION_TYPES.SYSTEM === userOrgType) {
      dispatch(organizationActions.getAgencies())
    }
    if(ORGANIZATION_TYPES.AGENCY === userOrgType) {
      dispatch(organizationActions.getClients(userOrganization.id))
    }
  }, [userOrgType, userOrganization])

  useEffect(() => {
    if(userOrganization?.name && userOrgType !== ORGANIZATION_TYPES.SYSTEM) {
      document.title = "MANACERT - " + userOrganization.name
    }
    if(userOrganization?.logo) {
      getLogo(
        userOrganization.logo,
        (response) => setLogo(response)
      )
    }
  }, [userOrganization])

  const onAddVendor = () => setAddVendorWizard(true)

  const childProps = {
    loggedInUser,
    userOrganization,
    clientMode,
    user,
    logo,
    onAddVendor,
  };

  function renderChildRoutes(children, routeKey) {
    return Object.keys(children).map(childRoute => {
      const ChildComponent = children[childRoute].element
      const path = String(childRoute).replace(`${routeKey}/`, "")
      return (
        <Route path={path} element={<ChildComponent {...childProps}/>} key={path} />
      )
    })
  }

  function renderOrgRoutes(organization) {
    const orgType = getHighestOrgType(organization.type)
    const orgRoutes = routesMap[orgType]
    if(orgRoutes) {
      return (
        <Routes>
            <Route exact path="/vendor/upload/:organizationId/:vendorId/:certificateId/" element={<VendorDocumentUpload />} >
              <Route path="/vendor/upload/:organizationId/:vendorId/:certificateId/:viewMode" element={<VendorDocumentUpload />} />
            </Route>
          {
            Object.keys(orgRoutes).map(routeKey => {
              const config = orgRoutes[routeKey]
              const ChildComponent = config.element
                return (
                  <Route
                    key={"route-" + routeKey}
                    path={routeKey}
                    element={
                      <ChildComponent {...childProps}>
                        {
                          config.container && config.children && renderChildRoutes(config.children, routeKey)
                        }
                      </ChildComponent>
                    }
                  >
                    {
                      config.container && config.children && renderChildRoutes(config.children, routeKey)
                    }
                  </Route>
                )
            })
          }
        </Routes>
      )
    }
    return <Loader />
  }

  return (
    <>
        <BrowserRouter>
      {
          user && user.challengeName !== "NEW_PASSWORD_REQUIRED" && <>
          <PageHeader {...childProps} />
          <AddVendorWizard open={addVendorWizard} onClose={() => setAddVendorWizard(false)} clientMode={clientMode} loggedInUser={loggedInUser} />
          <Container fluid>
            {
              userOrganization ?
              <Navigation
              orgType={getHighestOrgType(userOrganization.type)}
              onAddVendor={onAddVendor}
              clientMode={clientMode}
              >
                  {
                    renderOrgRoutes(userOrganization)
                  }
                </Navigation>
                :
                <Loader />
              }

          </Container>
              </>
      }
      {
        (!user || user.challengeName == "NEW_PASSWORD_REQUIRED") &&
          <Routes>
              <Route exact path="/vendor/upload/:organizationId/:vendorId/:certificateId/" element={<VendorDocumentUpload />} >
                <Route path="/vendor/upload/:organizationId/:vendorId/:certificateId/:viewMode" element={<VendorDocumentUpload />} />
              </Route>
              <Route
              path="/*"
                element={
                  <Authenticator
                  className={"login-container"}
                  hideSignUp={true}
                  components={{
                    SignIn: {
                      Header() {
                        return (
                          <Grid container direction="row" justifyContent="center">
                            <Image size="medium" src={window.location.origin + "/app-logo-h110.png"} />
                          </Grid>
                        );
                      },
                      Footer() {
                        const { toResetPassword } = useAuthenticator();

                        return (
                          <Grid flexDirection="column" display="flex" alignItems="center">
                            <Button
                              className="amplify-button amplify-field-group__control"
                              data-fullwidth="false"
                              data-size="small"
                              data-variation="link"
                              type="button"
                              onClick={toResetPassword}
                              style={{ fontWeight: "normal" }}
                            >
                              Forgot your password?
                            </Button>
                            <Link
                              my={2}
                              className="clickable"
                              color="inherit"
                              href="https://www.manacert.com/privacy-policy"
                              target="_blank"
                            >
                              MANACERT Privacy Policy
                            </Link>
                          </Grid>
                        );
                      },
                    },
                  }}
                />
                }
            />
          </Routes>
      }
        </BrowserRouter>
    </>
  );
}

