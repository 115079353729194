import React, {useEffect, useState, useMemo} from "react";

import {
  Stack,
  Typography,
  Button,
  Box,
  Divider
} from "@mui/material"

import EditIcon from "@mui/icons-material/Edit"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import {useLocation, useNavigate, useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {clientActions} from "redux/clientSlice";
import {loggedInUserState} from "redux/userSlice";
import {vendorActions, vendorState} from "redux/vendorSlice";

import ProjectInfoUpdateModal from "./ProjectInfoUpdateModal";
import UpdateAllSubsCoverageModal from "./UpdateAllSubsCoverageModal";

import Snackbar from "components/shared/Snackbar";
import StatusIndicatorSelect from "components/shared/StatusIndicatorSelect";
import SideMenuContainer from "components/shared/SideMenuContainer";
import "./project-details.scss";
import {NAVIGATED_FROM, getNavigationPath, useNavPath} from "constants/routes";
import {SYSTEM,AGENCY,getVendorLabel, getProjectLabel} from "constants/organization_types"
import {organizationState} from "redux/organizationSlice";
import {awsDateToUS} from "utils/dateUtils";
import {SectionTitle} from "components/shared/Typography";
import CoverageForm from "components/shared/CoverageForm";
import VendorsTable from "components/Vendors/VendorsTable";
import CoverageUpdateModal from "components/shared/CoverageForm/CoverageUpdateModal";
import CertificateTable from "components/CertificateTable";
import AddSubcontractorsModal from "./AddSubcontractorsModal";
import {client_mode} from "constants/client_properties";

const projectStatuses = {
  ACTIVE: {label: "Active", optionLabel: "Make Active"},
  INACTIVE: {label: "Inactive", optionLabel: "Make Inactive"},
  ARCHIVED: {label: "Archived", optionLabel: "Archive"},
}


export default function ProjectDetails() {
  const navigate = useNavigate();
  const { clientId, projectId } = useParams();
  const { userOrgType, selectedClient } = useSelector(organizationState)
  const { vendors } = useSelector(vendorState)
  const projects = useSelector(state => state.client.projects[selectedClient?.clientID]) || []
  const { isAdmin } = useSelector(loggedInUserState)
  const hasEditPrivileges = isAdmin //useMemo(() => [SYSTEM, AGENCY].includes(userOrgType), [userOrgType])
  const dispatch = useDispatch()
  const NAV_PATH = useNavPath(userOrgType)
  const project = projects.find(oneProject => oneProject.id === projectId)
  const coverages = JSON.parse(project?.requiredCoverages || "[]")
  const location = useLocation()
  const [certificates, setCertificates] = useState()
  const vendorLabel = getVendorLabel(selectedClient);
  const projectLabel = getProjectLabel(selectedClient);
  const subMenuItems = [
    {key: "coverages", label: "Required Coverages"},
    {key: "subcontractors", label: `${vendorLabel}s`},
    {key: "certificates", label: "Certificates"}
  ]
  
  async function getProjectCertificates() {
    const result = await dispatch(clientActions.getProjectCertificates(projectId))
    setCertificates(result?.payload?.certificates)
  }
  useEffect(()=>{
    if(!location.hash) {
      navigate(location.pathname + `#/${subMenuItems[0].key}`)
    }
    getProjectCertificates()
    if(selectedClient?.id && !vendors) {
      dispatch(vendorActions.fetchVendors(selectedClient.clientID))
    }
  }, [])

  const activeItem = String(location.hash).replace("#/", "")
  const dateStart = awsDateToUS(project?.startDate)
  const dateEnd = awsDateToUS(project?.endDate)
  // const [updateAllSubsModalOpen, setUpdateAllSubsModalOpen] = useState(false);
  const [projectData, setProjectData] = useState(false)
  const [saving, setSaving] = useState(false)
  const [message, setMessage] = useState();
  const [editCoverages, setEditCoverage] = useState(false)
  const [refreshCoverageView, setRefreshCoverageView] = useState(0)
  const [coverageLoading, setCoverageLoading] = useState(false)
  const [addSubsModalOpen, setAddSubsModalOpen] = useState(false)
  const [addSubcontractorsLoading, setAddSubcontractorsLoading] = useState(false)

  const navToVendor = (vendorId) => {
    const destination = getNavigationPath(NAV_PATH.CLIENT_VENDOR_DETAILS, {
      clientId: selectedClient.organizationID,
      vendorId,
      referrer: NAVIGATED_FROM.PROJECT,
      referrerId: projectId
    })
  
    navigate(destination)
  }

  function backToProjects() {
    const destination = getNavigationPath(NAV_PATH.CLIENT_PROJECTS, {clientId})
    navigate(destination)
  }

  async function onSaveProject() {
    setSaving(true)
    const result = await dispatch(clientActions.updateProject({projectData, clientID: selectedClient.clientID}))
    handleUpdateResult(result)
    setSaving(false)
  }

  function onProjectDataUpdate(name, value) {
    setProjectData({
      ...projectData,
      [name]: value
    })
  }

  async function onUpdateProjectStatus(status) {
    setSaving(true)
    const result = await dispatch(clientActions.updateProjectStatus({
      clientID: selectedClient.clientID,
      id: project.id,
      status,
    }))
    handleUpdateResult(result)
    setSaving(false)
  }

  function handleUpdateResult(result) {
    if(result?.payload?.success) {
      setProjectData(false)
      setMessage({
        severity: "success",
        text: `Successfully updated ${projectLabel}`
      })
    } else {
      setMessage({
        severity: "error",
        text: `Unable to updated ${projectLabel}`
      })
    }
  }

  function navToCertificate(id) {
    const certificatePath = getNavigationPath(NAV_PATH.CLIENT_CERTIFICATE_DETAILS, {
      clientId: selectedClient.id,
      vendorId: NAVIGATED_FROM.PROJECT,
      certificateId: id
    })
    navigate(certificatePath, {
      state: {
        referrer: location.pathname,
      }
    })
  }

  function handleCoverageCancel() {
    setEditCoverage(false)
  }

  async function handleCoverageSave(data) {
    setCoverageLoading(true)
    const result = await dispatch(clientActions.updateProject({
      projectData: {
        id: project.id,
        requiredCoverages: data,
      },
      clientID: selectedClient?.clientID
    }))
    if(result?.payload?.success) {
      setEditCoverage(false)
      setRefreshCoverageView(refresh => refresh+1)
      setMessage({
        severity: "success",
        text: "Successfully updated coverage"
      })
    } else {
      setMessage({
        severity: "error",
        text: "Unable to update coverage"
      })
    }

    setCoverageLoading(false)
  }

  async function handleSaveSubcontractors(selected) {
    setAddSubcontractorsLoading(true)
    const result = await dispatch(clientActions.updateProjectVendors({
      projectID: project.id,
      selectedVendors: selected,
      existingProjectVendors: project?.vendors?.items
    }))
    setAddSubcontractorsLoading(false)
    if(result?.payload?.success) {
      setAddSubsModalOpen(false)
      setMessage({
        severity: "success",
        text: `Successfully updated subcontractors for ${projectLabel}`
      })
    } else {
      setMessage({
        severity: "error",
        text: `Unable to update subcontractors for ${projectLabel}`
      })
    }
  }

  return (
    <div className="project-details-screen">
      <CoverageUpdateModal
        title={`Update ${projectLabel} Coverage Requirements`}
        open={editCoverages}
        loading={coverageLoading}
        onClose={handleCoverageCancel}
        coverages={coverages}
        clientCoverages={selectedClient?.clientCoverages?.items}
        onSubmit={handleCoverageSave}
        clientMode={selectedClient?.mode}
        showHelperText={true}
      />
      <Snackbar message={message} setMessage={setMessage} />
      <ProjectInfoUpdateModal
        open={projectData !== false}
        projectData={projectData}
        onClose={()=>setProjectData(false)}
        onSave={onSaveProject}
        onUpdate={onProjectDataUpdate}
        loading={saving}
        projectLabel={projectLabel}
      />
      {/*<UpdateAllSubsCoverageModal*/}
      {/*  open={updateAllSubsModalOpen}*/}
      {/*  onClose={()=>setUpdateAllSubsModalOpen(false)}*/}
      {/*/>*/}
      <Button onClick={backToProjects}>
        <KeyboardArrowLeftIcon />
        {`Back to ${projectLabel}s`}
      </Button>
      <Box sx={{mt: 2, mb: 4}}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5" component="span">
            {project?.name}
          </Typography>
          <StatusIndicatorSelect
            statuses={projectStatuses}
            currentStatus={project?.status}
            onUpdateStatus={onUpdateProjectStatus}
            disableSelection={!hasEditPrivileges}
          />
          <Button
            startIcon={<EditIcon fontSize="small" />}
            onClick={()=>setProjectData({
              ...project,
              startDate: awsDateToUS(project?.startDate),
              endDate: awsDateToUS(project?.endDate),
            })}
            color={"primary"}
            style={{ marginLeft: "auto" }}
            disabled={!hasEditPrivileges}
          >
            {`UPDATE ${projectLabel}`.toUpperCase()}
          </Button>
        </Stack>
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {
            `Start Date: ${(dateStart && dateStart !== 'Invalid Date') ? dateStart : "-" } | `
          }
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {
            `End Date: ${(dateEnd && dateEnd !== 'Invalid Date') ? dateEnd : "-" } | `
          }
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {project?.description}
        </Typography>
        {project?.address && 
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {` | ${project?.address}`}
        </Typography>
        }
        {project?.state && 
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {` | ${project?.state}`}
        </Typography>
        }
        {project?.zip && 
        <Typography variant="subtitle2" color="text.secondary" component="span">
          {` | ${project?.zip}`}
        </Typography>
        }
        <Divider />
      </Box>

      <SideMenuContainer items={subMenuItems}>
        {
          (activeItem === subMenuItems[0].key) &&
          <>
            <SectionTitle sx={{mb: 2}}>
              {subMenuItems[0].label}
              <Button
                onClick={() => setEditCoverage(true)}
                disabled={!hasEditPrivileges}
              >
                Update
              </Button>
            </SectionTitle>

            <CoverageForm isEdit={false} coverages={coverages} refresh={refreshCoverageView}/>
            {
              !coverages?.length &&
              <Typography variant="subtitle2" sx={{mt:3}} color="text.secondary">
                No requirement set for this {projectLabel}.
              </Typography>
            }
          </>
        }
        {
          (activeItem === subMenuItems[1].key) &&
          <>
            <AddSubcontractorsModal
              open={addSubsModalOpen}
              onClose={()=>setAddSubsModalOpen(false)}
              vendors={vendors}
              projectVendors={project?.vendors?.items}
              onSave={handleSaveSubcontractors}
              loading={addSubcontractorsLoading}
              vendorLabel={vendorLabel}
              projectLabel={projectLabel}
            />
            <SectionTitle sx={{mb: 2}}>
              {subMenuItems[1].label}
              <Button disabled={!hasEditPrivileges} sx={{float: "right"}} onClick={()=>setAddSubsModalOpen(true)}>
                {`+ ADD ${vendorLabel}S TO ${projectLabel}`.toUpperCase()}
              </Button>
            </SectionTitle>
            <VendorsTable
              columns={["name", "status", "city", "state", "createdAt", "email"]}
              vendors={project?.vendors?.items?.filter(vendor => vendor.clientVendor).map(vendor => vendor.clientVendor)}
              clientMode={client_mode.project}
              navToVendor={navToVendor}
              compact={false}
            />
          </>
        }
        {
          (activeItem === subMenuItems[2].key) &&
          <>
            <SectionTitle sx={{mb: 2}}>{subMenuItems[2].label}</SectionTitle>
            <CertificateTable
              onView={navToCertificate}
              certificates={certificates || []}
              clientMode={client_mode.project}
              compact={true}
            />
          </>
        }
      </SideMenuContainer>
    </div>
  )
}
