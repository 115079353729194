import React from "react";
import { Stack, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import us_states from "constants/us_states";

import { formatPhoneNumber, getPhoneNumberDigits, removeFormat, getZipCodeDigits } from "utils/formUtils";

export default function InsuranceAgentForm({
  vendorData = {},
  setInsuranceAgentData = () => {},
  insuranceAgentDataError = {},
  setInsuranceAgentDataError = () => {},
}) {
  const { name, address1, address2, phone, email, zip, city, state } = {
    ...vendorData?.insuranceAgent,
  };
  const insuranceAgentLabel = "Insurance Agent";

  function onStateFieldKeyPress (event) {
    const { key } = event
    const findState = us_states.find(state => state.startsWith(key.toUpperCase()))

    if (findState) {
      setInsuranceAgentData({
        ...vendorData,
        insuranceAgent: {
          ...vendorData?.insuranceAgent,
          'state': findState
        },
      });
      }
  }

  function updateFormValue(event) {
    const { name: fieldName, value } = event.target;
    let updatedValue = removeFormat({ name: fieldName, value });
    if (fieldName === "email") {
      if (!event.target.validity?.valid) {
        setInsuranceAgentDataError({
          ...insuranceAgentDataError,
          insuranceAgentEmail: event.type === "blur" ? "Please enter a valid email address" : true,
        });
      } else {
        setInsuranceAgentDataError({
          ...insuranceAgentDataError,
          insuranceAgentEmail: false,
        });
      }
    }

    //Insurance Agent form is only valid if both name and email are present or absent
    if (!!name != !!email) {
      setInsuranceAgentDataError({
        ...insuranceAgentDataError,
        invalidInsuranceAgentForm: true,
      });
    } else {
      setInsuranceAgentDataError({
        ...insuranceAgentDataError,
        invalidInsuranceAgentForm: false,
      });
    }

    //IMPORTANT: we can't send empty AWS types like AWS Email, Int
    //this can introduce break of API calls
    
    //set organization details if there is value, otherwise 
    // delete empty fields
    if (value) {
      setInsuranceAgentData({
        ...vendorData,
        insuranceAgent: {
          ...vendorData?.insuranceAgent,
          [fieldName]: updatedValue,
        },
      });
    } else {
      delete vendorData?.insuranceAgent[fieldName]
      setInsuranceAgentData({
        ...vendorData,
        insuranceAgent: {
          ...vendorData?.insuranceAgent,
        },
      });
    }
  }

  return (
    <>
      <Stack spacing={2} my={2}>
        {
          <>
            <TextField
              className="vendor-info-input"
              label={insuranceAgentLabel + " Name"}
              value={name}
              name={"name"}
              onChange={updateFormValue}
              onBlur={updateFormValue}
              error={!!email != !!name}
              helperText={name === "" ? `Please specify a name for the ${String(insuranceAgentLabel).toLowerCase()}` : ""}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                className="vendor-info-input"
                label={insuranceAgentLabel + " Email"}
                value={email}
                name={"email"}
                onChange={updateFormValue}
                onBlur={updateFormValue}
                type="email"
                error={!!email != !!name || typeof insuranceAgentDataError.email === "string"}
                helperText={email === "" || typeof insuranceAgentDataError.email === "string" ? "Please enter a valid email address" : ""}
              />
              <TextField
                className="vendor-info-input"
                label={insuranceAgentLabel + " Phone"}
                value={formatPhoneNumber(phone)}
                name={"phone"}
                onChange={updateFormValue}
                error={phone && (parseInt(getPhoneNumberDigits(phone)) === 0 || getPhoneNumberDigits(phone).length < 10)}
                helperText={
                  phone && (parseInt(getPhoneNumberDigits(phone)) === 0 || getPhoneNumberDigits(phone).length < 10) ? "Please enter a valid phone number" : ""
                }
              />
            </Stack>
            <TextField
              className="vendor-info-input"
              label={insuranceAgentLabel + " Address Line 1"}
              value={address1}
              name={"address1"}
              onChange={updateFormValue}
            />
            <TextField
              className="vendor-info-input"
              label={insuranceAgentLabel + " Address Line 2"}
              value={address2}
              name={"address2"}
              onChange={updateFormValue}
            />
          </>
        }
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField className="vendor-info-input" label="City" value={city} name={"city"} onChange={updateFormValue} />
        <FormControl sx={{ minWidth: 80 }}>
          <InputLabel id="state-input-label">State</InputLabel>
          <Select
            labelId="state-input-label"
            className="vendor-info-input"
            label="State"
            value={state || ""}
            name={"state"}
            onChange={updateFormValue}
            onKeyPress={onStateFieldKeyPress}
          >
            <MenuItem value={""}>-</MenuItem>
            {
              us_states.map(usState => (
                <MenuItem value={usState} key={usState}>{usState}</MenuItem>
              ))
            }

                </Select>
        </FormControl>
        <TextField
          className="vendor-info-input"
          label="Zip Code"
          value={getZipCodeDigits(zip)}
          name={"zip"}
          onChange={updateFormValue}
          error={zip && getZipCodeDigits(zip).length !== 5}
          helperText={zip && getZipCodeDigits(zip).length !== 5 ? "Please enter valid Zip Code" : ""}
        />
      </Stack>
    </>
  );
}
