export default {
  REQUEST: "Request",
  CUSTOM: "Custom",
  VENDOR_UPLOAD: "VendorUpload",
  CLIENT_ADMIN_NOTIFICATION: "ClientAdminNotificationOnUpload",
  REMINDER_1: "Reminder1",
  REMINDER_7: "Reminder7",
  REMINDER_14: "Reminder14",
  CERTIFICATE_EXPIRATION: "CertificateExpiration",
  SHARE_CERTIFICATE: "ShareCertificate",
}
