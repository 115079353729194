import {isExpired, awsDateToUS} from "./dateUtils"
import * as CERTIFICATE_CONSTANTS from "constants/insurance_types";
import { non_custom_insurance_types, CERTIFICATE_PROPERTIES, COVERAGE_PROPERTIES } from "constants/insurance_types";

import {formatCurrencyInt} from "./formUtils"

const notBlankFields = [
  "certificateDate",
  "insured",
  "certificateHolder",
  "producer",
  "producerContactName",
  "producerPhone",
  // "producerFax",
  "producerEmail",
]

const fieldKeyLabel = {
  certificateDate: "Certificate Date",
  insured: "Insured",
  certificateHolder: "Certificate Holder",
  producer: "Producer",
  producerContactName: "Producer Contact Name",
  producerPhone: "Producer Phone",
  producerEmail: "Producer Email",
  GL: "COMMERCIAL GENERAL LIABILITY",
  AU: "AUTOMOBILE LIABILITY",
  UMB: "UMBRELLA LIAB/EXCESS LIAB",
  WC: "WORKERS COMPENSATION AND EMPLOYERS' LIABILITY",
  insuranceForm: "Insurance Form",
  aggregateAppliesPer: "Aggregate Applies Per",
  coveredProperty: "Properties",
  selectedCoverage: "Coverages",
  additionalInsured: "Additional Insured",
  subrogationWaiver: "Subrogation Waiver",
  deductible: "Deductible",
  retention: "Retention",
  limitPerStatute: "Limit Per Statute",
  exclusion: "Exclusion",
}

const insurers = [
  ["insurerA", "insurerANAIC"],
  ["insurerB", "insurerBNAIC"],
  ["insurerC", "insurerCNAIC"],
  ["insurerD", "insurerDNAIC"],
  ["insurerE", "insurerENAIC"],
  ["insurerF", "insurerFNAIC"],
]

const coverageArrayFields = [
  "insuranceForm",
  "aggregateAppliesPer",
  "coveredProperty",
  "selectedCoverage",
]

const coverageMatchFields = [
  "additionalInsured",
  "subrogationWaiver",
  "deductible",
  "retention",
  "limitPerStatute",
  "exclusion",
]

export function validate(certData = {}, references = [], selectedAgency={}, selectedClient={}, vendor={}) {
  let validationFailed = false;
  let failedReasons = [];
  let hasNameAdditionalInsured = false;
  if(typeof references === "string") {
    references = JSON.parse(references)
  }
  let certOverrides = certData.overrides || {};
  if(typeof certOverrides === "string") {
    certOverrides = JSON.parse(certOverrides)
  }
  //validate insured
  const isValidInsured = isEqualFirstRowToInput(certData?.insured, vendor?.name);
  const hasInsuredError = !(isValidInsured || certOverrides[CERTIFICATE_PROPERTIES.insured.label]);
  if (hasInsuredError) {
    validationFailed = true;
    failedReasons.push(`INSURED should match SUBCONTRACTOR "${vendor?.name}"`)
  }

  //validate certificate holder
  const certHolderValidationFailed = validateCertificateHolder(certData, certOverrides, failedReasons, selectedAgency, selectedClient)

  if(certHolderValidationFailed) {
    validationFailed = true;
  }

  // validate overview
  notBlankFields.forEach(fieldKey => {
    if(!certOverrides[CERTIFICATE_PROPERTIES[fieldKey]?.label] && !String(certData[fieldKey]).trim()) {
      validationFailed = true;
      failedReasons.push(fieldKeyLabel[fieldKey]  + " cannot be empty.")
    }
  })

  const hasOneInsurer = insurers.find(([insurer, insurerNAIC]) => (certData[insurer] && certData[insurerNAIC]))
  if(!hasOneInsurer) {
    validationFailed = true;
    failedReasons.push("At least one insurer and NAIC required.")
  }

  // validate coverage
  if(references?.length) {
    references.forEach(reference => {
      const { insuranceType } = reference
      // for now, validate only non-custom types
      if(non_custom_insurance_types.includes(insuranceType)) {
        const coverage = certData?.coverages?.items.find(coverage => coverage.insuranceType === insuranceType)
        if (!coverage) {
          validationFailed = true;
          failedReasons.push(`${fieldKeyLabel[insuranceType]} is missing.`)
        } else {
          let coverageOverrides = coverage?.overrides || {};

          if (typeof coverageOverrides === "string") {
            coverageOverrides = JSON.parse(coverageOverrides);
          }

          // begin - process fields that needs to match
          coverageArrayFields.forEach(fieldKey => {
            if (reference[fieldKey]?.length) {
              let toMatch = [...reference[fieldKey]];
              switch (fieldKey) {
                case "aggregateAppliesPer": {
                  toMatch = toMatch.filter(
                    (fieldValue) =>
                      (!!fieldValue && ["POLICY", "LOC", "PROJECT"].includes(fieldValue) && !coverageOverrides[fieldValue]) ||
                      (!["POLICY", "LOC", "PROJECT"].includes(fieldValue) && !coverageOverrides["OTHER"])
                  );
                  break;
                }
                case "coveredProperty": {
                  toMatch = toMatch.filter(
                    (fieldValue) =>
                      (!!fieldValue &&
                        ["ANY AUTO", "ALL OWNED AUTOS", "SCHEDULED AUTOS", "HIRED AUTOS", "NON-OWNED AUTOS"].includes(fieldValue) &&
                        !coverageOverrides[fieldValue]) ||
                      (!["ANY AUTO", "ALL OWNED AUTOS", "SCHEDULED AUTOS", "HIRED AUTOS", "NON-OWNED AUTOS"].includes(fieldValue) &&
                        !coverageOverrides["OTHER"])
                  );
                  break;
                }
                default: {
                  toMatch = toMatch.filter((fieldValue) => !!fieldValue && !coverageOverrides[COVERAGE_PROPERTIES[fieldValue]?.label]);
                }
              }
              const fieldValue = coverage[fieldKey]?.length ? [...coverage[fieldKey]].filter(fieldValue => !!String(fieldValue).trim()) : []
              if (toMatch.filter((el) => fieldValue.includes(el)).length !== toMatch.length) {
                const error = fieldValue?.length ? `got ${fieldValue.join(", ")}` : "none selected";

                validationFailed = true;
                failedReasons.push(
                  `${fieldKeyLabel[insuranceType]} does not meet requirement - `
                  +`${fieldKeyLabel[fieldKey]} requires ${toMatch.join(", ").replaceAll("_", "-")} but ${error}.`
                )
              }
            }
          })
          coverageMatchFields.forEach(fieldKey => {
            if (
              (!coverageOverrides[COVERAGE_PROPERTIES[fieldKey]?.label] &&
                isNaN(reference[fieldKey]) &&
                reference[fieldKey] &&
                String(coverage[fieldKey]) !== String(reference[fieldKey])) ||
              (!isNaN(reference[fieldKey]) &&
                !coverageOverrides[COVERAGE_PROPERTIES[fieldKey]?.label] &&
                (isNaN(coverage[fieldKey]) || coverage[fieldKey] < reference[fieldKey]))
            ) {
              const error =
                reference[fieldKey] === true
                  ? `${fieldKeyLabel[fieldKey]} is required`
                  : `${fieldKeyLabel[fieldKey]} requires ${reference[fieldKey]} but got ${coverage[fieldKey]}`;

              validationFailed = true;
              failedReasons.push(
                `${fieldKeyLabel[insuranceType]} does not meet requirement`
                +` - ${error}.`
              )
            }
          })
          // end - process fields that needs to match

          // begin - process limits
          let referenceLimits = reference?.limits
          if(typeof referenceLimits === "string") {
            referenceLimits = JSON.parse(referenceLimits)
          }
          let coverageLimits = coverage.limits || {}
          if(typeof coverageLimits === "string") {
            coverageLimits = JSON.parse(coverageLimits)
          }
          if(referenceLimits && Object.keys(referenceLimits)?.length) {

            if (
              !Object.keys(coverageLimits)?.length &&
              !CERTIFICATE_CONSTANTS[coverage?.insuranceType].limits.some((value) => {
                return !!coverageOverrides[value];
              })
            ) {
              validationFailed = true;
              failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - no coverage limits are specified.`)
            } else {
              Object.entries(referenceLimits).forEach(([limitKey, limitValue]) => {
                const coverageLimit = coverageLimits[limitKey] || 0;
                if(!coverageOverrides[limitKey] && limitValue > coverageLimit) {
                  validationFailed = true;
                  failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - ${limitKey} is lower than the required limit of $${formatCurrencyInt(limitValue)}.`)
                }
              })
            }
          }
          // end - process limits

          // begin - check if policy is valid
          const policyValidationFailed = validatePolicy(certData, coverage, failedReasons, reference)
          if(policyValidationFailed) {
            validationFailed = true;
          }

          // todo:
          // if(coverage.exclusion) {
            // if exclusion set to true, need to specify who to exclude in description of operation
          // }
          // end - check if policy is valid
        }
      }
    })
  }
  // else if (certData?.coverages?.items?.length) {
    // certData.coverages.items.forEach(coverage => {
    //   if(coverage.insuranceType && non_custom_insurance_types.includes(coverage.insuranceType)) {
    //     const policyValidationFailed = validatePolicy(certData, coverage, failedReasons)
    //     if(policyValidationFailed) {
    //       validationFailed = true;
    //     }
    //   }
    // })
  // }
  return {
    isValid: !validationFailed,
    validationErrors: failedReasons
  }
}

function validatePolicy (certData = {}, coverage = {}, failedReasons = [], reference = {}) {
  //if references has more than two properties,
  //then there are policy requirements
  // empty references are : {index: Int, insuranceType: String }
  const isRequiredCoverage = Object.keys(reference).filter(r=> r!== "index" || r !== "insuranceType" || (Array.isArray(r) && r.length > 0))?.length > 0;
  if (!isRequiredCoverage) {
    return;
  }
  let validationFailed = false;
  const {insuranceType} = coverage
  if(!coverage.policyNumber) {
    validationFailed = true;
    failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - policy number is missing.`)
  }
  if(
    !coverage.policyEff?.length
    || !coverage.policyExp?.length
    || coverage.policyEff?.length !== coverage.policyExp?.length
  ) {
    validationFailed = true;
    failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - policy effective date or expiration date is missing.`)
  } else {
    coverage.policyExp?.forEach(policyExp => {
      if(policyExp && isExpired(policyExp)) {
        validationFailed = true;
        failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - policy expiration date ${awsDateToUS(policyExp)} is expired.`)
      }
    })
  }
  if(!coverage.insurerLetter?.length) {
    validationFailed = true;
    failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - no insurer is specified.`)
  } else {
    coverage.insurerLetter.forEach(insurerLetter => {
      if(!certData[`insurer${String(insurerLetter).toUpperCase()}`]) {
        validationFailed = true;
        failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - insurer ${String(insurerLetter).toUpperCase()} listed but no affiliate name specified.`)
      } else if (!certData[`insurer${String(insurerLetter).toUpperCase()}NAIC`]){
        validationFailed = true;
        failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - insurer ${String(insurerLetter).toUpperCase()} listed but no NAIC specified.`)
      }
    })
  }

  return validationFailed;
}

function validateCertificateHolder (certData = {},certOverrides = {}, failedReasons = [], selectedAgency={}, selectedClient={}) {
  const validCertificateHolder = selectedAgency?.certificateHolder?.trim() || selectedClient?.name;
  const validCertificateHolderAddress1 = selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderAddress1?.trim() : (selectedClient?.address1 || "") ;
  const validCertificateHolderCity= selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderCity?.trim() : (selectedClient?.city || "") ;
  const validCertificateHolderState= selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderState?.trim() : (selectedClient?.state || "") ;
  const validCertificateHolderZip= selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderZip : (selectedClient?.zip || "") ;

  const isValidCertificateHolder = isEqualFirstRowToInput(certData?.certificateHolder, validCertificateHolder);
  const isValidCertificateHolderAddress1 = isValidAddressDetail(certData?.certificateHolder,validCertificateHolderAddress1);
  const isValidCertificateHolderCity = isValidAddressDetail(certData?.certificateHolder,validCertificateHolderCity);
  const isValidCertificateHolderState =  isValidAddressDetail(certData?.certificateHolder,validCertificateHolderState);
  const isValidCertificateHolderZip = isValidAddressDetail(certData?.certificateHolder,validCertificateHolderZip);
  let validationFailed = false;
  let certHolderAddressErr = [];
  if (!isValidCertificateHolder && !certOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]) {
    failedReasons.push(`CERTIFICATE HOLDER should match CLIENT "${validCertificateHolder}"`);
  }
  if (!isValidCertificateHolderAddress1 && !certOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]) {
    validationFailed = true;
    certHolderAddressErr.push(`Address should match "${validCertificateHolderAddress1}"`);
  }
  if (!isValidCertificateHolderCity && !certOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]) {
    validationFailed = true;
    certHolderAddressErr.push(`City should match "${validCertificateHolderCity}"`);
  }
  if (!isValidCertificateHolderState && !certOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]) {
    validationFailed = true;
    certHolderAddressErr.push(`State should match "${validCertificateHolderState}"`);
  }
  if (!isValidCertificateHolderZip && !certOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]) {
    validationFailed = true;
    certHolderAddressErr.push(`Zip should match "${validCertificateHolderZip}"`);
  }
  if (certHolderAddressErr.length) {
    failedReasons.push("CERTIFICATE HOLDER " + certHolderAddressErr.join(", "));
  }
  return validationFailed;
};

function isEqualFirstRowToInput(textFieldInput, validationInput) {
  return textFieldInput?.split("\n")[0]?.toUpperCase()?.trim() === validationInput?.toUpperCase()?.trim();
};

function isValidAddressDetail(textFieldInput, validAddress) {
  const [, ...addressDetails] = textFieldInput?.split("\n") || [];
  return addressDetails.join(',').toUpperCase()?.trim()?.includes(validAddress?.toString()?.toUpperCase()?.trim());
};