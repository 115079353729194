import React, {useEffect, useState} from "react";

import {
  Typography,
  IconButton,
  ButtonGroup,
  TextField,
  Button,
} from "@mui/material"

import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useNavigate, useParams, useLocation } from "react-router-dom"
import {getNavigationPath, useNavPath} from "constants/routes";
import {useSelector, useDispatch} from "react-redux";
import {organizationState} from "redux/organizationSlice";
import {clientActions} from "redux/clientSlice";

import Snackbar from "components/shared/Snackbar";

import {SectionTitle} from "components/shared/Typography";
import CoverageForm from "components/shared/CoverageForm";
import CoverageUpdateModal from "components/shared/CoverageForm/CoverageUpdateModal";
import SideMenuContainer from "components/shared/SideMenuContainer";
import VendorsTable from "components/Vendors/VendorsTable";
import {client_mode} from "constants/client_properties";

const subMenuItems = [
  {key: "coverages", label: "Required Coverages"},
  {key: "vendors", label: "Subcontractors"},
]

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { userOrgType, selectedClient } = useSelector(organizationState)
  const categories = useSelector((state) => state.client.categories[selectedClient?.clientID]) || []

  const NAV_PATH = useNavPath(userOrgType)
  const {categoryId, clientId} = useParams();
  const category = categories.find(oneCategory => oneCategory.id === categoryId)
  const [updating, setUpdating] = useState(false)
  const [message, setMessage] = useState();
  const [editCoverages, setEditCoverage] = useState(false)
  const [coverageLoading, setCoverageLoading] = useState(false)
  const [refreshCoverageView, setRefreshCoverageView] = useState(0)
  const coverages = JSON.parse(category?.requiredCoverages || "[]")
  const [editName, setEditName] = useState(false);

  const navToVendor = (vendorId) => {
    const destination = getNavigationPath(NAV_PATH.CLIENT_VENDOR_DETAILS, {
      clientId: selectedClient.organizationID,
      vendorId
    })
    const referrer = getNavigationPath(NAV_PATH.CLIENT_CATEGORY_DETAILS, {
      clientId: selectedClient.organizationID,
      categoryId,
    })
    navigate(destination, {state:{ referrer }})
  }

  const location = useLocation()
  const activeItem = String(location.hash).replace("#/", "")
  useEffect(()=>{
    if(!location.hash) {
      navigate(location.pathname + `#/${subMenuItems[0].key}`)
    }
  }, [])

  async function handleCoverageSave(data) {
    setCoverageLoading(true)
    const result = await dispatch(clientActions.updateCategory({
      id: category.id,
      requiredCoverages: data,
      clientID: selectedClient?.clientID
    }))
    if(result?.payload?.success) {
      setEditCoverage(false)
      setRefreshCoverageView(refresh => refresh+1)
      setMessage({
        severity: "success",
        text: "Successfully updated coverage"
      })
    } else {
      setMessage({
        severity: "error",
        text: "Unable to update coverage"
      })
    }

    setCoverageLoading(false)
  }

  function handleCoverageCancel() {
    setEditCoverage(false)
  }

  function navBackToCategories() {
    const destination = getNavigationPath(NAV_PATH.CLIENT_CATEGORIES, {clientId})
    navigate(destination)
  }

  async function onEditCategorySave() {
    setUpdating(true)
    if(String(editName).trim() !== category.name) {
      const result = await dispatch(clientActions.updateCategory({
        id: category.id,
        name: editName,
        clientID: selectedClient.clientID
      }))
      if(result?.payload?.success) {
        setMessage({
          severity: "success",
          text: "Successfully updated category"
        })
        setEditName(false)
      } else {
        setMessage({
          severity: "error",
          text: "Unable to update category"
        })
      }
    } else {
      setEditName(false)
    }
    setUpdating(false)
  }

  function onEditCategoryCancel () {
    setEditName(false)
  }
  
  async function onEditCategoryDelete () {
    if (category.vendors?.items?.length > 0) {
      setMessage({
        severity: "error",
        text: "In order to delete a Category, it has to be disassociated from each client where the category is assigned."
      })
    } else {
      const result = await dispatch(clientActions.deleteCategory({
        id: category.id,
        clientID: selectedClient.clientID
      }))
      if(result?.payload?.success) {
        setMessage({
          severity: "success",
          text: "Category deleted successfully."
        })
        setEditName(false)
        setUpdating(true)
      } else {
        setMessage({
          severity: "error",
          text: "Unable to update category"
        })
      }
    }
  }

  return (
    <>
      <CoverageUpdateModal
        title={'Update Category Coverage Requirements'}
        open={editCoverages}
        loading={coverageLoading}
        onClose={handleCoverageCancel}
        coverages={coverages}
        clientCoverages={selectedClient?.clientCoverages?.items}
        onSubmit={handleCoverageSave}
        clientMode={selectedClient?.mode}
        showHelperText={true}
      />
      <Snackbar message={message} setMessage={setMessage} />
      <Button onClick={navBackToCategories}>
        <KeyboardArrowLeftIcon />
        Back to Categories
      </Button>
      {
        (editName === false)
        ?
          <Typography variant="h5" sx={{mt: 2, mb: 3}}>
            {category?.name}
            <IconButton onClick={()=>setEditName(category?.name)} color={"primary"}><EditIcon fontSize="small" /></IconButton>
          </Typography>
        :
          <Typography variant="h5" color="text.secondary" sx={{mt: 2, mb: 3}}>
            <TextField
              value={editName}
              disabled={!editName}
              variant="standard"
              label="Category Name (required)*"
              onChange={event => setEditName(event.target.value)}
              sx={{mr: 2}}
            />
            <ButtonGroup sx={{verticalAlign: "bottom"}}>
              <Button
                disabled={!editName || updating}
                onClick={onEditCategorySave}
                startIcon={<CheckIcon />}
                variant="text"
                color={"success"}
                >Save</Button>
              <Button
                disabled={updating}
                onClick={onEditCategoryCancel}
                startIcon={<CloseIcon />}
                variant="text"
                >Cancel</Button>
              <Button
                disabled={!editName || updating}
                onClick={onEditCategoryDelete}
                startIcon={<DeleteForeverIcon />}
                variant="text"
                color={"error"}
              >Delete</Button>
            </ButtonGroup>
          </Typography>
      }

      <SideMenuContainer items={subMenuItems}>
        {
          (activeItem === subMenuItems[0].key) &&
          <>
            <SectionTitle sx={{mb: 2}}>
              {subMenuItems[0].label}
              <Button
                onClick={()=>setEditCoverage(true)}
              >
                Update
              </Button>
            </SectionTitle>
            <CoverageForm isEdit={false} coverages={coverages} refresh={refreshCoverageView}/>
            {
              !coverages?.length &&
              <Typography variant="subtitle2" sx={{mt:3}} color="text.secondary">
                No requirement set for this category.
              </Typography>
            }
          </>
        }
        {
          (activeItem === subMenuItems[1].key) &&
          <>
            <SectionTitle sx={{mb: 2}}>{subMenuItems[1].label}</SectionTitle>
            <VendorsTable
              columns={["name", "status", "city", "state", "createdAt", "email", "phone"]}
              vendors={category?.vendors?.items?.filter(vendor=>vendor.clientVendor !== null)?.map(vendor => vendor.clientVendor)}
              clientMode={client_mode.category}
              navToVendor={navToVendor}
              compact={false}
            />
          </>
        }
        {
          false && (activeItem === subMenuItems[2].key) &&
          <>
            <SectionTitle sx={{mb: 2}}>{subMenuItems[2].label}</SectionTitle>
            TO BE IMPLEMENTED
            {/*<CertificateTable*/}
            {/*  clientMode={client_mode.category}*/}
            {/*  compact={true}*/}
            {/*  numRows={10}*/}
            {/*  columns={{*/}
            {/*    "name": "Name",*/}
            {/*    "vendor": "Vendor",*/}
            {/*    "types": "Coverage",*/}
            {/*    "status": "Status",*/}
            {/*  }}*/}
            {/*/>*/}
          </>
        }
      </SideMenuContainer>
    </>
  )
}
