import React from "react";
import { Box, Typography, Radio } from "@mui/material";
import StatusIndicator from "components/shared/StatusIndicator";
import { awsDateToUS } from "utils/dateUtils";

export default function ProjectLineItem ({ project, selectable = false, selected = "" }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {selectable && <Radio value={project?.id} />}
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {project.name}
      </Typography>
      {(project.startDate || project.endDate) && (
        <Typography variant="subtitle2" sx={{ ml: "auto", width: "141px", display: "flex", justifyContent: "space-between" }}>
          <span style={{ width: "65px" }}>{project.startDate && awsDateToUS(project.startDate)}</span>
          <span style={{ marginLeft: "2px", marginRight: "2px" }}>-</span>
          <span style={{ width: "65px" }}>{project.endDate && awsDateToUS(project.endDate)}</span>
        </Typography>
      )}
      {project.status ? (
        <StatusIndicator label={project.status} size="small" sx={{ ml: project.startDate || project.endDate ? 2 : "auto" }} />
      ) : (
        <Box sx={{ width: "105px", ml: 2, textAlign: "center" }}>-</Box>
      )}
    </Box>
  );
}
