import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default function LoaderButton (props) {
  const newProps = {...props}
  const { loading, disabled, children } = newProps;
  // this is necessary because of the console errors for the component
  //there is no loading property of the button
  delete newProps["loading"]
  return (
    <Button {...newProps} disabled={!!loading || disabled}>
      {children}
      {loading && (
        <CircularProgress
          color={"inherit"}
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Button>
  );
}
