import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { API } from "aws-amplify"
import {
  listORGANIZATIONS,
  updateORGANIZATION,
  clientByAgencyOrganizationID,
  clientByOrganizationID,
  createAgencyOrganization,
  createClientOrganization,
  createProjectBasedClient,
  createCategoryBasedClient,
} from "graphql/organization";
import {createClientProject} from "graphql/client";
import {getUserAndOrganization} from "graphql/user";
import { userActions } from "./userSlice"
import { vendorActions } from "./vendorSlice";
import {
  getHighestOrgType,
  SYSTEM,
  AGENCY,
  CLIENT,
} from 'constants/organization_types'
import {client_mode} from "constants/client_properties";
import {updateLogo} from "services/appResources";

const adapter = createEntityAdapter()
const initialState = adapter.getInitialState({
  status: "idle",
  selectedAgency: null,
  userOrganization: null,
  selectedClient: null,
  organizations: []
})

const actions = {
  populateOrganizations: ({location = {}, params = {}}) => async (dispatch, getState) => {
    // this is not a problem for client users
    const state = getState()
    const {user, organization} = state
    const { agencyId, clientId } = params
    const userOrgType = getHighestOrgType(organization.userOrganization.type)

    let client;
    if(clientId) {
      const { data: clientData } = await API.graphql({
        query: clientByOrganizationID,
        variables: {
          organizationID: clientId
        }
      })
      client = clientData?.clientByOrganizationID?.items?.[0]
    }

    let useAgencyId
    if(userOrgType === SYSTEM) {
      // for system admins
      useAgencyId = agencyId || client.agencyOrganizationID
    } else if(organization.userOrganization.id === client.agencyOrganizationID) {
      // for agency admins
      useAgencyId = organization.userOrganization.id
    } else {
      // for agency admins looking at the wrong agency
      throw new Error("User Not Allowed")
    }
    if(!organization?.selectedAgency && useAgencyId) {
      await dispatch(organizationActions.setSelectedAgency({id: useAgencyId}))
      await dispatch(organizationActions.getClients(useAgencyId))
        // todo - check if user is agency and if this client belong to this agency
      if(clientId && client) {
        await dispatch(organizationActions.setSelectedClient({id: clientId}))
        await dispatch(vendorActions.fetchVendors(client.id))
      }
    }
  },
  resetOrganizations: () => async (dispatch) => {
    await dispatch(organizationSlice.actions.setSelectedAgency(null))
    await dispatch(organizationSlice.actions.setSelectedClient(null))
  },
  createAgency: (inputs) => async (dispatch) => {
    const created = await API.graphql({
      query: createAgencyOrganization,
      variables: {
        name: inputs.name,
        themeColor: inputs.themeColor,
      }
    })
    const { id } = created.data.createORGANIZATION
    const { logo } = inputs
    if(id && logo) {
      await dispatch(actions.uploadAndUpdateOrganizationLogo({id, logo}))
    }
    return {
      payload: {
        success: true, id
      }
    }
  },
  createClient: (inputs) => async (dispatch) => {
    const created = await API.graphql({
      query: createClientOrganization,
      variables: {
        ...inputs
      }
    })
    const { id } = created.data.createORGANIZATION
    const { logo, clientMode, agencyId } = inputs
    if(id) {
      const query = (clientMode === client_mode.category) ? createCategoryBasedClient : createProjectBasedClient
      const client = await API.graphql({
        query,
        variables: {
          organizationID: id,
          agencyOrganizationID: agencyId
        }
      })

      const { id: clientID } = client.data.createCLIENT
      if(clientID) {
        await API.graphql({
          query: createClientProject,
          variables: {
            name: "Client Default Project",
            isDefault: true,
            clientID
          }
        })
      }
      if(logo) {
        await dispatch(actions.uploadAndUpdateOrganizationLogo({id, logo}))
      }
    }
    return {
      payload: {
        success: true
      }
    }
  },
  uploadAndUpdateOrganizationLogo: (inputs) => async (dispatch) => {
    const {id, logo} = inputs
    const fetched = await fetch(logo.content)
    const blobbed = await fetched.blob()
    await updateLogo(
      `${id}.${logo.extension}`,
      blobbed,
      logo.type,
    )
    await dispatch(
      organizationActions.updateOrganizationInformation({
        input: {
          id,
          logo: `${id}.${logo.extension}`
        },
        isClient: false,
      })
    )
  },
  updateOrganizationInformation: createAsyncThunk('organization/updateOrganizationInformation', async ({input, isClient}, {dispatch}) => {
    const response = await API.graphql({
      query: updateORGANIZATION,
      variables: { input }
    })
    await dispatch(organizationActions.updateSelectedClient({
      id: input.id,
      updates: {
        ...input
      }
    }))
    return {
      organization: response.data.updateORGANIZATION,
      isClient,
      success: true
    }
  }),
  getOrganizationByUser: (email) => async (dispatch, getState) => {
    const response = await API.graphql({
      query: getUserAndOrganization,
      variables: {
        email,
      }
    })
    const loggedInUser = response?.data?.userByEmail?.items && response.data.userByEmail.items[0]
    const userOrganization = loggedInUser?.userOrganization
    dispatch(organizationSlice.actions.setUserOrganization({userOrganization}))
    dispatch(userActions.setLoggedInUser({loggedInUser}))

    if(userOrganization.type?.includes(AGENCY)) {
      dispatch(organizationSlice.actions.setSelectedAgency({id: userOrganization.id}))
    } else if(userOrganization.type?.includes(CLIENT)) {
      const { data } = await API.graphql({
        query: clientByOrganizationID,
        variables: {
          organizationID: userOrganization.id
        }
      })
      const client = data?.clientByOrganizationID?.items?.length ? data.clientByOrganizationID.items[0] : {}
      dispatch(organizationSlice.actions.setSelectedClient({
        client: {
          clientID: client.id,
          ...client,
          ...(client?.clientOrganization || {}),
        }
      }))
    }
  },
  getAgencies: createAsyncThunk('organization/getAgencies', async () => {
    // todo: make sure user has the correct permission
    const response = await API.graphql({
      query: listORGANIZATIONS,
      variables: {
        filter: {type: {contains: AGENCY}}
      }
    })
    return {
      organizations: response?.data?.listORGANIZATIONS?.items
    }
  }),
  getClients: createAsyncThunk('organization/getClients', async (agencyId) => {
    // todo: make sure user has the correct permission
    const response = await API.graphql({
      query: clientByAgencyOrganizationID,
      variables: {
        agencyOrganizationID: agencyId
      }
    })
    return {
      clients: response?.data?.clientByAgencyOrganizationID?.items
    }
  })
}

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state = initialState
    },
    setUserOrganization: (state, action) => {
      state.userOrganization = action.payload.userOrganization
      if(getHighestOrgType(action.payload.userOrganization?.type) === AGENCY) {
        state.organizations.push(action.payload.userOrganization)
      }
    },
    setSelectedAgency: (state, action) => {
      if(!action.payload?.id) {
        state.selectedAgency = null;
      } else {
        state.selectedAgency = state.organizations?.find(
          organization => organization.id === action.payload.id
        )
      }
    },
    setSelectedClient: (state, action) => {
      if(!action.payload?.id && !action.payload?.client) {
        state.selectedClient = null;
        return;
      }
      if(state.selectedAgency && action.payload.id) {
        state.selectedClient = state.selectedAgency?.clients?.find(
          organization => organization.organizationID === action.payload.id
        )
      } else if(action.payload.client) {
        state.selectedClient = action.payload.client
      }
    },
    updateSelectedClient: (state, action) => {
      if(state.selectedAgency && action.payload.id) {
        state.selectedAgency.clients = state.selectedAgency?.clients?.map(
          organization => {
            if(organization.organizationID === action.payload.id) {
              const updatedOrganization = {...organization, ...action.payload.updates}
              state.selectedClient = updatedOrganization
              return updatedOrganization
            }
            return organization
          }
        )
      } else if(action.payload.client && action.payload.updates) {
        state.selectedClient = {
          ...state.selectedClient,
          ...action.payload.updates
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      // update organization information
      .addCase(actions.updateOrganizationInformation.pending, (state, action) => {
        state.status = 'updating'
      })
      .addCase(actions.updateOrganizationInformation.fulfilled, (state, action) => {
        // TODO: simplify structure //
        if(action.payload?.organization?.id === state.userOrganization?.id) {
          // if the org updated is the logged in user's org
          state.userOrganization = {
            ...(state.userOrganization || {}),
            ...action.payload.organization
          };
        }
        if (action.payload.isClient) {
          // if the org updated is a client
          let selectedClient;
          if (state?.selectedAgency?.clients) {
            state.selectedAgency.clients = state.selectedAgency.clients.map(client => {
              if (client.id === action.payload.organization?.id) {
                selectedClient = {
                  ...client,
                  ...action.payload.organization
                }
                return selectedClient
              }
              return client;
            })
          }
          if (!selectedClient) {
            selectedClient = {
              ...state.selectedClient,
              ...action.payload.organization
            }
          }
          state.selectedClient && (state.selectedClient = {...selectedClient});
        } else {
          // if the org updated is an agency
          let selectedAgency;
          state.organizations = state.organizations.map(org => {
            if(org.id === action.payload?.organization?.id) {
              selectedAgency = {
                ...org,
                ...action.payload.organization
              }
              return selectedAgency;
            }
            return org;
          })
          state.selectedAgency && (state.selectedAgency = {...selectedAgency})
        }
        state.status = 'success';
        state.error = null;
      })
      .addCase(actions.updateOrganizationInformation.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      .addCase(actions.getAgencies.pending, (state, action) => {
        state.status = 'loading'
      })
      // get agencies
      .addCase(actions.getAgencies.fulfilled, (state, action) => {
        state.organizations = action.payload.organizations?.filter(
          org => org.id !== state.userOrganization?.id
        );
        state.status = 'success';
        state.error = null;
      })
      .addCase(actions.getAgencies.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      // get clients
      .addCase(actions.getClients.pending, (state, action)=> {
        state.status = 'loading'
      })
      .addCase(actions.getClients.fulfilled, (state, action)=> {
        const clients = action.payload.clients.map(client => ({
          clientID: client.id,
          ...client,
          ...(client?.clientOrganization || {}),
        }))
        state.selectedAgency = {
          ...(state.selectedAgency || {}),
          clients
        }
        state.status = 'success'
      })
      .addCase(actions.getClients.rejected, (state, action)=> {
        state.status = 'failure'
      })
  }
})

export const organizationState = state => ({
  ...state.organization,
  userOrgType: getHighestOrgType(state.organization.userOrganization?.type)
})
export const userOrganizationState = state => ({
  userOrganization: state.organization.userOrganization,
  clientID: state.organization?.selectedClient?.clientID,
  userOrgType: getHighestOrgType(state.organization.userOrganization?.type)
})
export const organizationActions = {
  ...organizationSlice.actions,
  ...actions,
}
export default organizationSlice.reducer
