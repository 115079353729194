import React from "react";
import {CircularProgress} from "@mui/material";

export default ({size=50}) => (
  <CircularProgress
    size={size}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px',
      marginLeft: '-12px',
      color: "gray",
      zIndex: 1000
    }}
  />
)
