import React from "react";
import Chip from "@mui/material/Chip";

import "./status-indicator.scss";

export default (props) => {
  const label = String(props.label).replace("_", " ").toLowerCase()
  const colorScheme = label.replace(" ", "-")
  const size = props.size || "medium"
  return <Chip {...props} label={label} className={`status-chip ${colorScheme} ${size}`} />
}
