const formatRemovers = {
  phone: getPhoneNumberDigits,
  amount: getAmountInt,
  deductible: getAmountInt,
  retention: getAmountInt,
}

export function removeFormat({name, value}) {
  if(formatRemovers[name]) {
    return formatRemovers[name](value)
  }
  return value
}

export function getPhoneNumberDigits(formattedPhoneNumber) {
  const digits = String(formattedPhoneNumber).replaceAll(/\D/g, "")
  return digits.substring(0, 10)
}

export function getZipCodeDigits(formattedZipCode) {
  const digits = String(formattedZipCode).replaceAll(/\D/g, "")
  return digits.substring(0, 5)
}

export function getAmountInt(value) {
  if(value === "" || value === null || value === undefined) {
    return value
  }
  const parsed = parseInt((value || "0").replace(/,/g,''), 10)
  if(!isNaN(parsed)) {
    return parsed
  }
}

export function formatPhoneNumber(phoneNumber) {
  const digits = String(phoneNumber).replaceAll(/\D/g, "")
  const leftParenthesis = digits.charAt(0) ? "(" : ""
  const rightParenthesis = digits.charAt(3) ? ") " : ""
  const dashBetween = digits.charAt(6) ? "-" : ""

  return (
    leftParenthesis
    + digits.substring(0, 3)
    + rightParenthesis
    + digits.substring(3, 6)
    + dashBetween
    + digits.substring(6,10)
  )
}

export function formatCurrencyInt(number) {
  if(number === "" || number === undefined || number === null) {
    return ""
  }
  const formatted = Intl.NumberFormat().format(number)
  return formatted
}

export function createFileNameUnique(fileName = "", existingFileNames = []) {
  const extension = fileName.split(".").pop()
  let index = 0;
  let nameFound = false
  let fileNameWithoutExtension = getFileNameWithoutExtension(fileName)
  while (nameFound === false) {
    const existing = existingFileNames.find(
      existingFileName => existingFileName.indexOf(fileNameWithoutExtension + (!!index ? `(${index})` : "")) !== -1
    )
    if(existing) {
      index++
    } else {
      nameFound = true
    }
  }
  return fileNameWithoutExtension + (!!index ? `(${index})` : "") + "." + extension
}

function getFileNameWithoutExtension(fileName) {
  let fileNameWithoutExtension = fileName
  const indexLastDot = fileName.lastIndexOf(".")
  if(indexLastDot !== -1) {
    fileNameWithoutExtension = fileNameWithoutExtension.substring(0, indexLastDot)
  }
  return String(fileNameWithoutExtension).trim()
}
