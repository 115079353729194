import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Stack, Box } from "@mui/material"
import {useLocation, useNavigate} from "react-router-dom";
import "./side-menu.scss"

export const SideMenu = ({
  items,
  vertical=true,
  action,
  style={},
  className="",
  activeItem="",
  handleItemClick
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  activeItem = activeItem || String(location.hash).replace("#/", "")
  handleItemClick = handleItemClick || function (key) {
    const hash = `#/${key}`
    navigate(location.pathname + hash)
  }

  return (
    <Menu text vertical={vertical} className={`side-menu ${className}`} style={style}>
      {
        vertical && action
      }
      {
        items?.map((item, index) => (
          <Menu.Item
            key={`side-menu-item-${index}`}
            content={item.label || item.key}
            active={activeItem === item.key}
            onClick={()=>handleItemClick(item.key)}
          />
        ))
      }
      {
        !vertical && action
      }
    </Menu>
  )
}

export default function SideMenuContainer ({ items, children, action }) {
  return (
    <Stack direction="row" spacing={4}>
      <SideMenu items={items} action={action}/>
      <Box sx={{width: "100%"}}>
        {children}
      </Box>
    </Stack>
  )
}
