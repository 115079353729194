import React, {useEffect, useState} from "react";
import { useParams, Navigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { getLogo } from "services/appResources";
import { ADMIN_NAV_PATH } from "constants/routes";
import OrganizationSettings from "components/OrganizationSettings";
import { Loader } from 'semantic-ui-react'

export default function AgencyDetail () {
  const { agencyId } = useParams()
  const { organizations } = useSelector(organizationState)
  const fetchedOrganization = organizations?.find(org => org.id === agencyId)

  const [organization, setOrganization] = useState()

  const [logo, setLogo] = useState("")
  useEffect(() => {
    setOrganization(fetchedOrganization)

    if(organization?.logo) {
      getLogo(
        organization.logo,
        (response) => setLogo(response)
      )
    }
  }, [fetchedOrganization])

  return (
    organization ? 
      <OrganizationSettings organization={organization} setOrganization={setOrganization} logo={logo} isAffiliate={true} />
      :
      <Loader active  indeterminate size='huge' />
  )
}
