import React from "react";
import { Button, TextField, Typography, ButtonGroup, Stack, Box, Checkbox, FormControlLabel, Select, MenuItem } from "@mui/material";
import { isEmail } from "react-multi-email";
import { SubtitleInline, SectionTitle } from "components/shared/Typography";
import { getProjectLabel, getVendorLabel, getTableRowsPerPage } from "constants/organization_types";

export default function ClientTypeOrganizationConfiguration({
  organization = {},
  tableRowsPerPage,
  validationErrors = {},
  editOrganization = false,
  isCheckedPrimaryNotificationEAddress = false,
  haveUpdates = () => {},
  setEditOrganization = () => {},
  setValidationErrors = () => {},
  setTableRowsPerPage = () => {},
  setUpdatedVendorLabel = () => {},
  setUpdatedProjectLabel = () => {},
  updatedUseDefaultProject = () => {},
  setUpdateUseDefaultProject = () => {},
  handleCancelUpdateOrganization = () => {},
  handleConfirmUpdateOrganization = () => {},
  setPrimaryNotificationEmailAddress = () => {},
  setIsCheckedPrimaryNotificationEAddress = () => {},
}) {
  return (
    <Box>
      <SectionTitle sx={{ mb: 2 }}>
        Configuration
        {editOrganization ? (
          <ButtonGroup sx={{ ml: 2 }}>
            <Button onClick={handleCancelUpdateOrganization}>Cancel</Button>
            <Button disabled={!haveUpdates() || Object.entries(validationErrors).length > 0} onClick={handleConfirmUpdateOrganization}>
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <Button onClick={() => setEditOrganization(true)}>Edit</Button>
        )}
      </SectionTitle>
      <Stack spacing={3} direction="column">
        <Stack direction="row" spacing={10} sx={{ mb: 2 }}>
          {organization.mode && (
            <div style={{ flexBasis: "15%" }}>
              <SubtitleInline>Client Mode</SubtitleInline>
              <Typography variant="body1">{organization.mode}</Typography>
            </div>
          )}
        </Stack>
        <Stack spacing={10} direction="row" alignItems="start">
          <FormControlLabel
            control={
              <Checkbox
                checked={updatedUseDefaultProject}
                color="default"
                disabled={!editOrganization}
                onChange={(e) => setUpdateUseDefaultProject((prev) => !prev)}
              />
            }
            label={
              <>
                <SubtitleInline>Enforce Coverage Requirements by</SubtitleInline>
                <Typography variant="body1">Client Default Project</Typography>
              </>
            }
          />
        </Stack>
        <Stack spacing={10} direction="row" alignItems="start">
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckedPrimaryNotificationEAddress}
                color="default"
                disabled={!editOrganization}
                onChange={(e) => setIsCheckedPrimaryNotificationEAddress((prev) => !prev)}
              />
            }
            label={
              <>
                <SubtitleInline>Primary Notification Email Address</SubtitleInline>
                <TextField
                  disabled={!editOrganization}
                  required
                  size="small"
                  helperText={validationErrors["primaryNotificationEmailAddress"] ? "Enter a valid email address !!!" : ""}
                  error={validationErrors?.primaryNotificationEmailAddress}
                  inputProps={{ pattern: "^\\S+@\\S+\\.\\S+$" }}
                  variant="outlined"
                  defaultValue={organization.primaryNotificationEmailAddress}
                  onChange={(e) => {
                    setPrimaryNotificationEmailAddress(e.target.value);
                    if (!isEmail(e.target.value) && e.target.value) {
                      setValidationErrors({
                        ...validationErrors,
                        primaryNotificationEmailAddress: true,
                      });
                    } else {
                      setValidationErrors((prev) => {
                        delete prev["primaryNotificationEmailAddress"];
                        return prev;
                      });
                    }
                  }}
                />
              </>
            }
          />
        </Stack>
        <Stack spacing={10} direction="column">
          <div>
            <SubtitleInline>Vendor Label:</SubtitleInline>
            {editOrganization ? (
              <TextField
                fullWidth
                required
                variant="standard"
                defaultValue={getVendorLabel(organization)}
                onChange={(e) => setUpdatedVendorLabel(e.target.value)}
              />
            ) : (
              <Typography variant="body1">{getVendorLabel(organization)}</Typography>
            )}
          </div>
        </Stack>
        <Stack spacing={10} direction="column">
          <div>
            <SubtitleInline>Project Label:</SubtitleInline>
            {editOrganization ? (
              <TextField
                fullWidth
                required
                variant="standard"
                defaultValue={getProjectLabel(organization)}
                onChange={(e) => setUpdatedProjectLabel(e.target.value)}
              />
            ) : (
              <Typography variant="body1">{getProjectLabel(organization)}</Typography>
            )}
          </div>
        </Stack>
        <Stack display="flex" spacing={5} direction="row" alignItems="center">
          <SubtitleInline>Table rows per page: </SubtitleInline>
          <Select
            size="small"
            value={tableRowsPerPage || getTableRowsPerPage(organization)}
            displayEmpty={true}
            onChange={(e) => {
              setTableRowsPerPage(e.target.value);
            }}
            disabled={!editOrganization}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </Stack>
      </Stack>
    </Box>
  );
}
