import React, { useEffect, useState } from "react";
import { AccountSettings } from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { userActions } from "redux/userSlice";
import { useDispatch } from "react-redux";
import { Divider } from "semantic-ui-react";
import { Box, Typography, ButtonGroup, Button, Stack, TextField, FormControl } from "@mui/material";

import SideMenuContainer from "components/shared/SideMenuContainer";
import LoaderButton from "components/shared/LoaderButton";
import { SectionTitle } from "components/shared/Typography";
import Snackbar from "components/shared/Snackbar";

import "./settings.scss";

const MENU_ITEMS = {
  details: "details",
};

const menuItems = [{ key: MENU_ITEMS.details, label: "Details" }];

export default function Settings({ loggedInUser, userOrganization, clientMode, user }) {
  const location = useLocation();
  const navigate = useNavigate();
  const activeItem = String(location.hash).replace("#/", "");

  const [userData, setUserData] = useState(loggedInUser);
  const [validationErrors, setValidationErrors] = useState({});
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [message, setMessage] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    setUserData({ ...loggedInUser });
    // dispatch(organizationActions.resetOrganizations())
  }, [loggedInUser?.email]);

  useEffect(() => {
    if (!location.hash) {
      navigate(location.pathname + `#/${menuItems[0].key}`);
    }
  }, []);

  const handleConfirmUpdateUser = () => {
    onUserUpdate(() => {
      const { id, email, firstName, lastName, userOrganization } = userData;
      return dispatch(
        userActions.updateUserDetails({
          email,
          input: { id, firstName, lastName },
          organizationId: userOrganization?.id,
        })
      );
    });
  };

  const handleCancelUpdateUser = () => {};

  const haveUpdates = () => {
    return userData?.firstName !== loggedInUser?.firstName || userData?.lastName !== loggedInUser?.lastName;
  };

  function updateUserField(event, trim = false, skipValidation = false) {
    const key = event.target.name;
    let value = event.target.value;

    setUserData({
      ...userData,
      [key]: trim ? String(value).trim() : value,
    });
  }

  async function onUserUpdate(action, success) {
    setUpdateUserLoading(true);
    const result = await action();
    setUpdateUserLoading(false);
    if (result?.payload?.success) {
      success && success();
      setMessage({
        severity: "success",
        text: "Successfully updated user",
      });
      await dispatch(userActions.setLoggedInUser({ loggedInUser: { ...userData } }));
    } else {
      setMessage({
        severity: "error",
        text: "Unable to update user information",
      });
    }
  }

  const fieldProps = {
    onChange: updateUserField,
    // onBlur: validateForm,
    fullWidth: true,
    size: "small",
  };

  return (
    <>
      <SideMenuContainer items={menuItems}>
        {activeItem === MENU_ITEMS.details && (
          <Box width={"40%"}>
            <SectionTitle sx={{ mb: 2 }}>
              Details
              <ButtonGroup sx={{ ml: 2 }}>
                <Button onClick={handleCancelUpdateUser}>Cancel</Button>
                <LoaderButton
                  disabled={!haveUpdates() || Object.entries(validationErrors).length > 0}
                  onClick={handleConfirmUpdateUser}
                  loading={updateUserLoading}
                >
                  Save
                </LoaderButton>
              </ButtonGroup>
            </SectionTitle>
            <Stack direction="column" spacing={2}>
              <FormControl>
                <Typography variant="body1">First Name</Typography>
                <TextField {...fieldProps} name="firstName" value={userData?.firstName} />
              </FormControl>

              <FormControl>
                <Typography variant="body1">Last Name</Typography>
                <TextField {...fieldProps} name="lastName" value={userData?.lastName} />
              </FormControl>
            </Stack>
            <Divider />
            <SectionTitle sx={{ mb: 2 }}>Password Management</SectionTitle>
            <Stack direction="row" mt={2}>
              <AccountSettings.ChangePassword
                onSuccess={() => {
                  setMessage({
                    severity: "success",
                    text: "Successfully updated password",
                  });
                }}
                onError={() => {
                  setMessage({
                    severity: "error",
                    text: "Unable to update password. Please try again",
                  });
                }}
                style={{ flexGrow: 1 }}
              />
            </Stack>
            <Snackbar message={message} setMessage={setMessage} />
          </Box>
        )}
      </SideMenuContainer>
    </>
  );
}
