import { Typography } from "@mui/material"

export default (props) => (
  <Typography
    variant="h6"
    component="div"
    sx={{
      fontWeight: 700,
      fontSize: "1.15em",
      ...(props.sx || {})
    }}
  >
    {props.children}
  </Typography>
)
