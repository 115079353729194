import React, { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { FormControl, FormControlLabel, Typography } from "@mui/material";

import "./file-upload-drag-and-drop.scss";

export default function FileUploadDragAndDrop(props) {
  const { buttonText = "Upload", buttonProps = {}, sx = {}, inputId = `upload-button-${Date.now()}`, onChange = () => { } } = props;
  const isDisabled = buttonProps.disabled;

  //drag state
  const [dragActive, setDragActive] = useState(false);

  //handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDisabled) {
      return;
    }
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!isDisabled && e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      onChange(e);
    }
  };
  return (
    <FormControl
    className={`dropzone ${dragActive && !isDisabled && "animate"}`}
    sx={{ padding: 2,...sx, color: buttonProps.disabled ? '#9e9e9e' : '#1976d2', alignItems: "center" }}
    onDragEnter={handleDrag}
    onDragLeave={handleDrag}
    onDragOver={handleDrag}
    onDrop={handleDrop}
    >
      <FormControlLabel
        key={inputId}
        sx={{margin: 0}}
        control={
          <>
            {!isDisabled && <input style={{ display: "none" }} accept=".pdf" id={inputId} type="file" onChange={onChange} />}
            <Typography variant="subtitle1"  display="flex">
              {buttonProps.startIcon}
              {buttonText}
            </Typography>
          </>
        }
        label={""}
      />
    </FormControl>
  );
}
