import React, {useState, forwardRef} from "react";
import {Menu, MenuItem, Box, Typography, TextField} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DatePicker from "react-datepicker";
import StatusIndicator from "components/shared/StatusIndicator";
import {awsDateToUS, getDateFromDateString, usToAWSDate} from "utils/dateUtils";

export default ({
  status,
  getCertificateStatus,
  certificateDate,
  handleCertificateInfoUpdate,
  isEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const CertificateDateInput = forwardRef(({value, onClick, error}, ref)=>(
    <TextField
      value={value}
      onClick={onClick}
      error={error}
      ref={ref}
      variant={"outlined"}
      label={"Date"}
      size={"small"}
      inputProps={{
        sx: {maxWidth: "75px"}
      }}
    />
  ))

  return (
    <Box sx={{display: "flex", alignItems: "center", mb: 3}}>
      {
        isEdit &&
        <Box>
          <StatusIndicator
            label={status || "No Status"}
            onClick={handleClick}
            icon={<KeyboardArrowDownIcon />}
          />
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {
              status !== "APPROVED" &&
              <MenuItem onClick={()=>getCertificateStatus("APPROVED")}>
                <StatusIndicator label={"Approved"}/>
              </MenuItem>
            }
            {/*{*/}
            {/*  status !== "PENDING" &&*/}
            {/*  <MenuItem onClick={()=>getCertificateStatus("PENDING")}>*/}
            {/*    <StatusIndicator label={"Pending"}/>*/}
            {/*  </MenuItem>*/}
            {/*}*/}
            {
              status !== "INACTIVE" &&
              <MenuItem onClick={()=>getCertificateStatus("INACTIVE")}>
                <StatusIndicator label={"Inactive"}/>
              </MenuItem>
            }
            {
              status !== "NOT_APPROVED" &&
              <MenuItem onClick={()=>getCertificateStatus("NOT_APPROVED")}>
                <StatusIndicator label={"Not Approved"}/>
              </MenuItem>
            }
            {
              status !== "EXPIRED" &&
              <MenuItem onClick={()=>getCertificateStatus("EXPIRED")}>
                <StatusIndicator label={"Expired"}/>
              </MenuItem>
            }
            {
              status !== "REVIEW" &&
              <MenuItem onClick={()=>getCertificateStatus("REVIEW")}>
                <StatusIndicator label={"Review"}/>
              </MenuItem>
            }
          </Menu>
        </Box>
      }
      {
        !isEdit &&
        <StatusIndicator label={status || "No Status"}/>
      }

      <Box sx={{ml: "auto"}}>
        {
          isEdit &&
          <DatePicker
            customInput={<CertificateDateInput />}
            selected={
              certificateDate
                ? getDateFromDateString(certificateDate)
                : false
            }
            onChange={
              value => handleCertificateInfoUpdate({
                target: {
                  name: "certificateDate",
                  value: usToAWSDate(value.toLocaleDateString("en-US"))
                }
              })
            }
          />
        }

        {
          !isEdit && certificateDate &&
          <TextField
            value={awsDateToUS(certificateDate)}
            variant={"outlined"}
            label={"Date"}
            size={"small"}
            inputProps={{
              sx: {maxWidth: "75px"}
            }}
            disabled={true}
          />
        }

      </Box>
    </Box>
  )
}
