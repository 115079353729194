import Settings from "components/Settings";
import Dashboard from "components/Dashboard";
import Projects from "components/Projects";
import ProjectDetails from "components/ProjectDetails";
import Categories from "components/Categories";
import CategoryDetails from "components/CategoryDetails";
import Vendors from "components/Vendors";
import VendorDetails from "components/VendorDetails";
import VendorDocumentUpload from "components/VendorDocumentUpload";
import Agencies from "components/Agencies";
import AgencyDetail from "components/AgencyDetail";
import UserOrganizationSettings from "components/UserOrganizationSettings";
import Clients from "components/Clients";
import ClientDetails from "components/ClientDetails";
import CertificateDetails from "components/CertificateDetails";
import Certificates from "components/Certificates"

import { Agency, Client } from "components/containers"

import {client_mode} from "./client_properties";
import{SYSTEM,AGENCY,CLIENT} from "./organization_types"

export function getNavigationPath(path, pathReplacements) {
  Object.keys(pathReplacements)?.forEach(replaceKey => {
    path = String(path).replace(":" + replaceKey, pathReplacements[replaceKey])
  })
  return path;
}

export function useNavPath(orgType) {
  const orgTypeNavMapping = {
    [SYSTEM]: ADMIN_NAV_PATH,
    [AGENCY]: AGENCY_NAV_PATH,
    [CLIENT]: CLIENT_NAV_PATH,
  }
  return orgTypeNavMapping[orgType]
}

export const ADMIN_NAV_PATH = {
  // admin related routes
  AGENCIES: "/",
  // agency related routes
  AGENCY_ROOT: "/agency/:agencyId",
  AGENCY_DETAILS: "/agency/:agencyId/settings",
  AGENCY_CLIENTS: "/agency/:agencyId/clients",
  // client related routes
  CLIENT_ROOT: "/client/:clientId",
  // CLIENT_DASHBOARD: "/client/:clientId/dashboard",
  CLIENT_DETAILS: "/client/:clientId/settings",
  CLIENT_PROJECTS: "/client/:clientId/projects",
  CLIENT_PROJECT_DETAILS: "/client/:clientId/projects/:projectId",
  CLIENT_CATEGORIES: "/client/:clientId/categories",
  CLIENT_CATEGORY_DETAILS: "/client/:clientId/categories/:categoryId",
  CLIENT_VENDORS: "/client/:clientId/vendors",
  CLIENT_CERTIFICATES: "/client/:clientId/certificates",
  CLIENT_VENDOR_DETAILS: "/client/:clientId/vendors/:vendorId/:referrer/:referrerId",
  CLIENT_CERTIFICATE_DETAILS: "/client/:clientId/vendors/:vendorId/certificates/:certificateId",
  // vendor related routes
  VENDOR_DOCUMENT_UPLOAD: "/client/:clientId/vendor/upload",
  // user related routes
  SETTINGS: "/user-settings",
  ORGANIZATION_SETTINGS: "/organization-settings",
}

export const AGENCY_NAV_PATH = {
  // agency related routes
  AGENCY_CLIENTS: "/",
  // client related routes
  CLIENT_ROOT: "/client/:clientId",
  CLIENT_DETAILS: "/client/:clientId/settings",
  // CLIENT_DASHBOARD: "/client/:clientId/dashboard",
  CLIENT_PROJECTS: "/client/:clientId/projects",
  CLIENT_PROJECT_DETAILS: "/client/:clientId/projects/:projectId",
  CLIENT_CATEGORIES: "/client/:clientId/categories",
  CLIENT_CATEGORY_DETAILS: "/client/:clientId/categories/:categoryId",
  CLIENT_VENDORS: "/client/:clientId/vendors",
  CLIENT_CERTIFICATES: "/client/:clientId/certificates",
  CLIENT_VENDOR_DETAILS: "/client/:clientId/vendors/:vendorId/:referrer/:referrerId",
  CLIENT_CERTIFICATE_DETAILS: "/client/:clientId/vendors/:vendorId/certificates/:certificateId",
  // vendor related routes
  VENDOR_DOCUMENT_UPLOAD: "/client/:clientId/vendor/upload",
  // user related routes
  SETTINGS: "/user-settings",
  ORGANIZATION_SETTINGS: "/organization-settings",
}

export const CLIENT_NAV_PATH = {
  // client related routes
  // CLIENT_DASHBOARD: "/",
  CLIENT_VENDORS: "/",
  CLIENT_CERTIFICATES: "/certificates",
  CLIENT_PROJECTS: "/projects",
  CLIENT_PROJECT_DETAILS: "/projects/:projectId",
  CLIENT_CATEGORIES: "/categories",
  CLIENT_CATEGORY_DETAILS: "/categories/:categoryId",
  CLIENT_VENDOR_DETAILS: "/vendors/:vendorId/:referrer/:referrerId",
  CLIENT_CERTIFICATE_DETAILS: "/client/:clientId/vendors/:vendorId/certificates/:certificateId",
  // vendor related routes
  VENDOR_DOCUMENT_UPLOAD: "/vendor/upload",
  // user related routes
  SETTINGS: "/user-settings",
  ORGANIZATION_SETTINGS: "/organization-settings",
}

export const ADMIN_ROUTES = {
  // admin screens
  [ADMIN_NAV_PATH.AGENCIES]: {element: Agencies},
  // agency screens

  [ADMIN_NAV_PATH.AGENCY_ROOT]: {
    container: true,
    element: Agency,
    children: {
      [ADMIN_NAV_PATH.AGENCY_DETAILS]: {element: AgencyDetail},
      [ADMIN_NAV_PATH.AGENCY_CLIENTS]: {element: Clients},
    }
  },

  // client screens
  [ADMIN_NAV_PATH.CLIENT_ROOT]: {
    container: true,
    element: Client,
    children: {
      [ADMIN_NAV_PATH.CLIENT_DETAILS]: {element: ClientDetails},
      // [ADMIN_NAV_PATH.CLIENT_DASHBOARD]: {element: Dashboard},
      [ADMIN_NAV_PATH.CLIENT_PROJECTS]: {element: Projects},
      [ADMIN_NAV_PATH.CLIENT_PROJECT_DETAILS]: {element: ProjectDetails},
      [ADMIN_NAV_PATH.CLIENT_CATEGORIES]: {element: Categories},
      [ADMIN_NAV_PATH.CLIENT_CATEGORY_DETAILS]: {element: CategoryDetails},
      [ADMIN_NAV_PATH.CLIENT_VENDORS]: {element: Vendors},
      [ADMIN_NAV_PATH.CLIENT_CERTIFICATES]: {element: Certificates},
      [ADMIN_NAV_PATH.CLIENT_VENDOR_DETAILS]: {element: VendorDetails},
      [ADMIN_NAV_PATH.CLIENT_CERTIFICATE_DETAILS]: {element: CertificateDetails},
    }
  },

  // vendor screens
  [ADMIN_NAV_PATH.VENDOR_DOCUMENT_UPLOAD]: {element: VendorDocumentUpload},
  // user screens
  [ADMIN_NAV_PATH.SETTINGS]: {element: Settings},
  [ADMIN_NAV_PATH.ORGANIZATION_SETTINGS]: {element: UserOrganizationSettings},
}

export const AGENCY_ROUTES = {
  // agency screens
  [AGENCY_NAV_PATH.AGENCY_CLIENTS]: {element: Clients},
  // client screens
  [ADMIN_NAV_PATH.CLIENT_ROOT]: {
    container: true,
    element: Client,
    children: {
      [AGENCY_NAV_PATH.CLIENT_DETAILS]: {element: ClientDetails},
      // [AGENCY_NAV_PATH.CLIENT_DASHBOARD]: {element: Dashboard},
      [AGENCY_NAV_PATH.CLIENT_PROJECTS]: {element: Projects},
      [AGENCY_NAV_PATH.CLIENT_PROJECT_DETAILS]: {element: ProjectDetails},
      [AGENCY_NAV_PATH.CLIENT_CATEGORIES]: {element: Categories},
      [AGENCY_NAV_PATH.CLIENT_CATEGORY_DETAILS]: {element: CategoryDetails},
      [AGENCY_NAV_PATH.CLIENT_VENDORS]: {element: Vendors},
      [AGENCY_NAV_PATH.CLIENT_CERTIFICATES]: {element: Certificates},
      [AGENCY_NAV_PATH.CLIENT_VENDOR_DETAILS]: {element: VendorDetails},
      [AGENCY_NAV_PATH.CLIENT_CERTIFICATE_DETAILS]: {element: CertificateDetails},
    }
  },
  // vendor screens
  [AGENCY_NAV_PATH.VENDOR_DOCUMENT_UPLOAD]: {element: VendorDocumentUpload},
  // user screens
  [AGENCY_NAV_PATH.SETTINGS]: {element: Settings},
  [AGENCY_NAV_PATH.ORGANIZATION_SETTINGS]: {element: UserOrganizationSettings},
}

export const CLIENT_ROUTES = {
  // client screens
  // [CLIENT_NAV_PATH.CLIENT_DASHBOARD]: {element: Dashboard},
  [CLIENT_NAV_PATH.CLIENT_PROJECTS]: {element: Projects},
  [CLIENT_NAV_PATH.CLIENT_PROJECT_DETAILS]: {element: ProjectDetails},
  [CLIENT_NAV_PATH.CLIENT_CATEGORIES]: {element: Categories},
  [CLIENT_NAV_PATH.CLIENT_CATEGORY_DETAILS]: {element: CategoryDetails},
  [CLIENT_NAV_PATH.CLIENT_VENDORS]: {element: Vendors},
  [CLIENT_NAV_PATH.CLIENT_CERTIFICATES]: {element: Certificates},
  [CLIENT_NAV_PATH.CLIENT_VENDOR_DETAILS]: {element: VendorDetails},
  [CLIENT_NAV_PATH.CLIENT_CERTIFICATE_DETAILS]: {element: CertificateDetails},
  // vendor screens
  [CLIENT_NAV_PATH.VENDOR_DOCUMENT_UPLOAD]: {element: VendorDocumentUpload},
  // user screens
  [CLIENT_NAV_PATH.SETTINGS]: {element: Settings},
  [CLIENT_NAV_PATH.ORGANIZATION_SETTINGS]: {element: UserOrganizationSettings},
}

export const ADMIN_NAVIGATION = [
  {
    excludedPaths: ["/user-settings"],
    items: [
      {
        path: ADMIN_NAV_PATH.AGENCIES,
        icon: "home",
        name: "All Affiliates",
      },
      {
        path: ADMIN_NAV_PATH.ORGANIZATION_SETTINGS,
        name: "Settings",
      }
    ]
  },
  {
    stateKey: "selectedAgency",
    items: [
      {
        path: ADMIN_NAV_PATH.AGENCY_CLIENTS,
        name: "Clients",
        stateKey: "selectedAgency",
        pathProp: "id",
        replacePath: "agencyId",
        icon: "sitemap",
      },
      {
        path: ADMIN_NAV_PATH.AGENCY_DETAILS,
        stateKey: "selectedAgency",
        icon: "cog",
        pathProp: "id",
        replacePath: "agencyId",
        name: "Settings"
      },
    ]
  },
  {
    stateKey: "selectedClient",
    items: [
      // hide add button from admin view
      // {
      //   action: "addVendor",
      //   icon: "plus",
      //   client_mode: client_mode.category,
      //   label: "Add A Vendor",
      //   name: "addVendorButton",
      // },
      // {
      //   action: "addVendor",
      //   icon: "plus",
      //   client_mode: client_mode.project,
      //   label: "Subcontractor",
      //   name: "addVendorButton",
      // },
      // {
      //   path: ADMIN_NAV_PATH.CLIENT_DASHBOARD,
      //   stateKey: "selectedClient",
      //   pathProp: "organizationID",
      //   replacePath: "clientId",
      //   icon: "chart bar outline",
      //   name: "Dashboard",
      // },
      {
        path: ADMIN_NAV_PATH.CLIENT_VENDORS,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.category,
        icon: "users",
        name: "Subcontractors",
        matchPartial: true,
      },
      {
        path: ADMIN_NAV_PATH.CLIENT_VENDORS,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.project,
        icon: "users",
        name: "Subcontractors",
        matchPartial: true,
      },
      {
        path: ADMIN_NAV_PATH.CLIENT_CERTIFICATES,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        name: "Certificates",
      },
      {
        path: ADMIN_NAV_PATH.CLIENT_CATEGORIES,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.category,
        icon: "block layout",
        name: "Categories",
        matchPartial: true,
      },
      {
        path: ADMIN_NAV_PATH.CLIENT_PROJECTS,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.project,
        icon: "list alternate outline",
        name: "Projects",
        matchPartial: true,
      },
      {
        path: ADMIN_NAV_PATH.CLIENT_DETAILS,
        stateKey: "selectedClient",
        icon: "cog",
        name: "Settings",
        pathProp: "organizationID",
        replacePath: "clientId",
      }
    ]
  }
]

export const AGENCY_NAVIGATION = [
  {
    path: AGENCY_NAV_PATH.AGENCY_CLIENTS,
    icon: "sitemap",
    name: "Clients",
  },
  {
    stateKey: "selectedClient",
    items: [
      // hide add button from agency view
      // {
      //   action: "addVendor",
      //   icon: "plus",
      //   client_mode: client_mode.category,
      //   label: "Add A Vendor",
      //   name: "addVendorButton",
      // },
      // {
      //   action: "addVendor",
      //   icon: "plus",
      //   client_mode: client_mode.project,
      //   label: "Subcontractor",
      //   name: "addVendorButton",
      // },
      // {
      //   path: AGENCY_NAV_PATH.CLIENT_DASHBOARD,
      //   stateKey: "selectedClient",
      //   pathProp: "organizationID",
      //   replacePath: "clientId",
      //   icon: "home",
      //   name: "Dashboard",
      // },
      {
        path: AGENCY_NAV_PATH.CLIENT_VENDORS,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.category,
        icon: "users",
        name: "Subcontractors",
        matchPartial: true,
      },
      {
        path: AGENCY_NAV_PATH.CLIENT_VENDORS,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.project,
        icon: "users",
        name: "Subcontractors",
        matchPartial: true,
      },
      {
        path: AGENCY_NAV_PATH.CLIENT_CERTIFICATES,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        name: "Certificates",
      },
      {
        path: AGENCY_NAV_PATH.CLIENT_CATEGORIES,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.category,
        icon: "block layout",
        name: "Categories",
        matchPartial: true,
      },
      {
        path: AGENCY_NAV_PATH.CLIENT_PROJECTS,
        stateKey: "selectedClient",
        pathProp: "organizationID",
        replacePath: "clientId",
        client_mode: client_mode.project,
        icon: "list alternate outline",
        name: "Projects",
        matchPartial: true,
      },
      {
        path: AGENCY_NAV_PATH.CLIENT_DETAILS,
        stateKey: "selectedClient",
        icon: "cog",
        name: "Settings",
        pathProp: "organizationID",
        replacePath: "clientId",
      }
    ]
  }
]

export const CLIENT_NAVIGATION = [
  {
    // stateKey: "selectedClient",
    items: [
      // {
      //   action: "addVendor",
      //   icon: "plus",
      //   client_mode: client_mode.category,
      //   label: "Add A Vendor",
      // },
      // {
      //   action: "addVendor",
      //   icon: "plus",
      //   client_mode: client_mode.project,
      //   label: "Subcontractor",
      // },
      // {
      //   path: CLIENT_NAV_PATH.CLIENT_DASHBOARD,
      //   icon: "home",
      //   name: "Dashboard",
      // },
      {
        path: CLIENT_NAV_PATH.CLIENT_VENDORS,
        icon: "users",
        client_mode: client_mode.category,
        name: "Subcontractors",
        // matchPartial: true
      },
      {
        path: CLIENT_NAV_PATH.CLIENT_VENDORS,
        icon: "users",
        client_mode: client_mode.project,
        name: "Subcontractors",
        // matchPartial: true
      },
      {
        path: CLIENT_NAV_PATH.CLIENT_CERTIFICATES,
        name: "Certificates",
      },
      {
        path: CLIENT_NAV_PATH.CLIENT_CATEGORIES,
        client_mode: client_mode.category,
        icon: "block layout",
        name: "Categories",
        matchPartial: true
      },
      {
        path: CLIENT_NAV_PATH.CLIENT_PROJECTS,
        client_mode: client_mode.project,
        icon: "list alternate outline",
        name: "Projects",
        matchPartial: true
      },
      {
        path: CLIENT_NAV_PATH.ORGANIZATION_SETTINGS,
        stateKey: "selectedClient",
        icon: "cog",
        name: "Settings",
        pathProp: "organizationID",
        replacePath: "clientId",
        isAdmin: true
      }
    ]
  }
]

export const NAVIGATED_FROM = {
  CERTIFICATES: "CERTIFICATES",
  PROJECT: "PROJECTS",
  VENDORS: "VENDORS",
}

export const ROUTE_FRAGMENTS = {
  SHARED: "SHARED",
}