import React, { useState, useEffect } from "react";
import OrganizationDetailsForm from "./OrganizationDetailsForm";
import {
  Button,
  IconButton,
  TextField,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  MenuItem,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Image } from "semantic-ui-react";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";

import Modal from "components/shared/Modal";
import LoaderButton from "components/shared/LoaderButton";

import { client_mode, client_mode_description } from "constants/client_properties";

export default function AddOrganizationModal({ open, onClose, onSave, loading = false, isClient = true }) {
  const [organizationName, setOrganizationName] = useState(null);
  const [clientDetails, setClientDetails] = useState({});
  const [clientDetailsDataError, setClientDetailsDataError] = useState({})
  const [themeColor, setThemeColor] = useState("#db2828"); // default for now
  const [logo, setLogo] = useState(null);
  const label = isClient ? "Client" : "Affiliate";
  const [clientMode, setClientMode] = useState(client_mode.project);

  useEffect(() => {
    if (!open) {
      setOrganizationName(null);
      setLogo(null);
    }
  }, [open]);

  function handleLogoUpdate(e) {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setLogo({
          content: event.target.result,
          extension: file.name.split(".").pop(),
          type: file.type,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  function handleSave() {
    let inputs = {
      name: organizationName,
      themeColor,
      logo,
    };
    if (isClient) {
      inputs.clientMode = clientMode
      inputs = {...inputs, ...clientDetails}
    } 
    onSave && onSave(inputs);
  }

  return (
    <Modal
      title={"Add New " + label}
      open={open}
      content={
        <div>
          <TextField
            fullWidth
            required
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            label={label + " Name"}
            variant="outlined"
            error={organizationName === ""}
            helperText={organizationName === "" ? `${label} name must not be empty.` : ""}
            sx={{ mt: 1 }}
          />
          {isClient && <OrganizationDetailsForm
            setOrganizationDetails={setClientDetails}
            organizationDetails={clientDetails}
            setOrganizationDetailsError={setClientDetailsDataError}
            organizationDetailsError={clientDetailsDataError}
          />}

          {/*<TextField*/}
          {/*  fullWidth*/}
          {/*  value={organizationName}*/}
          {/*  onChange={e => setOrganizationName(e.target.value)}*/}
          {/*  label={"Theme Color (Hex Code)"}*/}
          {/*  variant="standard"*/}
          {/*/>*/}
          {/*{*/}
          {/*  isClient &&*/}
          {/*  <FormControl sx={{mt: 2}} required>*/}
          {/*    <FormLabel>Select Client Mode</FormLabel>*/}
          {/*    <RadioGroup value={clientMode} onChange={(e) => setClientMode(e.target.value)}>*/}
          {/*      {*/}
          {/*        Object.keys(client_mode).map(mode => {*/}
          {/*          return (*/}
          {/*            <FormControlLabel*/}
          {/*              key={"client-mode-"+mode}*/}
          {/*              sx={{*/}
          {/*                alignItems: "flex-start",*/}
          {/*                '.MuiFormControlLabel-label': {*/}
          {/*                  padding: "7px"*/}
          {/*                }*/}
          {/*              }}*/}
          {/*              value={client_mode[mode]}*/}
          {/*              control={<Radio />}*/}
          {/*              label={*/}
          {/*                <Box>*/}
          {/*                  <Typography*/}
          {/*                    variant={"button"}*/}
          {/*                    sx={{textTransform: "capitalize", fontSize: "1rem"}}*/}
          {/*                  >{client_mode[mode]}</Typography>*/}
          {/*                  <Typography variant={"caption"} component="div">{client_mode_description[mode]}</Typography>*/}
          {/*                </Box>*/}
          {/*              }*/}
          {/*            />*/}
          {/*          )*/}
          {/*        })*/}
          {/*      }*/}
          {/*    </RadioGroup>*/}
          {/*  </FormControl>*/}
          {/*}*/}
          {logo && (
            <Box sx={{ mt: 2 }}>
              <IconButton sx={{ float: "right" }} onClick={() => setLogo(null)}>
                <CloseIcon />
              </IconButton>
              <Image src={logo.content} />
            </Box>
          )}
          {!logo && (
            <Box sx={{ mt: 2 }}>
              <label htmlFor="upload-logo-input">
                <input type="file" hidden id={"upload-logo-input"} onChange={handleLogoUpdate} />
                <Button startIcon={<UploadIcon />} component="span">
                  Upload Logo
                </Button>
              </label>
            </Box>
          )}
        </div>
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoaderButton loading={loading} disabled={!organizationName || loading || clientDetailsDataError?.invalidorganizationDetailsForm} variant="contained" onClick={handleSave}>
            Save
          </LoaderButton>
        </>
      }
    />
  );
}
