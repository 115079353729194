import React, { useState, useEffect } from "react";
import { Button, Box, Checkbox, FormControl, FormControlLabel, Divider } from "@mui/material";
import {SubtitleInline} from "components/shared/Typography";
import { Warning } from "@mui/icons-material";
import LoaderButton from "components/shared/LoaderButton";

export default function CertificateCoverageOverrideModal({ open, onClose, onOverride = () => {}, overrides = {}, content = "", title = "" }) {
  const [selectableOverrides, setSelectableOverrides] = useState({});
  useEffect(() => {
    setSelectableOverrides({ ...overrides });
  }, [overrides]);

  if (!open) {
    return null;
  }
  return (
    <Box mb={2} sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <SubtitleInline>{title.toUpperCase()}</SubtitleInline>
      <FormControl>
        {Object.keys(selectableOverrides).map((coverage) => {
          return (
            <FormControlLabel
              key={coverage}
              control={
                <Checkbox
                  name={coverage}
                  checked={selectableOverrides[coverage]}
                  onChange={(event) => {
                    setSelectableOverrides((old) => {
                      old[event.target.name] = event.target.checked;
                      return { ...old };
                    });
                  }}
                />
              }
              label={coverage}
            />
          );
        })}
      </FormControl>
      <Box mb={2} sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoaderButton
          startIcon={<Warning />}
          variant="outlined"
          onClick={() => {
            onOverride(selectableOverrides);
          }}
        >
          Override
        </LoaderButton>
      </Box>
      <Divider />
    </Box>
  );
}
