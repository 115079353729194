import React from "react";
import {Box, Button, IconButton, TextField, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "components/shared/Modal";

export default function({
  open,
  loading = false,
  onClose,
  onSave,
  categoryName = "",
  onUpdate
}) {
  return (
    <Modal
      open={open}
      maxWidth={"md"}
      title={
        <div>
          <Typography variant={"h5"} sx={{display: "inline"}}>Add A Category</Typography>
          <IconButton
            variant="outlined"
            onClick={onClose}
            sx={{float: "right"}}
          >
            <CloseIcon/>
          </IconButton>
        </div>
      }
      content={
        <Box sx={{mt: 2}}>
          <TextField
            fullWidth
            label="Category Name (required)"
            required
            value={categoryName}
            onChange={event => onUpdate(event.target.value)}
          />
        </Box>
      }
      actions={
        <>
          <Button
            variant="contained"
            onClick={()=>onSave(true)}
            disabled={loading || !categoryName}
          >
            Create
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        </>
      }
    />
  )
}
