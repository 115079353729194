import { API } from "aws-amplify"
import {
  activityByEntityID,
  createACTIVITY
} from "graphql/activity";
import {isLaterDateTime} from "utils/dateUtils";

export const logVendorActivity = async ({
  clientVendorID,
  userFullName,
  userID,
  action,
  field,
  value,
}) => {
  await logActivity({
    entity: "VENDOR",
    entityID: clientVendorID,
    userFullName,
    userID,
    action,
    field,
    value,
  })
}

export const getVendorActivities = async (clientVendorID) => {
  return getActivities("VENDOR", clientVendorID)
}

export const getCertificateActivities = async (certificateID) => {
  return getActivities("CERTIFICATE", certificateID)
}

export const logCertificateActivity = async ({
   certificateID,
   userFullName,
   userID,
   action,
   field,
   value,
}) => {
  await logActivity({
    entity: "CERTIFICATE",
    entityID: certificateID,
    userFullName,
    userID,
    action,
    field,
    value,
  })
}

export const getOrganizationActivities = async (organizationID) => {
  return getActivities("ORGANIZATION", organizationID)
}

export const logOrganizationActivity = async ({
  organizationID,
  userFullName,
  userID,
  action,
  field,
  value,
}) => {
  await logActivity({
    entity: "ORGANIZATION",
    entityID: organizationID,
    userFullName,
    userID,
    action,
    field,
    value,
  })
}

async function getActivities(entity, entityID) {
  const {data} = await API.graphql({
    query: activityByEntityID,
    variables: {
      entityID,
      filter: {
        entity: { eq: entity },
      },
     },
  })
  const activities = data?.activityByEntityID?.items || []
  return activities.sort((a, b) => isLaterDateTime(a.createdAt, b.createdAt) ? -1 : 1)
}

function logActivity(input) {
  return API.graphql({
    query: createACTIVITY,
    variables: { input }
  })
}
