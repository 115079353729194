import React, { useEffect, useMemo, useState } from "react";
import { Box, InputAdornment, Stack, TextField, Divider, Button } from "@mui/material";
import { SubtitleInline } from "components/shared/Typography";
import { Warning } from "@mui/icons-material";
import CertificateOverrideModal from "./CertificateCoverageOverrideModal";
import { CERTIFICATE_PROPERTIES } from "constants/insurance_types";

const isEqualFirstRowToInput = (textFieldInput, validationInput) => {
  return textFieldInput?.split("\n")[0]?.toUpperCase()?.trim() === validationInput?.toUpperCase()?.trim();
};

const isValidAddressDetail = (textFieldInput, validAddress) => {
  const [, ...addressDetails] = textFieldInput?.split("\n") || [];
  return addressDetails.join(',').toUpperCase()?.trim()?.includes(validAddress?.toString()?.toUpperCase()?.trim());
};

const certificateHolderErrorText = ({
  isValidCertificateHolder,
  validCertificateHolder="",
  isValidCertificateHolderAddress1,
  validCertificateHolderAddress1="",
  isValidCertificateHolderCity,
  validCertificateHolderCity="",
  isValidCertificateHolderState,
  validCertificateHolderState="",
  isValidCertificateHolderZip,
  validCertificateHolderZip="",
}) => {
  let errorText = "";
  if (!isValidCertificateHolder) {
    errorText = errorText.concat(`CERTIFICATE HOLDER should match CLIENT "${validCertificateHolder}" \n`);
  }
  if (!isValidCertificateHolderAddress1) {
    errorText = errorText.concat(`CERTIFICATE HOLDER address should match "${validCertificateHolderAddress1}" \n`);
  }
  if (!isValidCertificateHolderCity) {
    errorText = errorText.concat(`CERTIFICATE HOLDER city should match "${validCertificateHolderCity}" \n`);
  }
  if (!isValidCertificateHolderState) {
    errorText = errorText.concat(`CERTIFICATE HOLDER state should match "${validCertificateHolderState}" \n`);
  }
  if (!isValidCertificateHolderZip) {
    errorText = errorText.concat(`CERTIFICATE HOLDER Zip should match "${validCertificateHolderZip}" \n`);
  }
  return errorText;
};

export default function CertificateInfoSection ({ certificateData, handleCertificateInfoUpdate, selectedClient, selectedAgency, vendor, isEdit = true }) {
  const [numberOfInfoSectionOverrides, setNumberOfInfoSectionOverrides] = useState(0);
  const [displayOverrideSection, setDisplayOverrideSection] = useState(false);
  
  const validCertificateHolder = selectedAgency?.certificateHolder?.trim() || selectedClient?.name;
  const validCertificateHolderAddress1 = selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderAddress1?.trim() : (selectedClient?.address1 || "");
  const validCertificateHolderCity= selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderCity?.trim() : (selectedClient?.city || "");
  const validCertificateHolderState= selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderState?.trim() : (selectedClient?.state || "");
  const validCertificateHolderZip= selectedAgency?.certificateHolder?.trim()  ? selectedAgency?.certificateHolderZip : (selectedClient?.zip || "");

  const isValidInsured = isEqualFirstRowToInput(certificateData?.insured, vendor?.name);
  const isValidCertificateHolder = isEqualFirstRowToInput(certificateData?.certificateHolder, validCertificateHolder);
  const isValidCertificateHolderAddress1 = isValidAddressDetail(certificateData?.certificateHolder,validCertificateHolderAddress1);
  const isValidCertificateHolderCity = isValidAddressDetail(certificateData?.certificateHolder,validCertificateHolderCity);
  const isValidCertificateHolderState =  isValidAddressDetail(certificateData?.certificateHolder,validCertificateHolderState);
  const isValidCertificateHolderZip = isValidAddressDetail(certificateData?.certificateHolder,validCertificateHolderZip);

  function onOverride() {
    setDisplayOverrideSection(true);
  }

  function onCancelOverride() {
    setDisplayOverrideSection(false);
  }

  function onOverrideDetails(updatedOverrides = {}) {
    handleCertificateInfoUpdate({
      target: {
        name: "overrides",
        value: updatedOverrides,
      },
    });
    setDisplayOverrideSection(false);
  }

  
  let overrides = certificateData?.overrides || {};
  if (typeof overrides === "string") {
    // this is a fixup for MNCRT-745 
    // fixup for legacy certificates (dev and stg only)
    // do parsing until we have parsed object
    while (typeof overrides === "string") {
      overrides = JSON.parse(overrides);
    }
  }

  const cerDataOverrides = useMemo(() => {
    const data = {
    ...(!isValidInsured && { [CERTIFICATE_PROPERTIES.insured.label]: false }),
    ...(!isValidCertificateHolder && { [CERTIFICATE_PROPERTIES.certificateHolder.label]: false }),
    ...overrides,
    }
    if (isValidInsured) {
      delete data[CERTIFICATE_PROPERTIES.insured.label]
    }
    if (isValidCertificateHolder) {
      delete data[CERTIFICATE_PROPERTIES.certificateHolder.label]
    }
  
    return data;
  }, [overrides, isValidCertificateHolder, isValidInsured]);

  useEffect(() => {
    const numberOfOverrides = Object.keys(cerDataOverrides).length;
    setNumberOfInfoSectionOverrides(numberOfOverrides);
    if (numberOfOverrides === 0) {
      setDisplayOverrideSection(false);
    }
  }, [cerDataOverrides]);

  const hasInsuredError = !(isValidInsured || cerDataOverrides[CERTIFICATE_PROPERTIES.insured.label]);
  const hasCertificateHolderError = !(
    isValidCertificateHolder ||
    cerDataOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]
  );
  const hasCertificateHolderAddressError = !(
    isValidCertificateHolderAddress1 ||
    cerDataOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]
  );
  const hasCertificateHolderStateCityZipError = !(
    isValidCertificateHolderCity ||
    isValidCertificateHolderState ||
    isValidCertificateHolderZip ||
    cerDataOverrides[CERTIFICATE_PROPERTIES.certificateHolder.label]
  );

  return (
    <Stack>
      <Stack>
        <CertificateOverrideModal
          title={"Override Certificate details"}
          overrides={cerDataOverrides}
          open={displayOverrideSection}
          onClose={onCancelOverride}
          onOverride={onOverrideDetails}
        />
      </Stack>
      <Stack mb={4}>
        {isEdit && !displayOverrideSection && (
          <Button startIcon={<Warning />} sx={{ alignSelf: "end" }} onClick={onOverride} disabled={numberOfInfoSectionOverrides < 1}>
            OVERRIDE
          </Button>
        )}
        {/* <LimitsSection /> */}
      </Stack>
      <Stack direction="row" mt={2} mb={2} spacing={2}>
        <Stack sx={{ flexBasis: "50%", justifyContent: "space-between" }}>
          <Box>
            <SubtitleInline>INSURED</SubtitleInline>
            <TextField
              key={certificateData?.insured ? "insured" : "insuredEmpty"}
              name="insured"
              defaultValue={certificateData?.insured ? String(certificateData.insured).trim() : ""}
              variant="outlined"
              fullWidth
              onChange={handleCertificateInfoUpdate}
              multiline
              rows={6}
              error={hasInsuredError}
              disabled={!isEdit}
              helperText={hasInsuredError ? `INSURED should match SUBCONTRACTOR "${vendor?.name}" ` : null}
              className={hasInsuredError ? "display-error-line-1" : null}
            />
          </Box>
        </Stack>
        <Stack sx={{ flexBasis: "50%", justifyContent: "space-between" }}>
          <Box>
            <SubtitleInline>CERTIFICATE HOLDER</SubtitleInline>
            <TextField
              key={certificateData?.certificateHolder ? "certificateHolder" : "certificateHolderEmpty"}
              name="certificateHolder"
              defaultValue={certificateData?.certificateHolder || ""}
              variant="outlined"
              fullWidth
              onChange={handleCertificateInfoUpdate}
              multiline
              rows={6}
              error={hasCertificateHolderError || hasCertificateHolderAddressError || hasCertificateHolderStateCityZipError}
              disabled={!isEdit}
              helperText={
                (hasCertificateHolderError || hasCertificateHolderAddressError || hasCertificateHolderStateCityZipError) &&
                certificateHolderErrorText({
                  isValidCertificateHolder,
                  validCertificateHolder,
                  isValidCertificateHolderAddress1,
                  validCertificateHolderAddress1,
                  isValidCertificateHolderCity,
                  validCertificateHolderCity,
                  isValidCertificateHolderState,
                  validCertificateHolderState,
                  isValidCertificateHolderZip,
                  validCertificateHolderZip,
                })
              }
              className={`${hasCertificateHolderError ? "display-error-line-1" : null} ${hasCertificateHolderAddressError || hasCertificateHolderStateCityZipError ? "display-error-line-234" : null}`}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" mt={2} mb={4} spacing={2}>
        <Stack sx={{ flexBasis: "50%", justifyContent: "space-between" }}>
          <Box>
            <SubtitleInline>PRODUCER</SubtitleInline>
            <TextField
              key={certificateData?.producer ? "producer" : "producerEmpty"}
              name="producer"
              defaultValue={certificateData?.producer ? String(certificateData.producer).trim() : ""}
              variant="outlined"
              fullWidth
              onBlur={handleCertificateInfoUpdate}
              multiline
              rows={6}
              disabled={!isEdit}
            />
          </Box>
        </Stack>
        <Stack sx={{ flexBasis: "50%" }}>
          <SubtitleInline>PRODUCER CONTACT</SubtitleInline>
          <TextField
            key={certificateData?.producerContactName ? "producerContactName" : "producerContactNameEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">NAME</InputAdornment>,
            }}
            name="producerContactName"
            defaultValue={certificateData?.producerContactName || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
          <TextField
            key={certificateData?.producerPhone ? "producerPhone" : "producerPhoneEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">PHONE</InputAdornment>,
            }}
            name="producerPhone"
            defaultValue={certificateData?.producerPhone || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
          <TextField
            key={certificateData?.producerFax ? "producerFax" : "producerFaxEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">FAX</InputAdornment>,
            }}
            name="producerFax"
            defaultValue={certificateData?.producerFax || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
          <TextField
            key={certificateData?.producerEmail ? "producerEmail" : "producerEmailEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">EMAIL</InputAdornment>,
            }}
            name="producerEmail"
            defaultValue={certificateData?.producerEmail || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack mt={4} mb={2}>
        <Stack direction="row">
          <SubtitleInline sx={{ flexBasis: "70%" }}>INSURER(S) AFFORDING COVERAGE</SubtitleInline>
          <SubtitleInline sx={{ flexBasis: "30%" }}>NAIC #</SubtitleInline>
        </Stack>

        {["A", "B", "C", "D", "E", "F"].map((insurerLetter) => (
          <Stack direction="row" key={"insurerLetter-" + insurerLetter}>
            <TextField
              key={certificateData?.["insurer" + insurerLetter] ? "insurerLetter" + insurerLetter : "insurerLetterEmpty" + insurerLetter}
              name={"insurer" + insurerLetter}
              defaultValue={certificateData?.["insurer" + insurerLetter] || ""}
              variant="standard"
              fullWidth
              onBlur={handleCertificateInfoUpdate}
              InputProps={{
                startAdornment: <InputAdornment position="start">{`INSURER ${insurerLetter}:`}</InputAdornment>,
              }}
              sx={{ flexBasis: "70%" }}
              disabled={!isEdit}
            />
            <TextField
              key={certificateData?.["insurer" + insurerLetter + "NAIC"] ? "insurer" + insurerLetter + "NAIC" : "insurerEmpty" + insurerLetter + "NAIC"}
              name={"insurer" + insurerLetter + "NAIC"}
              defaultValue={certificateData?.["insurer" + insurerLetter + "NAIC"] || ""}
              variant="standard"
              fullWidth
              onBlur={handleCertificateInfoUpdate}
              sx={{ flexBasis: "30%", ml: 2 }}
              disabled={!isEdit}
            />
          </Stack>
        ))}
      </Stack>
      <Stack mt={2}>
        <SubtitleInline>DESCRIPTION OF OPERATION</SubtitleInline>
        <TextField
          key={certificateData?.descriptionOfOperation ? "descriptionOfOperation" : "descriptionOfOperationEmpty"}
          sx={{ mt: 1 }}
          fullWidth
          multiline
          rows={8}
          placeholder={"Add description of operation ..."}
          name="descriptionOfOperation"
          defaultValue={certificateData?.descriptionOfOperation}
          onBlur={handleCertificateInfoUpdate}
          disabled={!isEdit}
        />
      </Stack>
    </Stack>
  );
}
