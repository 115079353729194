import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import { getLogo } from "services/appResources";
import OrganizationSettings from "components/OrganizationSettings";
import { Loader } from 'semantic-ui-react'

export default() => {
  const {
    selectedClient: fetchedOrganization,
  } = useSelector(organizationState)

  const [organization, setOrganization] = useState()
  const [logo, setLogo] = useState("")
  useEffect(() => {
    setOrganization(fetchedOrganization)
    
    if(fetchedOrganization?.logo) {
      getLogo(
        fetchedOrganization.logo,
        (response) => setLogo(response)
      )
    }
  }, [fetchedOrganization])

  return (
    organization ? 
      <OrganizationSettings organization={organization} setOrganization={setOrganization} logo={logo} isClient={true} />
      :
      <Loader active  indeterminate size='huge' />

  )
}
