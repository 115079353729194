import React, { useState } from "react";
import { Typography, FormControlLabel, FormControl, Checkbox, Select, MenuItem, InputLabel, ListItemText } from "@mui/material";
import DisplayProjectCoverages from "./DisplayProjectCoverages";

export default function AutomaticCertificateRequestForm({
  vendorData = {},
  setSendAutomaticEmailRecipients = () => {},
  projects = [],
  clientName,
  useDefaultProject,
  defaultProject,
  vendorLabel,
  projectLabel,
}) {
  const [state, setState] = useState({
    subcontractor: false,
    insuranceAgent: false,
    sendAutomaticEmailTo: {
      ...vendorData?.sendAutomaticEmailTo,
      ...(useDefaultProject && {emailSelectedProject: defaultProject}),
    }
  });
  const { subcontractor, insuranceAgent } = state;
  const displaySelectedProject = useDefaultProject ? defaultProject.id : vendorData?.sendAutomaticEmailTo?.emailSelectedProject?.id;

  const onProjectChange = (event) => {
    const selectedProject = projects?.find((project) => project?.id === event.target.value);
    const hasRequiredCoverages = selectedProject?.requiredCoverages
    setSendAutomaticEmailRecipients({
      ...vendorData,
      sendAutomaticEmailTo: {
        ...vendorData?.sendAutomaticEmailTo,
        ...(!hasRequiredCoverages && { subcontractor: false }),
        ...(!hasRequiredCoverages && { insuranceAgent: false }),
        emailSelectedProject:selectedProject
      },
    });
    setState({
      ...state,
      ...(!hasRequiredCoverages && { subcontractor: false }),
      ...(!hasRequiredCoverages && { insuranceAgent: false }),
      ...vendorData,
      sendAutomaticEmailTo: {
        ...(hasRequiredCoverages && { ...vendorData?.sendAutomaticEmailTo }) ,
        emailSelectedProject: hasRequiredCoverages ? selectedProject : null,
      },
    });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    setSendAutomaticEmailRecipients({
      ...vendorData,
      sendAutomaticEmailTo: {
        ...vendorData?.sendAutomaticEmailTo,
        ...(useDefaultProject && { emailSelectedProject: defaultProject }),
        [event.target.name]: event.target.checked,
      },
    });
  };

  return (
    <FormControl sx={{ flexDirection: "column", alignItems: "flex-start" }}>
      <InputLabel id="project-select-label">Please specify the {projectLabel} and its coverage requirements to request *</InputLabel>
      <Select
        labelId="project-select-label"
        id="projects-select"
        label="Projects"
        value={displaySelectedProject || ""}
        onChange={onProjectChange}
        fullWidth
      >
        {useDefaultProject ? (
          <MenuItem value={defaultProject.id}>
            <ListItemText primary={defaultProject.name} />
          </MenuItem>
        ) : (
          projects
            .filter((project) => vendorData?.selectedProjects?.includes(project?.id))
            .map((project, index) => (
              <MenuItem key={project.name + index} value={project.id}>
                <ListItemText primary={project.name} />
              </MenuItem>
            ))
        )}
      </Select>
      <Typography variant="h6" sx={{ my: 3 }} color="text.secondary">
        Once successfully adding the {vendorLabel}, automatically request submission of new Certificate of Insurance from:
      </Typography>
      <FormControlLabel
        disabled= {!state?.sendAutomaticEmailTo?.emailSelectedProject || !vendorData?.email}
        control={<Checkbox checked={subcontractor} color="default" onChange={handleChange} name="subcontractor" />}
        label={
          <Typography variant="subtitle1" color="text.secondary">
            {vendorLabel}
          </Typography>
        }
      />
      <FormControlLabel
        disabled= {!state?.sendAutomaticEmailTo?.emailSelectedProject || !vendorData?.insuranceAgent?.email}
        control={<Checkbox checked={insuranceAgent} color="default" onChange={handleChange} name="insuranceAgent" />}
        label={
          <Typography variant="subtitle1" color="text.secondary">
            Insurance Agent
          </Typography>
        }
      />
      <DisplayProjectCoverages sx={{ my: 3 }} project={state?.sendAutomaticEmailTo?.emailSelectedProject} projectLabel={projectLabel} certificateHolder={clientName} />
    </FormControl>
  );
}
