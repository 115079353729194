import React from "react";
import {
  FormHelperText,
  Typography,
  Box,
  FormControlLabel,
  FormControl,
  Checkbox,
  Stack,
  FormGroup,
  TextField,
  InputAdornment,
  RadioGroup,
  Radio,
  Button
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever"
import {formatCurrencyInt,removeFormat} from "utils/formUtils";
import {SubtitleInline} from "components/shared/Typography";
import CoverageLineItem from "./CoverageLineItem";
import CoverageLineItemEdit from "./CoverageLineItemEdit";
import { client_mode } from "constants/client_properties";

export default (props) => {

  const {
    index,
    isCustom,
    isEdit = false,
    onUpdate = () => {},
    onDeleteTab = () => {},
    coverageTypeConfig = {},
    coverageRequirement = null,
    clientCoverage = {},
    classCoverage = {},
    clientMode = client_mode.category,
    showHelperText = false,
    vendorCoverage = [],
  } = props

  let limits = coverageRequirement?.limits || {}
  if(typeof limits === 'string') {
    limits = JSON.parse(limits)
  }

  let clientLimits = clientCoverage?.limits || {}
  if(typeof clientLimits === 'string') {
    clientLimits = JSON.parse(clientLimits)
  }

  const classLimits = classCoverage?.limits || {}

  function onFieldUpdate (event) {
    let {name, value} = event.target
    if(event.target.type === 'checkbox') {
      value = event.target.checked
    }
    onUpdate({
      id: coverageRequirement.id,
      name,
      value: removeFormat({name, value}),
      index
    })
  }

  function onTabDelete() {
    onDeleteTab(index);
  }

  function onPerStatuteRadioUpdate(event) {
    onUpdate({
      id: coverageRequirement.id,
      name: "limitPerStatute",
      value: event.target.value === "PER STATUTE" ? event.target.value : "",
      index,
    })
  }

  function onAggregateAppliesUpdate(event) {
    const {aggregateAppliesPer = []} = coverageRequirement
    const aggregateAppliesPerSet = new Set(aggregateAppliesPer)
    const otherValue = aggregateAppliesPer?.find(
      oneApplies => !coverageTypeConfig.aggregateAppliesPer?.includes(oneApplies)
    )
    if(event.target.name === "OTHER") {
      if(event.target.checked) {
        aggregateAppliesPerSet.add("")
      } else {
        aggregateAppliesPerSet.delete(otherValue)
      }
    } else if(event.target.name === "OTHER_VALUE") {
      aggregateAppliesPerSet.delete(otherValue)
      aggregateAppliesPerSet.add(event.target.value)
    } else {
      if(event.target.checked) {
        aggregateAppliesPerSet.add(event.target.name)
      } else {
        aggregateAppliesPerSet.delete(event.target.name)
      }
    }
    onUpdate({
      id: coverageRequirement.id,
      name: "aggregateAppliesPer",
      value: Array.from(aggregateAppliesPerSet),
      index
    })
  }

  function onCoveredPropertyUpdate(event) {
    const {coveredProperty = []} = coverageRequirement
    const coveredPropertySet = new Set(coveredProperty)
    const otherValue = coveredProperty?.find(
      oneProperty => !coverageTypeConfig.coveredProperty?.includes(oneProperty)
    )
    if(event.target.name === "OTHER") {
      if(event.target.checked) {
        coveredPropertySet.add("")
      } else {
        coveredPropertySet.delete(otherValue)
      }
    } else if(event.target.name === "OTHER_VALUE") {
      coveredPropertySet.delete(otherValue)
      coveredPropertySet.add(event.target.value)
    } else {
      if(event.target.checked) {
        coveredPropertySet.add(event.target.name)
      } else {
        coveredPropertySet.delete(event.target.name)
      }
    }
    onUpdate({
      id: coverageRequirement.id,
      name: "coveredProperty",
      value: Array.from(coveredPropertySet),
      index
    })
  }

  function onLimitsUpdate(limit) {
    onUpdate({
      id: coverageRequirement.id,
      name: "limits",
      value: {
        ...limits,
        ...limit
      },
      index
    })
  }

  function onInsuranceFormUpdate(event) {
    const insuranceForm = event.target.name;
    const checked = event.target.checked;
    const insuranceFormsUnique = new Set(coverageRequirement?.insuranceForm)
    insuranceFormsUnique[checked ? "add" : "delete"](insuranceForm)
    onUpdate({
      id: coverageRequirement.id,
      index,
      name: "insuranceForm",
      value: Array.from(insuranceFormsUnique),
    })
  }

  const InsuranceFormSection = () => (
    <Box sx={{flexBasis: "50%"}}>
      {!!coverageTypeConfig.insuranceForm && (
        <FormControl>
          <Box sx={{flexDirection: "row"}}>
            <FormControlLabel
              checked={coverageRequirement?.insuranceForm?.includes("CLAIMS_MADE")}
              name={"CLAIMS_MADE"}
              control={<Checkbox/>}
              onChange={onInsuranceFormUpdate}
              label="CLAIMS-MADE"
              disabled={!isEdit}
            />
            <FormControlLabel
              checked={coverageRequirement?.insuranceForm?.includes("OCCUR")}
              name={"OCCUR"}
              control={<Checkbox/>}
              onChange={onInsuranceFormUpdate}
              label="OCCUR"
              disabled={!isEdit}
            />
          </Box>
          {
            clientCoverage?.insuranceForm && isEdit &&
            <FormHelperText>
              Default:
              {
                clientCoverage?.insuranceForm?.includes('CLAIMS_MADE') && " CLAIMS-MADE"
              }
              {
                clientCoverage?.insuranceForm?.length > 1 && ", "
              }
              {
                clientCoverage?.insuranceForm?.includes('OCCUR') && " OCCUR"
              }
            </FormHelperText>
          }
        </FormControl>
      )}
    </Box>
  )
  const AdditionAndSubrogationSection = () => (
    <>
      {
        coverageTypeConfig.additionalInsured &&
        <FormControl sx={{flex: "0 1 25%"}}>
          <FormControlLabel
            control={<Checkbox onClick={onFieldUpdate} name={"additionalInsured"} />}
            checked={coverageRequirement?.additionalInsured}
            disabled={!isEdit}
            label="ADDL INSD"
          />
          {
            clientCoverage?.additionalInsured &&
            <FormHelperText>Default: required</FormHelperText>
          }
        </FormControl>
      }
      {
        coverageTypeConfig.subrogationWaiver &&
        <FormControl sx={{flex: "0 1 25%"}}>
          <FormControlLabel
            control={<Checkbox onClick={onFieldUpdate} name={"subrogationWaiver"}/>}
            label="SUBR WVD"
            checked={coverageRequirement?.subrogationWaiver}
            disabled={!isEdit}
          />
          {
            clientCoverage?.subrogationWaiver &&
            <FormHelperText>Default: required</FormHelperText>
          }
        </FormControl>
      }
    </>
  )
  const LimitsSection = () => (
    coverageTypeConfig.limits?.length &&
    <Box sx={{flexBasis: "50%"}}>
      <SubtitleInline>LIMITS</SubtitleInline>
      {
        !!coverageTypeConfig.limitPerStatute &&
        <Box sx={{mb:2}}>
          {
            !isEdit && coverageRequirement?.limitPerStatute
          }
          {
            isEdit &&
            <FormControl>
              <RadioGroup
                row
                name="limitPerStatute"
                onChange={onPerStatuteRadioUpdate}
              >
                <FormControlLabel
                  value="PER STATUTE"
                  control={<Radio />}
                  label="PER STATUTE"
                  checked={coverageRequirement?.limitPerStatute === "PER STATUTE"}
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="OTHER"
                  checked={
                    coverageRequirement?.limitPerStatute === "" ||
                    (coverageRequirement?.limitPerStatute && coverageRequirement?.limitPerStatute !== "PER STATUTE")
                  }
                />
                <TextField
                  variant={"standard"}
                  name="limitPerStatute"
                  defaultValue={
                    coverageRequirement?.limitPerStatute === "" ||
                    (coverageRequirement?.limitPerStatute && coverageRequirement?.limitPerStatute !== "PER STATUTE")
                      ? coverageRequirement.limitPerStatute
                      : ""
                  }
                  disabled={coverageRequirement?.limitPerStatute === "PER STATUTE"}
                  onBlur={onFieldUpdate}
                />
              </RadioGroup>
              {
                clientCoverage?.limitPerStatute &&
                <FormHelperText>
                  {
                    `Default: ${clientCoverage?.limitPerStatute}`
                  }
                </FormHelperText>
              }

            </FormControl>
          }
        </Box>
      }
      {
        coverageTypeConfig.limits?.map(limit => {
          const CoverageLineItemComponent = isEdit ? CoverageLineItemEdit : CoverageLineItem
          let hasRequirement = false;
          return (
            <Box key={"lineItem"+limit}>
              <CoverageLineItemComponent
                name={limit}
                amount={limits[limit] || 0}
                onUpdate={onLimitsUpdate}
                clientAmount={clientLimits[limit]}
                classAmount={classLimits[limit]}
                clientMode={clientMode}
                showHelperText={showHelperText}
                vendorCoverage={vendorCoverage}
              />
            </Box>
          )
        })
      }
    </Box>
  )

  return (
    <Box sx={{minHeight: "307px"}}>
      <Stack direction="row" justifyContent={"space-between"} mr={2} flexDirection={"column"} alignItems={"flex-start"}>
        {
          isCustom && isEdit &&
          <Button
            onClick={onTabDelete}
            color="error"
            sx={{ alignSelf: "flex-end", mb: 2 }}
            startIcon={<DeleteForever />}
          >
            DELETE INSURANCE TYPE
          </Button>
        }
        <Typography variant="h6">
          {
            !isCustom && coverageTypeConfig.displayName
          }
          {
            isCustom && (
              !isEdit
                ? coverageRequirement.name
                : <TextField
                  fullWidth={true}
                  label={"TYPE OF INSURANCE"}
                  variant={"standard"}
                  name={"name"}
                  value={coverageRequirement.name}
                  onChange={onFieldUpdate}
                />
            )
          }
        </Typography>
      </Stack>
      <Stack spacing={2} ml={2} mr={2} mt={2} direction={"row"} alignItems="center">
        <InsuranceFormSection />
        <AdditionAndSubrogationSection />
      </Stack>

      <Stack ml={2} mr={2} mt={3} direction={"row"}>
        <Box sx={{flexBasis: "50%"}}>
          {
            (coverageTypeConfig.aggregateAppliesPer || coverageTypeConfig.coveredProperty) &&
            <Stack direction="row" spacing={2} sx={{mb:2}}>
              {
                coverageTypeConfig.aggregateAppliesPer &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  <SubtitleInline>GEN'L AGGREGATE LIMIT APPLIES PER</SubtitleInline>
                  {
                    isEdit
                    ? (
                        <FormGroup>
                          {
                            coverageTypeConfig.aggregateAppliesPer.map((aggregateType, index) => {
                              const otherValue = coverageRequirement.aggregateAppliesPer?.find(
                                oneType => !coverageTypeConfig.aggregateAppliesPer.includes(oneType)
                              )
                              const checked = aggregateType !== "OTHER"
                                ? coverageRequirement.aggregateAppliesPer?.includes(aggregateType)
                                : (otherValue === "" || !!otherValue)
                              const vendorRequired = vendorCoverage
                                ?.filter(oneVendorCoverage => oneVendorCoverage.aggregateAppliesPer?.includes(aggregateType))
                                ?.map(oneVendorCoverage => oneVendorCoverage.classificationName || "Vendor Default")
                              return (
                                <Box sx={{display: "flex"}} key={"aggregateAppliesPer-"+aggregateType}>
                                  <FormControl
                                    sx={{flexDirection: "row", alignItems: "center"}}
                                    key={aggregateType+"Checkbox"+index}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={aggregateType}
                                          checked={!!checked}
                                          onChange={onAggregateAppliesUpdate}
                                        />
                                      }
                                      label={aggregateType}
                                    />
                                    {

                                      clientCoverage?.aggregateAppliesPer &&
                                      clientCoverage.aggregateAppliesPer?.includes(aggregateType) &&
                                      <FormHelperText>Default: required</FormHelperText>
                                    }
                                  </FormControl>
                                  {
                                    aggregateType === "OTHER" && checked &&
                                    <TextField
                                      name="OTHER_VALUE"
                                      onChange={onAggregateAppliesUpdate}
                                      value={otherValue || ""}
                                      variant={"standard"}
                                      helperText={
                                        !clientCoverage?.aggregateAppliesPer ||
                                        `Default: ${
                                          clientCoverage.aggregateAppliesPer.find(aggregateType => !coverageTypeConfig.aggregateAppliesPer?.includes(aggregateType))
                                          || "None"
                                        }`
                                      }
                                    />
                                  }
                                </Box>
                              )
                            })
                          }
                        </FormGroup>
                      )
                    : (
                        <Typography variant="body1">
                          {
                            coverageRequirement?.aggregateAppliesPer
                              ? coverageRequirement.aggregateAppliesPer.join(", ")
                              : "-"
                          }
                        </Typography>
                      )
                  }

                </Box>
              }
              {
                coverageTypeConfig.coveredProperty &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  <SubtitleInline>PROPERTIES</SubtitleInline>
                  {
                    isEdit
                    ? (
                        <FormGroup>
                          {
                            coverageTypeConfig.coveredProperty.map(propertyType => {
                              const otherValue = coverageRequirement.coveredProperty?.find(
                                oneType => !coverageTypeConfig.coveredProperty.includes(oneType)
                              )
                              const checked = propertyType !== "OTHER"
                                ? coverageRequirement.coveredProperty?.includes(propertyType)
                                : (otherValue === "" || !!otherValue)
                              const vendorRequired = vendorCoverage
                                ?.filter(oneVendorCoverage => oneVendorCoverage.coveredProperty?.includes(propertyType))
                                ?.map(oneVendorCoverage => oneVendorCoverage.classificationName || "Vendor Default")

                              return (
                                <Box sx={{display: "flex"}} key={"propertyType-"+propertyType}>
                                  <FormControl sx={{flexDirection: "row", alignItems: "center"}}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checked}
                                          onChange={onCoveredPropertyUpdate}
                                          name={propertyType}
                                        />
                                      }
                                      label={propertyType}
                                    />
                                    {

                                      clientCoverage?.coveredProperty &&
                                      clientCoverage.coveredProperty?.includes(propertyType) &&
                                      <FormHelperText>Default: required</FormHelperText>
                                    }
                                  </FormControl>
                                  {
                                    propertyType === "OTHER" && checked &&
                                    <TextField
                                      name="OTHER_VALUE"
                                      onChange={onCoveredPropertyUpdate}
                                      value={otherValue || ""}
                                      variant={"standard"}
                                      helperText={
                                        !clientCoverage?.coveredProperty ||
                                        `Default: ${
                                          clientCoverage.coveredProperty.find(propertyType => !coverageTypeConfig.coveredProperty.includes(propertyType))
                                          || "None"
                                        }`
                                      }
                                    />
                                  }
                                </Box>
                              )
                            })
                          }
                        </FormGroup>
                      )
                    : (
                        <Typography variant="body1">
                          {
                            coverageRequirement?.coveredProperty
                              ? coverageRequirement?.coveredProperty.join(", ")
                              : "-"
                          }
                        </Typography>
                      )
                  }
                </Box>
              }
            </Stack>
          }
          {
            (coverageTypeConfig.deductible || coverageTypeConfig.retention || coverageTypeConfig.exclusion) &&
            <Stack direction="row" spacing={2}>
              {
                (coverageTypeConfig.deductible || coverageTypeConfig.retention) &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  <Stack direction="row" spacing={2}>
                    {
                      coverageTypeConfig.deductible &&
                      <Box>
                        <SubtitleInline>DED</SubtitleInline>
                        {
                          isEdit
                          ? (
                              <TextField
                                InputProps={{
                                  startAdornment: (<InputAdornment position={"start"}>$</InputAdornment>)
                                }}
                                value={formatCurrencyInt(coverageRequirement?.deductible)}
                                name={"deductible"}
                                onChange={onFieldUpdate}
                                helperText={
                                  !!clientCoverage.deductible
                                  && `Default: $${formatCurrencyInt(clientCoverage.deductible)}`
                                }
                              />
                            )
                          : (
                              <Typography variant="body1"> $
                                {
                                  formatCurrencyInt(coverageRequirement?.deductible || 0)
                                }
                              </Typography>
                            )
                        }
                      </Box>
                    }
                    {
                      coverageTypeConfig.retention &&
                      <Box>
                        <SubtitleInline>RETENTION</SubtitleInline>
                        {
                          isEdit
                          ? (
                              <TextField
                                InputProps={{
                                  startAdornment: (<InputAdornment position={"start"}>$</InputAdornment>)
                                }}
                                value={formatCurrencyInt(coverageRequirement?.retention)}
                                name={"retention"}
                                onChange={onFieldUpdate}
                                helperText={
                                  !!clientCoverage.retention
                                  && `Default: $${formatCurrencyInt(clientCoverage.retention)}`
                                }
                              />
                            )
                          : (
                              <Typography variant="body1"> $
                                {
                                  formatCurrencyInt(coverageRequirement?.retention || 0)
                                }
                              </Typography>
                            )
                        }
                      </Box>
                    }
                  </Stack>
                </Box>
              }
              {
                coverageTypeConfig.exclusion &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  <SubtitleInline>PROPRIETOR/PARTNER/EXECUTIVE OFFICER/MEMBER EXCLUSION</SubtitleInline>
                  {
                    isEdit
                    ? (
                        <FormControl>
                          <RadioGroup
                            row
                            name="exclusion"
                            onChange={onFieldUpdate}
                            value={coverageRequirement.exclusion}
                          >
                            <FormControlLabel value="true" control={<Radio />} label="Y" />
                            <FormControlLabel value="false" control={<Radio />} label="N" />
                          </RadioGroup>
                          {
                            clientCoverage?.exclusion !== undefined &&
                            <FormHelperText>
                              Default: { clientCoverage.exclusion ? " Y" : " N"}
                            </FormHelperText>
                          }

                        </FormControl>
                      )
                    : (
                        <Typography variant="body1">
                          { coverageRequirement?.exclusion ? "Y" : "N" }
                        </Typography>
                      )
                  }
                </Box>
              }
            </Stack>
          }
        </Box>
        <LimitsSection />
      </Stack>
    </Box>
  )
}
