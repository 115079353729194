import React, {useState, useEffect} from "react";
import {Button} from "@mui/material"
import Modal from "components/shared/Modal";
import VendorsTable from "components/Vendors/VendorsTable";
import LoaderButton from "components/shared/LoaderButton";

export default function AddSubcontractorsModal({open, onClose, projectVendors, vendors, onSave, loading, vendorLabel, projectLabel}) {
  const [sortBy, setSortBy] = useState("Created Date")
  const [sortAsc, setSortAsc] = useState(false)
  const theadProps = {sortBy, setSortBy, sortAsc, setSortAsc}
  const [selectedSubs, setSelectedSubs] = useState({})
  useEffect(() => {
    if(open) {
      const vendorsMap = {}
      projectVendors?.forEach(projectVendor => {
        if(projectVendor.clientVendor?.id) {
          vendorsMap[projectVendor.clientVendor.id] = true
        }
      })
      setSelectedSubs(vendorsMap)
    } else {
      setSelectedSubs({})
    }
  }, [open])

  function onSelectionChange(clientVendorID, checked) {
    const currentSelected = {...selectedSubs}
    if(currentSelected?.[clientVendorID]) {
      delete currentSelected[clientVendorID]
    } else {
      currentSelected[clientVendorID] = true
    }
    setSelectedSubs(currentSelected)
  }

  return (
    <Modal
      title={`Set ${vendorLabel}s For ${projectLabel}`}
      open={open}
      onClose={onClose}
      maxWidth={"lg"}
      content={
        <VendorsTable
          columns={["selected", "name", "city", "state", "createdAt", "email"]}
          theadProps={theadProps}
          vendors={vendors}
          selected={selectedSubs}
          onSelectionChange={onSelectionChange}
        />
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <LoaderButton
            variant="contained"
            onClick={() => onSave && onSave(selectedSubs)}
            loading={loading}
          >
            Save
          </LoaderButton>
        </>
      }
    />
  )
}
