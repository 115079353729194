import React, {useState, useEffect, useImperativeHandle} from "react";
import {Box} from "@mui/material"
import {SideMenu} from "components/shared/SideMenuContainer";
import EmailTemplate from "./EmailTemplate";
import { getDefaultTemplates } from "constants/client_properties";
import certificate_actions from "constants/certificate_actions";
import { getProjectLabel } from "constants/organization_types"
import { organizationState } from "redux/organizationSlice";
import { useSelector } from "react-redux";

const menuItems = [
  {key: certificate_actions.REMINDER_1, label: "1 Day Reminder"},
  {key: certificate_actions.REMINDER_7, label: "7 Day Reminder"},
  {key: certificate_actions.REMINDER_14, label: "14 Day Reminder"},
  {key: certificate_actions.REQUEST, label: "Certificate Upload Request"},
  {key: certificate_actions.CERTIFICATE_EXPIRATION, label: "Certificate Expiration"},
]

export default React.forwardRef(({templates, clientName, isEdit, onTemplatesUpdate, vendorLabel}, ref) => {
  const [activeItem, setActiveItem] = useState("Reminder1")
  const { selectedClient, userOrgType } = useSelector(organizationState);
  const [templatesJSON, setTemplatesJSON] = useState({})
  const projectLabel = getProjectLabel(selectedClient);

  useImperativeHandle(ref, () => ({
    getTemplateData: () => templatesJSON
  }), [templatesJSON]);

  useEffect(() => {
    resetTemplateData()
  }, [templates])

  useEffect(()=>{
    if(!isEdit) {
      resetTemplateData()
    }
  }, [isEdit])

  function resetTemplateData() {
    const defaultTemplates = getDefaultTemplates({ clientName, projectLabel: projectLabel, vendorName: `[${vendorLabel}]` })
    if(!templates) {
      setTemplatesJSON(defaultTemplates)
    } else {
      let clientTemplates = templates
      if(typeof templates === "string") {
        clientTemplates = JSON.parse(templates)
        setTemplatesJSON(JSON.parse(templates))
      }
      setTemplatesJSON({
        ...defaultTemplates,
        ...clientTemplates,
      })
    }
  }

  function onFieldUpdate(key, event) {
    const fieldName = event.target.name
    const value = event.target.value
    const newTemplate = {
      ...templatesJSON[key],
      [fieldName]: value,
    }
    setTemplatesJSON({
      ...templatesJSON,
      [key]: newTemplate,
    })
  }

  return (
    <Box sx={{mb: 2}}>
      <SideMenu
        items={menuItems}
        vertical={false}
        style={{marginTop: 0}}
        activeItem={activeItem}
        handleItemClick={(key) => setActiveItem(key)}
      />
      <EmailTemplate
        template={templatesJSON[activeItem]}
        isEdit={isEdit}
        onFieldUpdate={event => onFieldUpdate(activeItem, event)}
      />
    </Box>
  )
})
