import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./userSlice";
import organizationReducer from "./organizationSlice"
import clientReducer from "./clientSlice";
import vendorReducer from "./vendorSlice"

const store = configureStore({
  reducer: {
    user: userReducer,
    organization: organizationReducer,
    client: clientReducer,
    vendor: vendorReducer,
  }
})

export default store
