export function getEmailFieldError(eventTarget) {
  const validity = eventTarget?.validity
  if(validity?.valid) {
    return false
  } else if(validity?.valueMissing) {
    return "Please enter an email address for this user."
  } else if(validity?.typeMismatch || validity?.patternMismatch) {
    return "Please enter a valid email address."
  }
}
