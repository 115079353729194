import React, {useState, useEffect, useMemo} from 'react';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import InputAdornment from '@mui/material/InputAdornment';

import {Menu} from 'semantic-ui-react'

import {useNavigate, useParams} from "react-router-dom"

import { useSelector, useDispatch } from "react-redux";
import { clientActions } from "redux/clientSlice";
import { organizationState } from "redux/organizationSlice";
import { loggedInUserState } from "redux/userSlice";

import StatusIndicator from "components/shared/StatusIndicator";
import SortableTHeadCell from "components/shared/SortableTHeadCell";
import { useNavPath, getNavigationPath } from "constants/routes";
import {SYSTEM,AGENCY, getVendorLabel, getProjectLabel, getTableRowsPerPage} from "constants/organization_types"
import AddProjectModal from "components/ProjectDetails/ProjectInfoUpdateModal"
import Snackbar from "components/shared/Snackbar";
import TableLoadingIndicator from "components/shared/TableLoadingIndicator";

import {awsDateToUS, isLaterDate} from "utils/dateUtils";
import { findInStringsIgnoreCase } from "utils/stringUtils"

import AmplitudeService, { AMPLITUDE_EVENT } from 'services/amplitude';

const STATUS_LABEL = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  INACTIVE: "Inactive",
}

function Row(props) {
  const { row = {}, onClick, rowIndex } = props;
  const { id, name, vendors, requirement, createdAt, startDate, endDate, status } = row;
  const disabledStyle = ["INACTIVE", "ARCHIVED"].includes(status) ? {"&.MuiTableCell-root": {color: "darkgrey"}} : {};
  const statusLabel = STATUS_LABEL[status]
  let coverageTypes = []
  if(typeof row?.requiredCoverages === "string") {
    coverageTypes = JSON.parse(row.requiredCoverages)?.map(requiredCoverage => requiredCoverage.insuranceType)
  }

  return (
    <React.Fragment>
      <TableRow onClick={()=>onClick(id)} sx={{
        '&:hover': {
          cursor: "pointer",
          backgroundColor: "rgba(0, 0, 0, 0.04)"
        },
        ".MuiTableRow-root .MuiTableCell-body": {
          color: "red"
        }
      }}>
        <TableCell align="left" sx={disabledStyle}>{name}</TableCell>
        <TableCell align="center">
          {
            statusLabel &&
            <StatusIndicator label={statusLabel} size="small"/>
          }

        </TableCell>
        <TableCell align="left" sx={disabledStyle}>{vendors?.items?.length || "0"}</TableCell>
        <TableCell align="left" sx={disabledStyle}>{coverageTypes.join(", ")}</TableCell>
        <TableCell align="left" sx={disabledStyle}>{awsDateToUS(startDate)}</TableCell>
        <TableCell align="left" sx={disabledStyle}>{awsDateToUS(endDate)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ProjectsTable() {
  const { userOrgType, selectedClient } = useSelector(organizationState)
  const projects = useSelector(state => state.client.projects[selectedClient?.clientID]) || []
  const NAV_PATH = useNavPath(userOrgType)
  const { clientId } = useParams()
  const [statusFilter, setStatusFilter] = useState("ALL")
  const [sortBy, setSortBy] = useState("createdAt")
  const [sortAsc, setSortAsc] = useState(false)
  const theadProps = {sortBy, setSortBy, sortAsc, setSortAsc}
  const [searchPhrase, setSearchPhrase] = useState("")
  const [newProjectData, setNewProjectData] = useState(false)
  const [saving, setSaving] = useState(false)
  const [message, setMessage] = useState();
  const [page, setPage] = useState(0)
  const [initialLoading, setInitialLoading] = useState(false)
  const { isAdmin, loggedInUser } = useSelector(loggedInUserState)
  const hasEditPrivileges = isAdmin
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const vendorLabel = getVendorLabel(selectedClient)
  const projectLabel = getProjectLabel(selectedClient)
  const tableRowsPerPage = getTableRowsPerPage(selectedClient);
  
  const [rowsPerPage, setRowsPerPage] = useState(tableRowsPerPage)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const navToProject = (projectId) => {
    const destination = getNavigationPath(NAV_PATH.CLIENT_PROJECT_DETAILS, {clientId, projectId})
    navigate(destination)
  }
  useEffect(() => {
    if(selectedClient?.clientID) {
      loadProjects()
    }
  }, [selectedClient?.clientID])

  async function loadProjects() {
    setInitialLoading(true)
    await dispatch(clientActions.fetchProjects(selectedClient.clientID))
    setInitialLoading(false)
  }

  const filteredProjects = useMemo(() => {
    if(projects?.length) {
      let toSort = [...projects]
      if(statusFilter !== "ALL" || searchPhrase) {
        toSort = toSort.filter((project) => (
          (statusFilter === "ALL" || project.status === statusFilter) &&
          (!searchPhrase || findInStringsIgnoreCase(searchPhrase, [
            project.name,
            awsDateToUS(project.startDate),
            awsDateToUS(project.endDate),
            JSON.parse(project.requiredCoverages)?.map(requiredCoverage => requiredCoverage.insuranceType).join("")
          ]))
        ))
      }
      toSort.sort((a,b) => {
        let larger = (a[sortBy] || "") > (b[sortBy] || "")
        if(sortBy === "numVendors") {
          larger = a?.vendors?.items?.length > b?.vendors?.items?.length
        }

        if(larger) {
          return sortAsc ? 1 : -1
        }
        return sortAsc ? -1 : 1
      })
      return toSort
    }
    return projects
  }, [projects?.length, sortBy, sortAsc, statusFilter, searchPhrase])

  async function onCreateProject() {
    setSaving(true)
    const result = await dispatch(clientActions.createProject({
      projectData: newProjectData,
      clientID: selectedClient.clientID
    }))
    if(result?.payload?.success) {
      setNewProjectData(false)
      setMessage({
        severity: "success",
        text: "Successfully created project"
      })

      AmplitudeService.logEvent(AMPLITUDE_EVENT.PROJECT_CREATED.eventName,
        AMPLITUDE_EVENT.PROJECT_CREATED.eventProperties({
          userEmail: loggedInUser?.email,
          clientName: selectedClient?.name,
          clientId: selectedClient?.id,
          label: projectLabel
        })
       )
    } else {
      setMessage({
        severity: "error",
        text: "Unable to create project"
      })
    }
    setSaving(false)
  }

  function onProjectDataUpdate(name, value) {
    setNewProjectData({
      ...newProjectData,
      [name]: value
    })
  }

  return (
    <>
      <Snackbar message={message} setMessage={setMessage} />
      <AddProjectModal
        open={newProjectData !== false}
        loading={saving}
        projectData={newProjectData}
        onClose={()=>setNewProjectData(false)}
        onSave={onCreateProject}
        onUpdate={onProjectDataUpdate}
        projectLabel={projectLabel}
      />
      <div>
        <Typography variant="h5" component="span">{`${projectLabel}s`}</Typography>
        {
          hasEditPrivileges &&
          <Button startIcon={<AddIcon />} sx={{ float: "right" }} onClick={() => setNewProjectData({})}>
              {`Add New ${projectLabel}`}
          </Button>
        }
      </div>

      <TableContainer style={{overflow: "visible"}} className="projects-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} style={{border: "none"}}>
                <Menu text className="status-filter">
                  <Menu.Item active={statusFilter === 'ALL'} onClick={() => setStatusFilter('ALL')} >
                    All
                  </Menu.Item>
                  <Menu.Item active={statusFilter === "ACTIVE"} onClick={() => setStatusFilter("ACTIVE")} >
                    Active
                  </Menu.Item>
                  <Menu.Item active={statusFilter === "INACTIVE"} onClick={() => setStatusFilter("INACTIVE")} >
                    Inactive
                  </Menu.Item>
                  <Menu.Item active={statusFilter === "ARCHIVED"} onClick={() => setStatusFilter("ARCHIVED")} >
                    Archived
                  </Menu.Item>
                </Menu>

              </TableCell>
              <TableCell colSpan={1} sx={{border: "none"}} />
              <TableCell colSpan={2} sx={{border: "none", pr: 0}}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={`Search ${projectLabel}s`}
                  id="fullWidth"
                  value={searchPhrase}
                  onChange={(e) => setSearchPhrase(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <SortableTHeadCell label={projectLabel} sortId={"name"} align="left" {...theadProps} />
              <TableCell align="center">Status</TableCell>
              <SortableTHeadCell label={`Number of ${vendorLabel}s`} sortId={"numVendors"} align="left" {...theadProps} />
              <TableCell align="left">Required Coverages</TableCell>
              <SortableTHeadCell label={`${projectLabel} Start Date`} sortId={"startDate"} align="left" {...theadProps} />
              <SortableTHeadCell label={`${projectLabel} End Date`} sortId={"endDate"} align="left" {...theadProps} />
            </TableRow>
          </TableHead>
          <TableBody>
            { initialLoading &&
              <TableLoadingIndicator colSpan={6}/>
            }
            {
              !initialLoading &&
              filteredProjects
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, rowIndex) => (
                <Row key={rowIndex} row={row} onClick={navToProject} rowIndex={rowIndex} />
              ))
            }
          </TableBody>
        </Table>

      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={projects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
