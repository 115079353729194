import React, { useState, useEffect } from "react";
import { Button, Box, Step, Stepper, StepLabel } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Modal from "components/shared/Modal";
import VendorForm from "components/shared/VendorForm";
import InsuranceAgentForm from "components/shared/VendorForm/InsuranceAgentForm";
import LoaderButton from "components/shared/LoaderButton";
import { client_mode } from "constants/client_properties";

export default function VendorInfoUpdateModal({
  open,
  onClose,
  onSave,
  vendor = {},
  projects = [],
  categories = [],
  loading = false,
  vendorCategories,
  vendorProjects,
  logo,
  clientMode = client_mode.category,
  vendorLabel,
  projectLabel,
}) {

  const insuranceAgentLabel = "Insurance Agent";
  const steps = [{ label: vendorLabel }, { label: insuranceAgentLabel }];
  const [vendorData, setVendorData] = useState({});
  const [updatedLogo, setUpdatedLogo] = useState(logo);
  const [vendorDataError, setVendorDataError] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setSaving(false)
    setActiveStep(0);
    if (open) {
      setVendorData(vendor);
    } else {
      setVendorData({});
    }
  }, [open]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      title={"Update " + steps[activeStep]["label"]}
      open={open}
      content={
        <Box sx={{ width: "100%" }}>
          {
            open === 'info' &&
            <Stepper activeStep={activeStep}>
              {steps.map(({ label, ...labelProps }, index) => {
                const stepProps = {};
                labelProps.StepIconProps = {
                  style: { fontSize: "30px" },
                };
                if (activeStep === index) {
                  labelProps.StepIconProps.style.color = "green";
                } else {
                  labelProps.StepIconProps.style.color = "grey";
                }
                return (
                  <Step completed={false} key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
          </Stepper>
          }
          {
            open === 'info' &&
            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
              <Button color="inherit" disabled={activeStep === 0 || saving} onClick={handleBack} sx={{ mr: 1 }}>
                <ChevronLeftIcon /> Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} color="success" disabled={activeStep === steps.length - 1 || saving}>
                Next
                <ChevronRightIcon />
              </Button>
            </Box>
          }
          
          {
            activeStep === 0 &&
            <VendorForm
              updateSection={open}
              categories={categories}
              clientMode={clientMode}
              projects={projects}
              vendorData={vendorData}
              setVendorData={setVendorData}
              vendorDataError={vendorDataError}
              setVendorDataError={setVendorDataError}
              vendorCategories={vendorCategories}
              vendorProjects={vendorProjects}
              updatedLogo={updatedLogo}
              setUpdatedLogo={setUpdatedLogo}
              logo={logo}
              vendorLabel={vendorLabel}
              projectLabel={projectLabel}
            />
          }
          {
            activeStep === 1 && open === 'info' &&
            <InsuranceAgentForm
              vendorData={vendorData}
              setInsuranceAgentData={setVendorData}
              insuranceAgentDataError={vendorDataError}
              setInsuranceAgentDataError={setVendorDataError}
            />
          }
        </Box>
      }
      actions={
        <>
          <Button variant="outlined" onClick={() => onClose(setUpdatedLogo)} disabled={loading}>
            Cancel
          </Button>
          <LoaderButton
            variant="contained"
            onClick={() => {
              onSave(vendorData, updatedLogo, setUpdatedLogo);
              setSaving(true);
            }
            }
            disabled={loading || !(vendorData.name && vendorData.email && vendorData?.selectedProjects?.length > 0)}
            loading={loading}
          >
            {loading ? "Saving" : "Save"}
          </LoaderButton>
        </>
      }
    />
  );
}
