import React, { useEffect, useState } from 'react'
import "./page-header.scss"
import {
  Dropdown,
  Menu,
} from 'semantic-ui-react'
import { AmplifyS3Image } from '@aws-amplify/ui-react-v1/legacy';
import Container from "@mui/material/Container"
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loggedInUserState } from "redux/userSlice"
import {vendorActions} from "redux/vendorSlice";
import {organizationActions, organizationState, userOrganizationState} from "redux/organizationSlice";
import organization_types from "constants/organization_types";
import { useNavPath, getNavigationPath } from "constants/routes";

const PageHeader = () => {
  const auth = useAuthenticator((context) => {
    return [context.user]
  });
  const [logo, setLogo] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector(loggedInUserState)
  const signOut = async () => {
    try {
      await auth.signOut();
      document.title = "MANACERT"
      navigate("/")
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  const { selectedClient, selectedAgency } = useSelector(organizationState);
  const { userOrganization, userOrgType } = useSelector(userOrganizationState);
  const NAV_PATH = useNavPath(userOrgType)

  function handleClientSelect (id) {
    dispatch(vendorActions.resetState())
    dispatch(organizationActions.setSelectedClient({ id: id }))
    navigate(getNavigationPath(NAV_PATH.CLIENT_VENDORS, { clientId: id }))
  }

  function getSelectedClientOrAgency () {
    return selectedClient || selectedAgency;
  }

  //update logo on every component refresh
  useEffect(() => {
    setLogo(userOrganization?.logo)
})

  return (
    <div className={"page-header"}>
      <Menu
        fixed='top'
        style={{
          boxShadow: "none",
          backgroundColor: "#f3f4f7"
        }}
      >
        <Container sx={{display: "flex"}} maxWidth="xl">
          {
            (userOrgType === organization_types.CLIENT || !selectedAgency) &&
            <Menu.Item style={{ borderLeft: "none" }} onClick={() => navigate("/")}>
                {logo &&
                  <AmplifyS3Image
                    key={"logo" + String(userOrganization?.name).replace(" ", "-")}
                    alt={userOrganization?.name + " image"}
                    imgKey={"logos/" + logo}
                    level="public"
                    imgProps={{
                      style: {
                        height: "20px",
                        marginRight: "10px"
                      }
                    }}
                  />
                }
              {userOrganization?.name}
            </Menu.Item>
          }
          {
            userOrgType !== organization_types.CLIENT && !!selectedAgency &&
            <Menu.Item style={{borderLeft: "none"}}>
            <Dropdown
              text={
                <>
                  {
                  getSelectedClientOrAgency()?.logo &&
                  <AmplifyS3Image
                    key={"logo" + String(getSelectedClientOrAgency()?.name).replace(" ", "-")}
                    alt={ getSelectedClientOrAgency()?.name + " image"}
                    imgKey={"logos/" + getSelectedClientOrAgency()?.logo}
                    level="public"
                    imgProps={{
                      style: {
                        height: "20px",
                        marginRight: "10px"
                      }
                    }}
                  />
                  }
                  {selectedClient?.name || selectedAgency?.name || userOrganization?.name}
                </>
              }
              floating
              labeled
              className="organization-select-menu"
              style={{display: "flex", alignItems: "center"}}
            >
              <Dropdown.Menu>
                {/*{*/}
                {/*  selectedClient &&*/}
                {/*  <>*/}
                {/*    <Dropdown.Item text={selectedClient.name} disabled />*/}
                {/*    <Dropdown.Divider />*/}
                {/*  </>*/}
                {/*}*/}

                {
                  userOrgType === organization_types.SYSTEM &&
                  <>
                    <Dropdown.Item text="View All Affiliates" onClick={()=>navigate(NAV_PATH.AGENCIES)}/>
                    <Dropdown.Divider />
                  </>
                }
                <Dropdown.Header>{selectedAgency.name}</Dropdown.Header>
                {
                  selectedAgency.clients?.map(client => (
                    <Dropdown.Item
                      disabled={client.id === selectedClient?.id}
                      active={client.id === selectedClient?.id}
                      key={"header-menu-item-" + client.id}
                      content={<span style={{marginLeft: "1em"}}>{client.name}</span>}
                      onClick={()=>handleClientSelect(client.id)}
                    />
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
            </Menu.Item>
          }

          <Menu.Item header style={{flexGrow:1}}></Menu.Item>
          <Menu.Item>
            <Dropdown
              text={
                (loggedInUser?.firstName || loggedInUser?.lastName)
                ? `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
                : `Hello`
              }
              floating
              labeled
              button
              className='icon account-settings-dropdown'
            >
              <Dropdown.Menu>
                <Dropdown.Item icon='user' text="User Settings" onClick={()=>navigate(NAV_PATH.SETTINGS)}/>
                {/*<Dropdown.Item icon='cog' text="Org Settings" onClick={()=>navigate(NAV_PATH.ORGANIZATION_SETTINGS)}/>*/}
                <Dropdown.Item icon='log out' text="Sign Out" onClick={signOut} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>

        </Container>
      </Menu>
    </div>
  )
}

export default PageHeader
