import React, {useEffect, useState}  from "react";
import {useSelector} from "react-redux";
import {getCertificateActivities, logCertificateActivity} from "services/activity";
import {loggedInUserState} from "redux/userSlice";
import {formatCurrencyInt} from "utils/formUtils";
import insurance_types, {non_custom_insurance_types} from "constants/insurance_types";

const fieldFields = [
  "status",
  "certificateDate",
  "insured",
  "certificateHolder",
  "producer",
  "producerName",
  "producerContactName",
  "producerPhone",
  "producerFax",
  "producerEmail",
  "insurerA",
  "insurerANAIC",
  "insurerB",
  "insurerBNAIC",
  "insurerC",
  "insurerCNAIC",
  "insurerD",
  "insurerDNAIC",
  "insurerE",
  "insurerENAIC",
  "insurerF",
  "insurerFNAIC",
  "descriptionOfOperation",
  "requestedNewValidCertificateURL"
]

const coverageFields = {
  insuranceForm: {type: "array", name: "Insurance Form"},
  aggregateAppliesPer: {type: "array", name: "Aggregate Applies Per"},
  coveredProperty: {type: "array", name: "Covered Properties"},
  additionalInsured: {name: "Additional Insured"},
  subrogationWaiver: {name: "Subrogation Waiver"},
  deductible: {name: "Deductible"},
  retention: {name: "Retention"},
  exclusion: {name: "Exclusion"},
  limitPerStatute: {name: "Limit Per Statute"},

  policyNumber: {name: "Policy Number"},
  insurerLetter: {type: "array", name: "Insurer Letters"},
  selectedCoverage: {type: "array", name: "Coverages"},
}

export default function useCertificateActivities(certificate) {
  const [originalData, setOriginalData] = useState({})
  const [certificateActivities, setCertificateActivities] = useState([])
  const { loggedInUser } = useSelector(loggedInUserState)
  let userFullName = loggedInUser?.email;
  if(loggedInUser?.firstName || loggedInUser?.lastName) {
    userFullName = `${loggedInUser.firstName}${loggedInUser.firstName ? " " : ""}${loggedInUser.lastName}`;
  }

  const loadCertificateActivities = async function(id) {
    const result = await getCertificateActivities(certificate?.id || id)
    setCertificateActivities(result)
  }

  const addCertificateProjectActivity = async function(prev, next) {
    await logCertificateActivity({
      certificateID: certificate.id,
      userFullName,
      userID: loggedInUser.id,
      action: "EDIT",
      field: JSON.stringify({
        project: {
          project: {
            prev: prev || "",
            next: next || ""
          }
        }
      })
    })
    loadCertificateActivities();
  }

  const addCertificateActivity = async function(certificateData = {}, certificateFiles = [], explicitCertificateData) {
    if(certificateData?.action === "CREATE") {
      const {action, id: certificateID} = certificateData
      await logCertificateActivity({
        certificateID,
        userFullName,
        userID: loggedInUser.id,
        action,
      })
      loadCertificateActivities(certificateID);
      return;
    }

    const hasExplicitCertificateData = explicitCertificateData && Object.keys(explicitCertificateData).length 
    const originalCertificateInfo = hasExplicitCertificateData ? explicitCertificateData : originalData || {}

    // cert overview fields
    const certificateUpdates = {}
    fieldFields.forEach(key => {
      if(String(originalCertificateInfo[key]) !== String(certificateData[key])) {
        certificateUpdates[key] = {prev: originalCertificateInfo[key], next: certificateData[key]}
      }
    })

    // cert overrides
    if (certificateData.overrides) {
      const prevOverrides = typeof originalCertificateInfo?.overrides === 'string' ? JSON.parse(originalCertificateInfo.overrides) : originalCertificateInfo?.overrides;
      const nextOverrides = typeof certificateData?.overrides === 'string' ? JSON.parse(certificateData.overrides) : certificateData?.overrides;
      if(nextOverrides && Object.keys(nextOverrides)?.length) {
        Object.entries(nextOverrides).forEach(([override, next]) => {
          const prev = prevOverrides && prevOverrides[override]

          if(!!prev !== !!next) {
            certificateUpdates[override] = {prev: prev ? "OVERRIDDEN" : "NOT OVERRIDDEN", next: next ? "OVERRIDDEN" : "NOT OVERRIDDEN"}
          }
        })
      }
    }

    // coverage fields
    const coverageUpdates = {}
    const nextCoverages = certificateData.coverages?.items;
    const preCoverages = originalCertificateInfo?.coverages?.items;

    if(nextCoverages?.length) {
      nextCoverages.forEach(coverage => {
        const insuranceType = coverage.insuranceType || String(coverage.name || "CUS").substring(0,3).toUpperCase()
        const prevCoverage = preCoverages?.length && preCoverages.find(prev => prev.id === coverage.id)
        const prevLimits = typeof prevCoverage?.limits === 'string' ? JSON.parse(prevCoverage.limits) : prevCoverage?.limits;
        const nextLimits = typeof coverage?.limits === 'string' ? JSON.parse(coverage.limits) : coverage?.limits;
        if(nextLimits && Object.keys(nextLimits)?.length) {
          Object.entries(nextLimits).forEach(([limit, next]) => {
            const prev = prevLimits && prevLimits[limit]
            if(String(prev) !== String(next)) {
              coverageUpdates[`${insuranceType} - ${limit}`] = {prev: formatCurrencyInt(prev), next: formatCurrencyInt(next)}
            }
          })
        }

        const prevOverrides = typeof prevCoverage?.overrides === 'string' ? JSON.parse(prevCoverage.overrides) : prevCoverage?.overrides;
        const nextOverrides = typeof coverage?.overrides === 'string' ? JSON.parse(coverage.overrides) : coverage?.overrides;
        if(nextOverrides && Object.keys(nextOverrides)?.length) {
          Object.entries(nextOverrides).forEach(([override, next]) => {
            const prev = prevOverrides && prevOverrides[override]
            if(!!prev !== !!next) {
              coverageUpdates[`${insuranceType} - ${override}`] = {prev: prev ? "OVERRIDDEN" : "NOT OVERRIDDEN", next: next ? "OVERRIDDEN" : "NOT OVERRIDDEN"}
            }
          })
        }
        const insuranceTypeFields = non_custom_insurance_types.includes(insuranceType) ? insurance_types[insuranceType] : insurance_types.CUSTOM
        Object.entries(coverageFields).forEach(([fieldKey, config]) => {
          const {type, name} = config
          let prev, next;
          if(
            insuranceTypeFields[fieldKey]
            || ["policyNumber", "insurerLetter"].includes(fieldKey)
            || (fieldKey === "selectedCoverage" && insuranceTypeFields.selectableCoverage)
          ) {
            if(type === "array") {
              prev = [...(prevCoverage?.[fieldKey] || [])].sort()?.filter(ele => !!ele)?.join(", ")
              next = [...(coverage?.[fieldKey] || [])].sort()?.filter(ele => !!ele)?.join(", ")
            } else if(type === "boolean") {
              prev = prevCoverage?.[fieldKey] ? "checked" : "unchecked"
              next = coverage?.[fieldKey] ? "checked" : "unchecked"
            } else {
              prev = String(prevCoverage?.[fieldKey])
              next = String(coverage?.[fieldKey])
            }
            if(prev !== next) {
              coverageUpdates[`${insuranceType} - ${name}`] = {prev, next}
            }
          }
          const prevEff = prevCoverage?.policyEff
          const nextEff =  coverage?.policyEff
          const prevExp = prevCoverage?.policyExp
          const nextExp = coverage?.policyExp
          if(prevEff?.join(",") !== nextEff?.join(",") || prevExp?.join(",") !== nextExp?.join(",")) {
            const prevDates = prevEff?.map((eff, index) => {
              const exp = prevExp[index]
              const [effYear, effMon, effDay] = eff.split("-")
              const [expYear, expMon, expDay] = exp?.split("-")
              return `${effMon}/${effDay}/${effYear} - ${expMon}/${expDay}/${expYear}`
            })?.join(", ")
            const nextDates = nextEff?.map((eff, index) => {
              const exp = nextExp[index]
              const [effYear, effMon, effDay] = eff.split("-")
              const [expYear, expMon, expDay] = exp?.split("-")
              return `${effMon}/${effDay}/${effYear} - ${expMon}/${expDay}/${expYear}`
            })?.join(", ")
            coverageUpdates[`${insuranceType} - Policy Dates`] = { prev: prevDates, next: nextDates}
          }
        })
      })
    }

    // file updates
    const fileUpdates = {}
    if(certificateFiles?.length) {
      const acord25FileChange = {}
      certificateFiles.forEach(file => {
        if(file.isAcord25) {
          if(file.isNew) {
            acord25FileChange.next = file.name
          } else if(file.isDeleted) {
            acord25FileChange.prev = file.name
          }
        } else if(file.isNew || file.isDeleted && !(file.isNew && file.isDeleted)) {
          fileUpdates['Supplemental File - ' + file.name] = {
            prev: "action",
            next: file.isDeleted ? "Deleted" : "Uploaded"
          }
        }
      })
      if(acord25FileChange.next) {
        fileUpdates['Acord25 File'] = acord25FileChange;
      }
    }

    const field = {}
    if(Object.keys(certificateUpdates).length) {
      field.certificate = certificateUpdates
    }
    if(Object.keys(coverageUpdates).length) {
      field.coverages = coverageUpdates
    }

    if(Object.keys(fileUpdates).length) {
      field.files = fileUpdates
    }

    if(Object.keys(field).length) {
      await logCertificateActivity({
        certificateID: originalCertificateInfo.id,
        userFullName,
        userID: loggedInUser.id,
        action: "EDIT",
        field: JSON.stringify(field)
      })
    }
    if (!hasExplicitCertificateData) {
      loadCertificateActivities(originalCertificateInfo?.id);
    }
  }

  useEffect(() => {
    if(certificate?.id) {
      setOriginalData(certificate);
      loadCertificateActivities();
    }
  }, [certificate])

  return [
    certificateActivities,
    addCertificateActivity,
    addCertificateProjectActivity,
  ]
}
