import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { loggedInUserState } from "redux/userSlice";

import {
  Box,
  Container,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import {Menu, Image} from "semantic-ui-react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {useParams} from "react-router-dom";
import {createFileNameUnique} from "utils/formUtils";

import {SubtitleInline} from "components/shared/Typography";
import LoaderButton from "components/shared/LoaderButton";
import CertificateFileDisplay from "components/CertificateDetails/CertificateFileDisplay";
import CertificateFilesSection from "components/CertificateDetails/CertificateFilesSection";
import {getEmailFieldError} from "utils/validationUtils"
import cognito_groups from "constants/cognito_groups";
import { getVendorDocumentUploadTemplate } from "constants/client_properties";
import certificate_actions from "constants/certificate_actions";
import {ROUTE_FRAGMENTS} from "constants/routes"
import {API} from "aws-amplify";
import "./vendor-upload.scss"

import AmplitudeService, { AMPLITUDE_EVENT } from "services/amplitude";
import {uploadUsingPresignedUrl, getCertificateFiles} from "services/appResources";
import DisplayProjectCoverages from "components/AddVendorWizard/DisplayProjectCoverages";
import PrivacyPolicy from "./PrivacyPolicy";

export default function VendorDocumentUpload () {

  const { isAdmin, loggedInUser } = useSelector(loggedInUserState);
  const { certificateId, vendorId, organizationId, viewMode } = useParams();
  const [initialLoading, setInitialLoading] = useState(false);
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [notify, setNotify] = useState()
  const [vendor, setVendor] = useState()

  const [isSelf, setIsSelf] = useState();
  const [uploadAgentName, setUploadAgentName] = useState(null)
  const [uploadAgentEmail, setUploadAgentEmail] = useState(null)
  const [uploadAgentEmailError, setUploadAgentEmailError] = useState(null)
  const [logo, setLogo] = useState()
  const [project, setProject] = useState()
  const [organization, setOrganization] = useState()
  const [certificateData, setCertificateData] = useState()
  const [certificateFiles, setCertificateFiles] = useState([])
  const [fileSelected, setFileSelected] = useState(false)
  const [hasPending, setHasPending] = useState(false)
  const [isPrivacyPolicyConfirmed, setIsPrivacyPolicyConfirmed] = useState(false)
  const [initialCoverageRequirements, setInitialCoverageRequirements] = useState();

  const isViewOnly = viewMode?.toLowerCase() === ROUTE_FRAGMENTS.SHARED.toLowerCase()

  useEffect(() => {
    if (isPrivacyPolicyConfirmed || isViewOnly) {
      loadInitialData()
    }
  }, [isPrivacyPolicyConfirmed])

  useEffect(() => {
    if(certificateFiles?.length && certificateFiles[certificateFiles.length - 1]?.isNew) {
      setFileSelected(certificateFiles[certificateFiles.length - 1].name)
    }
  }, [certificateFiles])

  async function loadInitialData() {
    setInitialLoading(true)
    const result = await API.get("FileUploadApi", "/upload", {
        queryStringParameters: {
          organizationId,
          certificateId,
          vendorId,
        }
      }
    )
    result?.logo && setLogo(result.logo)
    result?.notify && setNotify(result.notify)
    result?.project && setProject(result.project)
    result?.organization && setOrganization(result.organization)
    if(result?.inactive) {
      setVendor({inactive: true})
    } else {
      result?.vendor && setVendor(result.vendor)

      let initialFileSelected;
      const certificate = result?.certificate
      setCertificateData({ ...certificate })
      if (certificate?.initialCoverageRequirements && typeof certificate.initialCoverageRequirements === "string") {
        setInitialCoverageRequirements(JSON.parse(certificate.initialCoverageRequirements));
      }

      if((certificate?.acord25File || certificate.files?.length) && isViewOnly) {
        setFileSelected(certificate?.acord25File || certificate.files[0])
        let files = [];
        (certificate?.acord25File) && files.push({name: certificate?.acord25File, isAcord25: true});
        (certificate.files?.length) && (files = files.concat(certificate.files));
        getCertificateFiles(certificate.id, files, result => setCertificateFiles(result))
      } else {
        setCertificateFiles([])
      }
      if(result?.hasPending) {
        setHasPending(true)
      }
      if(initialFileSelected) {
        setFileSelected(initialFileSelected)
      }
    }
    setInitialLoading(false)
  }

  if (!isPrivacyPolicyConfirmed && !isViewOnly) {
    return <PrivacyPolicy onConfirm={()=>setIsPrivacyPolicyConfirmed(true)} />
  }

  const handleEntitySelect = (e) => {
    setIsSelf(e.target.value)
    if(e.target.value) {
      setUploadAgentName(null)
      setUploadAgentEmail(null)
      setUploadAgentEmailError(null)
    }
  }

  const handleFileUpload = (e, newAcord25FileName) => {
    const file =  e.target.files ? e.target.files[0] : e?.dataTransfer?.files[0]
    if(file) {
      const reader = new FileReader();
      reader.onload = function(event) {
        const fileName = String(file.name).replaceAll(" ", "")
        const fileNameUnique = newAcord25FileName ? fileName : createFileNameUnique(
          String(file.name).replaceAll(" ", ""),
          certificateFiles
            .filter(certificateFile => !certificateFile.isAcord25)
            .map(certificateFile => certificateFile.name)
        )
        const newFiles = certificateFiles.map(file => {
          if(newAcord25FileName && file.isAcord25) {
            if(!file.isNew) {
              return {
                ...file,
                isDeleted: true,
                isAcord25: true
              }
            } else {
              return {
                ...file,
                isNew: false,
                isAcord25: true,
                isDeleted: true,
              }
            }
          }
          return {...file}
        })

        setCertificateFiles([
          ...newFiles,
          {
            content: event.target.result,
            name: fileNameUnique,
            isNew: true,
            isAcord25: !!newAcord25FileName
          }
        ])
      }
      reader.readAsDataURL(file)
    }
  }

  const onCancelSave = () => {
    setCertificateFiles(
      certificateFiles?.filter(file => !file.isNew).map(file => ({...file, isDeleted: false}))
    )
    setFileSelected(certificateData.acord25File || certificateData.files?.[0])
  }

  const onSaveFile = async () => {
    let acord25Uploaded = false
    setSaving(true)

    const newSuppFiles = certificateFiles?.filter(file => file.isNew && !file.isAcord25)
    const acord25File = certificateFiles?.find(file => file.isNew && file.isAcord25)
    let newCertId = certificateId

    // we want to presign and upload the file after creating a scan record if it is acord25
    if (acord25File) {
      const uploaderName = isSelf ? vendor?.name : uploadAgentName;
      const certAcord25UpdateResult = await API.post("FileUploadApi", "/upload", {
        body: {
          certificateId,
          fileName: acord25File.name,
          vendor: vendor?.name,
          uploaderName: uploaderName,
          uploaderEmail: isSelf ? vendor?.email : uploadAgentEmail,
          uploadLink: `${window.location.origin}${window.location.pathname}`,
          isAcord25: true,
          notify,
          notifyClientAdmins: [
            ...new Set([
              ...organization?.organizationUsers?.items
                ?.filter((user) => user.role !== cognito_groups.ClientViewer && user.status === "ACTIVE")
                .map((user) => user.email),
              ...(Array.isArray(vendor?.additionalEmails) ? vendor?.additionalEmails : []),
            ]),
          ],
          action: certificate_actions.CLIENT_ADMIN_NOTIFICATION,
          email: getVendorDocumentUploadTemplate(organization?.name, vendor?.name, project?.name, uploaderName, isSelf).CertificateUploadNotification,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(certAcord25UpdateResult.scanId && certAcord25UpdateResult.certificateId) {
        AmplitudeService.logEvent(AMPLITUDE_EVENT.COI_FILE_UPLOADED.eventName,
          AMPLITUDE_EVENT.COI_FILE_UPLOADED.eventProperties({
            userEmail: loggedInUser?.email,
            isAdminUser: isAdmin,
            clientId: organizationId,
            clientName: organization?.name,
            isSelf: isSelf,
            uploaderEmail: isSelf ? vendor?.email : uploadAgentEmail,
          })
        )
        newCertId = certAcord25UpdateResult.certificateId
        const presignedAcord25UrlResponse = await API.post("FileUploadApi", "/upload/presign", {
          body: {
            files: [{name: acord25File.name, isAcord25: acord25File.isAcord25}],
            certificateId: newCertId,
            scanId: certAcord25UpdateResult.scanId
          },
          headers: {
            'Content-Type' : 'application/json',
          }
        })
        if(presignedAcord25UrlResponse?.presignedUrls?.length) {
          const { presignUrl } = presignedAcord25UrlResponse.presignedUrls[0]
          const acord25ploadResult = await uploadUsingPresignedUrl(presignUrl, acord25File.content)
          acord25Uploaded = true
          console.log("acord25ploadResult", acord25ploadResult)
        } else {
          console.log("No presign url created for Acord25 file - " + acord25File.name)
        }
      }
    }

    // we want to upload the file first when it is supplemental before updating the certificate
    if(newSuppFiles.length) {
      const presignedUrlResponse = await API.post("FileUploadApi", "/upload/presign", {
        body: {
          files: newSuppFiles?.map(file => ({name: file.name, isAcord25: file.isAcord25})),
          certificateId: newCertId,
          existingFiles: newCertId === certificateId ? certificateData.files : null
        },
        headers: {
          'Content-Type' : 'application/json',
        }
      })

      const presignedUrls = presignedUrlResponse?.presignedUrls
      if(presignedUrls?.length) {
        await Promise.all((newSuppFiles || []).map(async updatedFile => {
          const presignUrlConfig = presignedUrls.find(presignUrl => presignUrl.fileName === updatedFile.name);
            if(presignUrlConfig) {
              const uploadResult = await uploadUsingPresignedUrl(presignUrlConfig.presignUrl, updatedFile.content)
              console.log("uploadResult", uploadResult)
              const certUpdateResult = await API.post("FileUploadApi", "/upload", {
                body: {
                  certificateId: newCertId,
                  fileName: updatedFile.isAcord25 ? updatedFile.name : presignUrlConfig?.fileNameUnique,
                  vendor: vendor?.name,
                  uploaderName: isSelf ? vendor?.name : uploadAgentName,
                  uploaderEmail: isSelf ? vendor?.email : uploadAgentEmail,
                  uploadLink: `${window.location.origin}${window.location.pathname}`,
                  isAcord25: false,
                  notify,
                },
                headers: {
                  'Content-Type' : 'application/json',
                }
              })
            } else {
              console.log("No presign url created for file - " + updatedFile.name)
            }
          return Promise.resolve()
        }))
      }
    }


    setSaving(false)
    if(acord25Uploaded) {
      setHasPending(true)
    }
    setSaved(true)
  }

  const renderActionButtons = () => {
    const enableSave = certificateFiles?.length && (isSelf || (uploadAgentEmail && uploadAgentName && !uploadAgentEmailError))
    return (
      <Box sx={{ml: "auto", alignSelf: "center", float: "right"}}>
        <Button
          size="small"
          onClick={onCancelSave}
          disabled={!certificateFiles?.length}
        >Cancel</Button>
        <LoaderButton
          variant="contained"
          size="small"
          onClick={onSaveFile}
          disabled={!enableSave}
          loading={saving}
        >
          UPLOAD
        </LoaderButton>
      </Box>
    )
  }

  const handleUploadAgentEmailChange = (e) => {
    setUploadAgentEmail(e.target.value)
    if(!e.target.value) {
      setUploadAgentEmailError("Email is required.")
    } else if(getEmailFieldError(e.target)) {
      setUploadAgentEmailError("Email is not in valid format.")
    } else {
      setUploadAgentEmailError("")
    }
  }

  //Disable upload for uploaded files
  if (!initialLoading && (certificateData?.acord25File && certificateData?.status !== "REQUESTED") && !isViewOnly) {
    return (
      <>
        <Container maxWidth="xl">
          <Menu fixed="top"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#f3f4f7"
                }}
          >
            <Menu.Item style={{borderRight: "none",}}>
              <Image size="mini" src={window.location.origin + "/app-logo-h110.png"} />
            </Menu.Item>
          </Menu>
      </Container>
      <Container sx={{mt: "90px", pb: 3}} maxWidth="xl">
        <Box sx={{display: "flex", alignItems: "center", flexFlow: "column"}}>
          <Typography variant="h5" pl={2}>
            This link is not valid anymore (already uploaded certificate file <b>{ certificateData?.acord25File}</b>).
          </Typography>
          <Typography variant="h6" pl={2}>
            Please contact the <b>"{ organization?.name }"</b> for more information.
          </Typography>
          <Image style={{margin: '2em'}} size="medium" src={window.location.origin + "/app-logo-h110.png"} />
         </Box>
      </Container>
      </>
    )
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: "blur(8px)"}}
        open={initialLoading}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth="xl">
        <Menu fixed="top"
              style={{
                boxShadow: "none",
                backgroundColor: "#f3f4f7"
              }}
        >
          <Menu.Item style={{borderRight: "none",}}>
            <Image size="mini" src={window.location.origin + "/app-logo-h110.png"} />
          </Menu.Item>
        </Menu>
      </Container>
      <Container sx={{mt: "90px", pb: 3}} maxWidth="xl">
        {
          !initialLoading && !!vendor?.inactive &&
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography variant="h5" as="span" pl={2}>
              Upload for this subcontractor is disabled.
            </Typography>
          </Box>
        }
        { !initialLoading && (hasPending || saved) &&
          <Box sx={{display: "flex", alignItems: "center",flexFlow: "column"}}>
            {/*<Loader active inline indeterminate size='small' />*/}
            <Typography variant="h5" as="span" pl={2}>
              Thank you for uploading! We have received your certificate file(s).
              {hasPending && ` You will receive a confirmation email shortly.`}
            </Typography>
            <Image style={{margin: '2em'}} size="medium" src={window.location.origin + "/app-logo-h110.png"} />
          </Box>
        }
        {
          !initialLoading && !vendor?.inactive && !hasPending && !saved &&
          <>
            <Box sx={{display: "flex", alignItems: "center",flexFlow: "column", width: "800px"}}>
              <Image style={{margin: '2em'}} size="medium" src={window.location.origin + "/app-logo-h110.png"} />
              <Typography fontSize={14} fontStyle="italic" textAlign="justify">
                This document is for authorized personnel only and must not be shared or copied. If you are not the intended recipient, please disregard this message. Unauthorized access or use is prohibited. Notify the sender if received in error.
              </Typography>
            </Box>
            <Box sx={{textAlign: "right"}}>
              <Link
                href={`${window.location.origin}/images/sample/acord25.png`}
                target={"_blank"}
              >
                View Sample Acord25 <OpenInNewIcon fontSize={"small"}/>
              </Link>
            </Box>
            <Stack direction="row" spacing={2} mt={3}>
              <Stack>
                <CertificateFileDisplay
                  certificateFiles={certificateFiles || []}
                  fileSelected={fileSelected}
                  handleFileUpload={handleFileUpload}
                  disableUpload={true}
                />
              </Stack>

              <Divider orientation={"vertical"} flexItem={true}/>

              <Stack sx={{ flexGrow: 1 }}>
                {
                  !isViewOnly &&
                  <>
                    <Typography variant="h5" mr={2}>
                      {vendor?.name}
                      {
                        renderActionButtons()
                      }
                    </Typography>
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <SubtitleInline>
                        {`WHO IS UPLOADING`}
                      </SubtitleInline>
                      <FormControl fullWidth={true} sx={{ mt: 2 }}>
                        <InputLabel id="party-select-label">I am...(required)</InputLabel>
                        <Select
                          labelId="party-select-label"
                          id="party-select-label"
                          value={isSelf}
                          label="I am...(required)"
                          onChange={handleEntitySelect}
                        >
                          <MenuItem key={"party-self"} value={true}>{`Self - ${vendor?.name}`}</MenuItem>
                          <MenuItem key={"party-self"} value={false}>Insurance Agent</MenuItem>
                        </Select>
                      </FormControl>
                      {
                        isSelf === false &&
                        <>
                          <TextField
                            fullWidth
                            label="Affiliate Name (required)"
                            sx={{ mt: 2 }}
                            value={uploadAgentName}
                            onChange={(e) => setUploadAgentName(e.target.value)}
                            onBlur={(e) => setUploadAgentName(e.target.value)}
                            error={uploadAgentName === ""}
                            helperText={uploadAgentName === "" ? "Affiliate Name is required." : ""}
                          />
                          <TextField
                            fullWidth
                            label="Contact Email (required)"
                            sx={{ mt: 2 }}
                            value={uploadAgentEmail}
                            onChange={handleUploadAgentEmailChange}
                            onBlur={handleUploadAgentEmailChange}
                            inputProps={{ pattern: "^\\S+@\\S+\\.\\S+$" }}
                            error={!!uploadAgentEmailError}
                            helperText={uploadAgentEmailError}
                          />
                        </>
                      }
                    </Box>

                    <CertificateFilesSection
                      {...{
                        acord25FileName: certificateData?.acord25File,
                        certificateFiles: certificateFiles,
                        handleFileUpload,
                        setFileSelected,
                        disableUpload: hasPending || saving,
                        saving,
                      }}
                    />
                  </>
                }
                {initialCoverageRequirements && (
                  <DisplayProjectCoverages
                    project={project}
                    certificateHolder={organization?.name}
                    initialCoverageRequirements={initialCoverageRequirements}
                    logo={logo}
                  />)}
              </Stack>
            </Stack>
          </>
        }
      </Container>

    </>
  )
}
