import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { List, Segment } from "semantic-ui-react";

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Typography from "@mui/material/Typography";

const data = [
  { name: "General Contractor", value: 200, color: "#2f3eb0" },
  { name: "Sub Contractor", value: 400, color: "#0088FE" },
  { name: "Trucking", value: 300, color: "#00C49F" },
  { name: "Supplier", value: 300, color: "#FFBB28" },
  { name: "Professional Service", value: 200, color: "#FF8042" },
  { name: "Maintenance", value: 200, color: "#8884D8" },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function VendorCategoriesPieChart() {
  return (
    <>
      <Typography variant="h6" sx={{mb:4}}>Vendor Categories Breakdown</Typography>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <Segment basic>
        <List divided relaxed>
          <List.Item>
            <List.Icon><CircleOutlinedIcon sx={{color: "transparent"}}/></List.Icon>
            <List.Content>
              <List.Header>Category<span style={{float: "right"}}>Count</span></List.Header>
            </List.Content>

          </List.Item>
          {data.map(entry => (
            <List.Item>
              <List.Icon>
                <CircleOutlinedIcon sx={{color: entry.color}}/>
              </List.Icon>
              <List.Content>
                <List.Description>
                  <span>{entry.name}</span>
                  <span style={{float: "right"}}>{entry.value}</span>
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>
    </>
  );
}
