import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {organizationState,organizationActions} from "redux/organizationSlice";
import { AmplifyS3Image } from '@aws-amplify/ui-react-v1/legacy';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Box,
  Typography,
  Button,
} from '@mui/material';
import AddIcon from "@mui/icons-material/Add"
import {Navigate, useNavigate} from "react-router-dom";
import {
  getNavigationPath,
  useNavPath,
} from "constants/routes";
import AddOrganizationModal from "components/shared/AddOrganizationModal";
import Snackbar from "components/shared/Snackbar";
import TableLoadingIndicator from "components/shared/TableLoadingIndicator";
import { getClientLabel } from "constants/organization_types";

export default() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectedAgency, userOrgType, status } = useSelector(organizationState)
  const clientLabel = getClientLabel(selectedAgency);
  const NAV_PATH = useNavPath(userOrgType)
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false)
  const [showAddClientModal, setShowAddClientModal] = useState(false)

  let organizations = []
  if(selectedAgency) {
    organizations = selectedAgency.clients
  }

  useEffect(() => {
    dispatch(organizationActions.setSelectedClient(null))
  }, [])

  async function createClient(inputs) {
    setLoading(true)
    const result = await dispatch(organizationActions.createClient({
      ...inputs,
      agencyId: selectedAgency.id
    }))
    if(result?.payload?.success) {
      dispatch(organizationActions.getClients(selectedAgency?.id))
      setShowAddClientModal(false)
      setMessage({
        severity: "success",
        text: "Successfully created client"
      })
    } else {
      setMessage({
        severity: "error",
        text: "Unable to create affiliate"
      })
    }
    setLoading(false)
  }

  function handleClientSelect(id) {
    dispatch(organizationActions.setSelectedClient({id: id}))
    navigate(getNavigationPath(NAV_PATH.CLIENT_VENDORS, {clientId: id}))
  }

  return (
    <>
      <Snackbar message={message} setMessage={setMessage} />
      <AddOrganizationModal
        open={showAddClientModal}
        onClose={()=>setShowAddClientModal(false)}
        onSave={(inputs)=>createClient(inputs)}
        loading={loading}
        isClient={true}
      />
      <Box sx={{mb: 2}}>
        <Typography variant={"h5"} component={"span"}>Clients</Typography>
        <Button
          sx={{float: "right"}}
          startIcon={<AddIcon />}
          onClick={()=>setShowAddClientModal(true)}
        >Add A {clientLabel}</Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell /> {/* avatar */}
              <TableCell>Organization Name</TableCell>
              <TableCell>Client Mode</TableCell>
              <TableCell>Logo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              status === 'loading' &&
              <TableLoadingIndicator colSpan={4}/>
            }
            {
              !(status === 'loading') &&
              organizations?.map(organization => (
                <TableRow onClick={()=>handleClientSelect(organization.id)} className="clickable" key={organization.id}>
                  <TableCell>
                    <Avatar sx={{ bgcolor: organization.themeColor || "default" }}>
                      {
                        organization.name.charAt(0)
                      }
                    </Avatar>
                  </TableCell>
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>{organization.mode}</TableCell>
                  <TableCell>
                    <Box sx={{maxHeight: "50px"}}>
                    {
                      organization.logo &&
                      <AmplifyS3Image
                        alt={organization.name + " image"}
                        imgKey={"logos/" + organization.logo}
                        level="public"
                        imgProps={{
                          style: {
                            height: "50px"
                          }
                        }}
                      />
                    }
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
