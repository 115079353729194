import * as amplitude from "@amplitude/analytics-browser";

const basicEventProperties = {
  userEmail: "",
  clientName: "",
  clientId: "",
};

export const AMPLITUDE_EVENT = {
  PROJECT_CREATED: {
    eventName: "Project created",
    eventProperties: function (eventProps = { ...basicEventProperties, label: "" }) {
      return eventProps;
    },
  },
  SUBCONTRACTOR_CREATED: {
    eventName: "Franchisee/Subcontractor created",
    eventProperties: function (eventProps = { ...basicEventProperties, label: "" }) {
      return eventProps;
    },
  },
  SUBCONTRACTOR_STATUS_CHANGED: {
    eventName: "Franchisee/Subcontractor status changed",
    eventProperties: function (eventProps = { ...basicEventProperties, previous: "", next: "", subcontractorId: "", subcontractorName: ""}) {
      return eventProps;
    },
  },
  CERTIFICATE_CREATED: {
    eventName: "Certificate created",
    eventProperties: function (eventProps = { ...basicEventProperties }) {
      return eventProps;
    },
  },
  CERTIFICATE_REQUESTED: {
    eventName: "Certificate requested",
    eventProperties: function (eventProps = { ...basicEventProperties }) {
      return eventProps;
    },
  },
  COI_FILE_UPLOADED: {
    eventName: "Coi file uploaded",
    eventProperties: function (eventProps = { ...basicEventProperties, isSelf: false, uploaderEmail: "", isAdminUser: false }) {
      return eventProps;
    },
  },
  CERTIFICATE_COVERAGE_OVERRIDE: {
    eventName: "Certificate's coverage override",
    eventProperties: function (eventProps = { ...basicEventProperties }) {
      return eventProps;
    },
  },
};

class AmplitudeService {
  static instance = null;

  constructor() {
    if (!AmplitudeService.instance) {
      this.amplitudeInstance = amplitude;
      this.isInitialized = false;
      AmplitudeService.instance = this;
    }
    return AmplitudeService.instance;
  }

  initialize(API_KEY) {
    if (!API_KEY) {
      console.info("API Key is required to initialize Amplitude");
      return;
    }
    console.log("initialize", this);
    this.amplitudeInstance
      .init(API_KEY)
      .promise.then(() => {
        this.isInitialized = true;
        console.log("Amplitude initialized successfully.");
      })
      .catch((err) => {
        console.error("Failed to initialize Amplitude", err);
        this.isInitialized = false;
      });
  }

  setUserId(userId) {
    if (this.isInitialized) {
      return;
    }
    this.amplitudeInstance.setUserId(userId);
  }

  logEvent(event, eventProperties) {
    //don't track events if analytics is not enabled
    if (!this.isInitialized) {
      console.warn("Amplitude is not initialized. Event not sent.");
      return;
    }
    this.amplitudeInstance.track(event, eventProperties);
  }
}

const instance = new AmplitudeService();
export default instance;
