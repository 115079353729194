import React from "react";
import {Button, CardHeader, Card, Divider, CardContent, Collapse} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {SectionTitle} from "components/shared/Typography";

import "./collapsible-card.scss"

const CollapsibleCard = ({children, title, action, toggle, show, cardContent}) => (
  <Card
    sx={{
      boxShadow: "none",
      ".MuiCardHeader-root": {
        pl: 0
      }
    }}
  >
    {!!children && children}
    {
      !children &&
      <>
        <CollapsibleCardHeader {...{title, action, toggle, show}} />
        <Divider />
        <Collapse in={show}>
          <CardContent>
            {cardContent}
          </CardContent>
        </Collapse>
      </>
    }

  </Card>
)

export default CollapsibleCard;
export const CollapsibleCardHeader = ({title, action, toggle, show, sx}) => (
  <CardHeader
    sx={{
      pl: 0,
      pb: 0,
      ".MuiCardHeader-content .MuiButton-root": {
        color: "unset",
        ":hover": {
          backgroundColor: "transparent",
        }
      },
      ".MuiCardHeader-action": {
        my: "auto"
      }, ...sx}}
    action={show && action}
    title={
      <Button
        sx={{textTransform: "capitalize"}}
        startIcon={show ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        onClick={()=> (toggle && toggle(!show))}
      >
        <SectionTitle>
          {title}
        </SectionTitle>
      </Button>
    }
    disableTypography={true}
  />
)


