const oneDayInMilliseconds = 24 * 60 * 60 * 1000

export function usToAWSDate(enUSDateString) { // e.g. 12/30/2022
  if(!enUSDateString) {
    return null
  }
  const dateArr = enUSDateString.split("/")
  const month =  dateArr[0]?.length === 2 ? dateArr[0] : ("0" + dateArr[0])
  const day = dateArr[1]?.length === 2 ? dateArr[1] : ("0" + dateArr[1])
  return `${dateArr[2]}-${month}-${day}`
}

export function awsDateToUS(awsDateString) { // e.g. 2022-12-30
  if(!awsDateString || awsDateString === "0000-01-01") {
    return ""
  }
  return new Date(awsDateString.replace(/-/g, "/") + " 00:00:00").toLocaleDateString("en-US", {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

export function isExpired(dateToCheck) {
  if(typeof dateToCheck === 'string') {
    dateToCheck = getDateFromDateString(dateToCheck)
  }
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return dateToCheck < today;
}

export function isExpiringIn90Days(dateToCheck) {
  return getExpirationDays(dateToCheck) <= 90
}

export function isLaterDate(date1, date2) {
  return (date1 || "") > (date2 || "")
}

export function isLaterDateTime(date1, date2) {
  return (new Date(date1)) > (new Date(date2))
}

export function getDateFromDateString(dateString) {
  if(dateString && dateString !== "0000-01-01") {
    return new Date(dateString.replace(/-/g, "/") + " 00:00:00")
  }
  return null
}

export function getExpirationDays (dateString) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const date = getDateFromDateString(dateString)
  
  if (date => today) {
    return Math.round((date.getTime() - today.getTime()) / oneDayInMilliseconds)
  }
  return null
}


