import React, {useEffect} from "react"
import {useSelector, useDispatch} from "react-redux";
import {Routes, useLocation, useParams} from "react-router-dom";
import {organizationActions, organizationState} from "redux/organizationSlice";

export default function({children}) {
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { selectedAgency, organizations } = useSelector(organizationState)

  useEffect(() => {
    if(organizations?.length) {
      if(!selectedAgency) {
        dispatch(organizationActions.populateOrganizations({
          location,
          params
        }))
      } else if(!selectedAgency.clients) {
        dispatch(organizationActions.getClients(selectedAgency.id))
      }
    }
  }, [selectedAgency, organizations])

  return (
    <Routes>
      {
        children
      }
    </Routes>
  )
}
