import React, {forwardRef} from "react";
import {
  Stack,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import DatePicker from "react-datepicker";

import Modal from "components/shared/Modal"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import us_states from "constants/us_states";
import { getZipCodeDigits } from "utils/formUtils";


import "./project-info-update-modal.scss"

export default function ProjectInfoUpdateModal({
  open,
  onClose,
  loading = false,
  onSave,
  projectData = false,
  onUpdate,
  projectLabel
}) {
  const today = new Date();
  const datePickerAnchor = ({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      label=""
      size="small"
      margin="none"
      fullWidth={true}
      InputProps={{
        readOnly: true,
        endAdornment: <CalendarMonthIcon color="info"/>
      }}
      value={value}
    />
  );

  function onStateFieldKeyPress (event) {
    const { key } = event
    const findState = us_states.find(state => state.startsWith(key.toUpperCase()))

    if (findState) {
      onUpdate("state", event.target.value)
    }
  }

  const ProjectStartDate = forwardRef(datePickerAnchor);
  const ProjectEndDate = forwardRef(datePickerAnchor);
  return (
    <Modal
      className="project-info-update-modal"
      title={`Update ${projectLabel}`}
      open={open}
      content={
        <Stack spacing={2} mt={2}>
          <div>
            <InputLabel>{projectLabel} Name (required)</InputLabel>
            <TextField
              required
              fullWidth
              label=""
              margin="none"
              value={projectData?.name || ""}
              onChange={event=>onUpdate("name", event.target.value)}
            />
          </div>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" sx={{flexGrow: 1}}>
              <InputLabel> Date Start </InputLabel>
              <DatePicker
                selected={projectData?.startDate ? new Date(projectData.startDate) : null}
                customInput={<ProjectStartDate />}
                onChange={date => onUpdate("startDate", date.toLocaleDateString("en-US"))}
                value={projectData?.startDate}
                maxDate={projectData?.endDate ? new Date(projectData.endDate) : null}
              />
            </Stack>
            <Stack direction="column" sx={{flexGrow: 1}}>
              <InputLabel> Date End </InputLabel>
              <DatePicker
                selected={projectData?.endDate ? (new Date(projectData.endDate)) : null}
                customInput={<ProjectEndDate />}
                onChange={date => onUpdate("endDate", date.toLocaleDateString("en-US"))}
                value={projectData?.endDate}
                minDate={projectData?.startDate ? new Date(projectData.startDate) : null}
              />
            </Stack>
          </Stack>
          <div>
            <InputLabel>Description</InputLabel>
            <TextField
              fullWidth
              label=""
              margin="none"
              value={projectData?.description || ""}
              onChange={event=>onUpdate("description", event.target.value)}
            />
          </div>
          <TextField
            label=" Address"
            value={projectData.address}
            name={"address"}
            onChange={event=>onUpdate("address", event.target.value)}
            
          />
           <FormControl sx={{ minWidth: 80 }}>
          <InputLabel id="state-input-label">State</InputLabel>
          <Select
            labelId="state-input-label"
            className="vendor-info-input"
            label="State"
            value={projectData.state || ""}
            name={"state"}
            onChange={event=>onUpdate("state", event.target.value)}
            onKeyPress={onStateFieldKeyPress}
          >
            <MenuItem value={""}>-</MenuItem>
            {
              us_states.map(usState => (
                <MenuItem value={usState} key={usState}>{usState}</MenuItem>
              ))
            }

                </Select>
          </FormControl>
          <TextField
            className="vendor-info-input"
            label="Zip Code"
            value={getZipCodeDigits(projectData.zip)}
            name={"zip"}
            onChange={event=>onUpdate("zip", event.target.value)}
            error={projectData.zip && getZipCodeDigits(projectData.zip).length !== 5}
            helperText={projectData.zip && getZipCodeDigits(projectData.zip).length !== 5 ? "Please enter valid Zip Code" : ""}
          />
        </Stack>
      }
      actions={
        <>
          <Button
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSave}
            disabled={loading || !projectData.name}
          >
            Save
          </Button>
        </>
      }
    />
  )
}
