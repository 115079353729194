import certificate_actions from "./certificate_actions";

export const client_mode = {
  category: "CATEGORY",
  project: "PROJECT"
}

export const client_mode_description = {
  category: (
    <>
      <p>Classify subcontractors by categories.</p>
      <p>Each category can have its own coverage requirements that can be applied to all subcontractors in the category.</p>
    </>
  ),
  project: (
    <>
      <p>Assign subcontractor to projects.</p>
      <p>Each project can have its own coverage requirements that can be applied to all subcontractors in the project.</p>
      <p>Additionally, projects can have start and end dates.</p>
    </>
  )
}

const getClientProjectsHelper = (projects = ['PROJECT ABCDEF', 'PROJECT ABCDEF', 'PROJECT ABCDEF'], projectLabel) => {
  if (projects?.length < 1) {
    return ''
  }

  const projectNames = []
  const returnedProjects = projects.every(e => e === 'PROJECT ABCDEF') ? [`${projectLabel || 'PROJECT'} A`, `${projectLabel || 'PROJECT'} B`, `${projectLabel || 'PROJECT'} C`] : projects

  returnedProjects.forEach((project, index) => {
    projectNames.push(`<span>${ index + 1 }. ${ project }</span><br/>`)
  })

  return (
    `
    <div>Please provide the certificate of insurance for the following:</div> ${
      projectNames.join('')
    }
    `
  ).replace(/>,/g, ">")
}


const signature = `
  <p>Best,

    <b>MANACERT Certificate Manager</b>
  </p>
`;

export const getDefaultTemplates = ({ vendorName, clientName, clientProjects, projectLabel }) => ({
  [certificate_actions.REMINDER_1]: {
    subject: "A Policy Is Expiring In 1 Day",
    content: `Our records indicate that one or more insurance policies you have for your insured, ${vendorName}, are expiring in 1 day.`,
    signature
  },
  [certificate_actions.REMINDER_7]: {
    subject: "A Policy Is Expiring In 7 Days",
    content: `Our records indicate that one or more insurance policies you have for your insured, ${vendorName}, are expiring in 7 days.`,
    signature
  },
  [certificate_actions.REMINDER_14]: {
    subject: "A Policy Is Expiring In 14 Days",
    content: `Our records indicate that one or more insurance policies you have for your insured, ${vendorName}, are expiring in 14 days.`,
    signature
  },
  [certificate_actions.SHARE_CERTIFICATE]: {
    subject: `${clientName} has shared a COI with you`,
    content: `${vendorName} has shared a certificate with you.`,
    signature
  },
  [certificate_actions.CERTIFICATE_EXPIRATION]: {
    subject: "A Policy Has Expired",
    content: `Our records indicate that one or more insurance policies you have for your insured, ${vendorName}, has expired.`,
    signature
  },
  [certificate_actions.REQUEST]: {
    subject: "Certificate Upload Request",
    projectList: getClientProjectsHelper(clientProjects, projectLabel),
    content: `${clientName} has partnered with MANACERT to confirm that you meet our insurance requirements in reference to these ${projectLabel}s, visible once you click the link below.

    *Please work with your insurance agent or broker to supply an ACORD 25 COI (Certificate of insurance) with the listed coverage requirements.
    *You can forward this email to your insurance agent or broker, where they can click on the link to view the coverage requirements and supply the ACORD 25 COI.

    A timely response to this request is necessary to reduce the possibility of any ${projectLabel} delays. Your cooperation is appreciated. For any questions, please get in touch with your ${clientName} representative.
    `,
    signature: `
    <p>Best,
    
      <b>${clientName}</b>
    </p>
    `
  }
})

export const classification_id = {
  CATEGORY: "categoryID",
  PROJECT: "projectID",
}

export const getVendorDocumentUploadTemplate = (clientName, vendorName, projectName, insuranceAgentName, isSubcontractorUploading) => ({
  CertificateUploadNotification: {
    subject: `A certificate was uploaded by subcontractor  "${vendorName}"  for ${clientName}`,
    greeting: `Dear ${clientName}`,
    content: `A certificate was uploaded by ${isSubcontractorUploading ? `subcontractor "${insuranceAgentName}"` : `the insurance agent "${insuranceAgentName}" for subcontractor "${vendorName}"`} for the following project:

    • ${projectName}
    
    Please review promptly to ensure the subcontractor meets all of your insurance requirements.`,
    signature: `
    Best,
      <b>MANACERT Team</b>`
  }
})