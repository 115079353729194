export const SystemAdmin = "SystemAdmin";
export const AgencyAdmin = "AgencyAdmin";
export const ClientAdmin = "ClientAdmin";
export const ClientViewer = "ClientViewer";
export const AddUser = "AddUser";

const GROUPS = {
  SystemAdmin,
  AgencyAdmin,
  ClientAdmin,
  ClientViewer,
  AddUser,
}

export const GROUPS_CAN_ADD_USER = [
  SystemAdmin,
  AgencyAdmin,
  ClientAdmin,
]

export default GROUPS;
