export function findInStringsIgnoreCase(searchInput = "", fieldList = []) {
  if (fieldList?.length && searchInput) {
    const searchInputLower = String(searchInput).toLowerCase();
    return fieldList.find((fieldValue) => !!fieldValue && String(fieldValue).toLowerCase().includes(searchInputLower));
  }
  return false;
}

export function formatID(idString) {
  const idSubstringsArray = idString.split("-");
  return idSubstringsArray[idSubstringsArray.length - 1]?.substring(8);
}
