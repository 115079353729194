/* eslint-disable */

export const getUserAndOrganization = /* GraphQL */ `
    query UserByEmail(
        $email: AWSEmail!
        $sortDirection: ModelSortDirection
        $filter: ModelUSERFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userByEmail(
            email: $email
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                email
                firstName
                lastName
                role
                organizationID
                status
                userOrganization {
                    themeColor
                    isAgency
                    id
                    vendorLabel
                    projectLabel
                    clientLabel
                    certificateHolder
                    certificateHolderAddress1
                    certificateHolderAddress2
                    certificateHolderCity
                    certificateHolderState
                    certificateHolderZip
                    useDefaultProject
                    usePrimaryNotificationEmailAddress
                    primaryNotificationEmailAddress
                    tableRowsPerPage
                    logo
                    name
                    type
                }
            }
        }
    }
`;

export const createUSER = /* GraphQL */ `
    mutation CreateUSER(
        $input: CreateUSERInput!
        $condition: ModelUSERConditionInput
    ) {
        createUSER(input: $input, condition: $condition) {
            id
            firstName
            lastName
            email
            organizationID
            role
            status
        }
    }
`;

export const updateUSER = /* GraphQL */ `
    mutation UpdateUSER(
        $input: UpdateUSERInput!
        $email: String
    ) {
        updateUSER(input: $input, condition: {email: {eq: $email}}) {
            id
            firstName
            lastName
            email
            organizationID
            role
            status
        }
    }
`;
