import { API, Auth } from "aws-amplify";
import GROUPS, { GROUPS_CAN_ADD_USER } from "constants/cognito_groups";
import { createUSER, updateUSER } from "graphql/user";

export async function adminDisableUser(user) {
  await API.post("AdminQueries", "/adminDisableUser", {
    body: {
      "username" : user.email
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  })
}

export async function adminDeleteUser(user) {
  await API.post("AdminQueries", "/adminDeleteUser", {
    body: {
      "username" : user.email
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  })
}

export async function enableUser(user) {
  await API.post("AdminQueries", "/enableUser", {
    body: {
      "username" : user.email
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  })
}

export async function signUserUp(user, group = GROUPS.ClientAdmin, organizationID, success) {
  const { email } = user

  await API.post("AdminQueries", "/inviteUser", {
    body: {
      "username" : email
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  }).then(
    res => {
      saveUserCognitoGroups(email, group)
      addUserToDB({...user, organizationID}, success)
    },
    err => {
      if(err?.response?.data?.message === "An account with the given email already exists.") {
        saveUserCognitoGroups(email, group)
        addUserToDB({...user, organizationID}, success)
      }
    },
  )
}

export function saveUserCognitoGroups(email, group) {
  const canAddUser = GROUPS_CAN_ADD_USER.includes(group)
  addUserToGroup(email, group, () => (canAddUser && addUserToGroup(email, GROUPS.AddUser)))
}

async function addUserToGroup(username, group, success) {
  API.post("AdminQueries", "/addUserToGroup", {
    body: {
      "username" : username,
      "groupname": group
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  }).then(
    res => success && success(res),
    err => console.log("err", err),
  )
}

export async function getGroupsForUser(email) {
  const groups = await API.get("AdminQueries", "/listGroupsForUser?username=" + email, {
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  })

  return groups?.Groups?.map(group => group.GroupName)?.filter(group => group !== GROUPS.AddUser)
    || []
}

export async function removeUserFromGroups(email, groups=[], shouldRemoveFromAddUser=true) {
  let isInAddUser = false
  for(let i=0; i<groups.length; i++) {
    !isInAddUser && (isInAddUser = GROUPS_CAN_ADD_USER.includes(groups[i]))
    await removeUserFromGroup(email, groups[i])
  }

  if(shouldRemoveFromAddUser && isInAddUser) {
    await removeUserFromGroup(email, GROUPS.AddUser)
  }
}

async function removeUserFromGroup(email, group) {
  await API.post("AdminQueries", "/removeUserFromGroup", {
    body: {
      "username" : email,
      "groupname": group
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  })
}

async function getCognitoUser(username, success) {
  API.get("AdminQueries", "/getUser" + `?username=${username}`, {
    headers: {
      'Content-Type' : 'application/json',
      Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  }).then(
    res => success && success(res),
    err => console.log("err", err),
  )
}

function addUserToDB(user, success) {
  const isCreate = !user.id
  API.graphql({
    query: isCreate ? createUSER : updateUSER,
    variables: {
      input: {
        ...user,
        status: "ACTIVE"
      },
      ...(isCreate ? {} : { email:user.email })
    }
  }).then(result => success && success(result?.data?.[isCreate ? "createUSER" : "updateUSER"])

  ).catch(error => console.log("error", error))
}
