import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { API } from "aws-amplify"
import { getOrganizationUsers } from "graphql/organization";
import { getUserAndOrganization, updateUSER } from "graphql/user";
import {
  getGroupsForUser,
  removeUserFromGroups,
  saveUserCognitoGroups,
  enableUser,
  adminDisableUser,
  adminDeleteUser
} from "services/auth"
import GROUPS, {GROUPS_CAN_ADD_USER} from "../constants/cognito_groups";

const adapter = createEntityAdapter()
const initialState = adapter.getInitialState({
  status: "idle",
  loggedInUser: null,
  users: {}
})

const onUserInfoUpdated = (state, action) => {
  const newOrganizationUsers = state.users && state.users[action.payload.organizationId]?.map(user => {
      if(user.id === action.payload.id) {
        return {
          ...user,
          ...action.payload.user
        }
      }
      return user
    })
  state.users = {
    ...state.users,
    [action.payload.organizationId]: newOrganizationUsers
  }

  if(action.payload?.id === state.loggedInUser?.id) {
    state.loggedInUser = {
      ...state.loggedInUser,
      ...action.payload.user
    }
  }
  state.status = 'success'
  state.error = null
}

const actions = {
  fetchOrganizationUsers: createAsyncThunk('user/fetchOrganizationUsers', async (id) => {
    const response = await API.graphql({
      query: getOrganizationUsers,
      variables: {
        id
      }
    })
    return {
      id,
      users: response?.data?.getORGANIZATION?.organizationUsers?.items
    }
  }),
  updateUserInformation: createAsyncThunk("user/updateUserInformation", async (
    { id, email, input, organizationId, role },
  ) => {

    const response = await API.graphql({
      query: updateUSER,
      variables: {
        email,
        input,
      }
    })
    
    const updatedRole = response?.data?.updateUSER?.role
    const shouldRemoveFromAddUser = !GROUPS_CAN_ADD_USER.includes(updatedRole)
    const currentRoles = await getGroupsForUser(email)
    const groupsToRemove = currentRoles?.filter(currentRole => updatedRole !== currentRole)
    if(groupsToRemove?.length) {
      await removeUserFromGroups(email, groupsToRemove, shouldRemoveFromAddUser)
    }
    if(!currentRoles || !currentRoles.includes(updatedRole)) {
      await saveUserCognitoGroups(email, updatedRole)
    }
    return {
      id,
      user: response?.data?.updateUSER,
      success: true,
      organizationId
    }
  }),
  updateUserDetails: createAsyncThunk("user/updateUserDetails", async (
    { id, email, input, organizationId, role },
  ) => {

    const response = await API.graphql({
      query: updateUSER,
      variables: {
        email,
        input,
      }
    })

    return {
      id,
      user: response?.data?.updateUSER,
      success: true,
      organizationId
    }
  }),
  getUser: createAsyncThunk("user/getUser", async (email) => {
    const response = await API.graphql({
      query: getUserAndOrganization,
      variables: {
        email,
      }
    })
    return {
      user: response?.data?.userByEmail?.items?.length ? response.data.userByEmail.items[0] : null,
      success: true
    }
  }),
  enableUser: createAsyncThunk("user/enableUser", async ({user, organizationId}) => {
    await enableUser(user)
    const response = await API.graphql({
      query: updateUSER,
      variables: {
        email: user.email,
        input: {
          id: user.id,
          status: "ACTIVE"
        }
      }
    })
    return {
      id: user.id,
      user: response?.data?.updateUSER,
      success: true,
      organizationId
    }
  }),
  disableUser: createAsyncThunk("user/disableUser", async ({user, organizationId}) => {
    await adminDisableUser(user)
    const response = await API.graphql({
      query: updateUSER,
      variables: {
        email: user.email,
        input: {
          id: user.id,
          status: "DISABLED"
        }
      }
    })
    return {
      id: user.id,
      user: response?.data?.updateUSER,
      success: true,
      organizationId
    }
  }),
  deleteUser: createAsyncThunk("user/deleteUser", async ({user, organizationId}) => {
    await adminDeleteUser(user)
    const response = await API.graphql({
      query: updateUSER,
      variables: {
        email: user.email,
        input: {
          id: user.id,
          status: "DELETED"
        }
      }
    })
    return {
      id: user.id,
      user: response?.data?.updateUSER,
      success: true,
      organizationId
    }
  }),
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state = initialState
    },
    setUserGroups: (state, action) => {
      state.userGroups = action.payload
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload.loggedInUser
    }
  },
  extraReducers: builder => {
    builder
      // get organization users
      .addCase(actions.fetchOrganizationUsers.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(actions.fetchOrganizationUsers.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          [action.payload.id]: action.payload.users
        }
        state.status = 'success'
        state.error = null
      })
      .addCase(actions.fetchOrganizationUsers.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      // update user information
      .addCase(actions.updateUserInformation.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(actions.updateUserInformation.fulfilled, onUserInfoUpdated)
      .addCase(actions.updateUserInformation.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      // update user details
      .addCase(actions.updateUserDetails.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(actions.updateUserDetails.fulfilled, onUserInfoUpdated)
      .addCase(actions.updateUserDetails.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      // enableUser
      .addCase(actions.enableUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(actions.enableUser.fulfilled, onUserInfoUpdated)
      .addCase(actions.enableUser.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      // disableUser
      .addCase(actions.disableUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(actions.disableUser.fulfilled, onUserInfoUpdated)
      .addCase(actions.disableUser.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
      // delete user
      .addCase(actions.deleteUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(actions.deleteUser.fulfilled, (state, action) => {
        state.status = 'success'
        state.error = null
      })
      .addCase(actions.deleteUser.rejected, (state, action) => {
        state.error = action.payload
        state.status = 'failure'
      })
  }
})

export const userState = state => state.user
export const loggedInUserState = state => ({
  loggedInUser: state.user.loggedInUser,
  userGroups: state.user.userGroups,
  isAdmin: (
    !!state.user.userGroups?.length &&
    (
      state.user.userGroups.includes(GROUPS.SystemAdmin) ||
      state.user.userGroups.includes(GROUPS.ClientAdmin) ||
      state.user.userGroups.includes(GROUPS.AgencyAdmin)
    )
  )
})

export const userActions = {
  ...userSlice.actions,
  ...actions,
}
export default userSlice.reducer
