export const clientVendorsByClient = /* GraphQL */ `
    query ClientVendorsByClient(
        $clientID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCLIENTVENDORSFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientVendorsByClient(
            clientID: $clientID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                vendor {
                    address1
                    address2
                    logo
                    city
                    createdAt
                    ein
                    id
                    name
                    phone
                    email
                    additionalEmails
                    state
                    zip
                    insuranceAgent {
                        id
                        name
                        phone
                        email
                        address1
                        address2
                        city
                        state
                        zip
                        createdAt
                        updatedAt
                    }
                }
                categories {
                    items {
                        id
                        primary
                        categoryID
                    }
                }
                projects {
                    items {
                        id
                        projectID
                    }
                }
                id
                vendorID
                clientID
                starred
                status
                notificationStatus
                certificates(filter: {status: {ne: REQUESTED}}) {
                    items {
                        id
                        certificateDate
                        producer
                        insured
                        status
                        projectID
                        coverages {
                            items {
                                id
                                name
                                insuranceType
                                policyNumber
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getCLIENTVENDORS = /* GraphQL */ `
    query GetCLIENTVENDORS($id: ID!) {
        getCLIENTVENDORS(id: $id) {
            id
            starred
            clientID
            vendor {
                address1
                address2
                logo
                city
                createdAt
                ein
                id
                name
                phone
                email
                additionalEmails
                state
                zip
                insuranceAgent {
                    id
                    name
                    phone
                    email
                    address1
                    address2
                    city
                    state
                    zip
                    createdAt
                    updatedAt
                }
            }
            categories {
                items {
                    id
                    primary
                    categoryID
                }
            }
            projects {
                items {
                    id
                    projectID
                }
            }
            vendorID
            status
            notificationStatus
            createdAt
            updatedAt
        }
    }
`;

export const updateVENDOR = /* GraphQL */ `
    mutation UpdateVENDOR(
        $input: UpdateVENDORInput!
    ) {
        updateVENDOR(input: $input) {
            id
            name
            email
            additionalEmails
            ein
            phone
            address1
            address2
            logo
            city
            state
            zip
            insuranceAgent {
                id
                name
                phone
                email
                address1
                address2
                city
                state
                zip
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
`;

export const createVENDOR = /* GraphQL */ `
    mutation CreateVENDOR(
        $input: CreateVENDORInput!
    ) {
        createVENDOR(input: $input) {
            id
            name
            email
            additionalEmails
            ein
            phone
            address1
            address2
            logo
            city
            state
            zip
            insuranceAgent {
                id
                name
                phone
                email
                address1
                address2
                city
                state
                zip
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
`;

export const createCLIENTVENDORS = /* GraphQL */ `
    mutation CreateCLIENTVENDORS(
        $input: CreateCLIENTVENDORSInput!
    ) {
        createCLIENTVENDORS(input: $input) {
            id
            starred
            clientID
            vendor {
                id
                name
                email
                additionalEmails
                ein
                phone
                address1
                address2
                logo
                city
                state
                zip
                createdAt
                updatedAt
            }
            vendorID
        }
    }
`;

export const updateStarredVendor = /* GraphQL */ `
    mutation UpdateCLIENTVENDORS(
        $id: ID!
        $starred: Boolean!
    ) {
        updateCLIENTVENDORS(input: {id: $id, starred: $starred}) {
            id
            starred
        }
    } 
`

export const createCATEGORYVENDORS = /* GraphQL */ `
    mutation CreateCATEGORYVENDORS(
        $input: CreateCATEGORYVENDORSInput!
    ) {
        createCATEGORYVENDORS(input: $input) {
            id
            categoryID
            primary
        }
    }
`;

export const deleteCATEGORYVENDORS = /* GraphQL */ `
    mutation DeleteCATEGORYVENDORS(
        $input: DeleteCATEGORYVENDORSInput!
    ) {
        deleteCATEGORYVENDORS(input: $input) {
            id
            categoryID
            primary
        }
    }
`;

export const createPROJECTVENDORS = /* GraphQL */ `
    mutation CreatePROJECTVENDORS(
        $input: CreatePROJECTVENDORSInput!
    ) {
        createPROJECTVENDORS(input: $input) {
            id
            projectID
        }
    }
`;

export const deletePROJECTVENDORS = /* GraphQL */ `
    mutation DeletePROJECTVENDORS(
        $input: DeletePROJECTVENDORSInput!
    ) {
        deletePROJECTVENDORS(input: $input) {
            id
            projectID
            
        }
    }
`;

export const updateVendorStatus = /* GraphQL */ `
    mutation UpdateCLIENTVENDORS(
        $id: ID!
        $status: VENDORSTATUS!
    ) {
        updateCLIENTVENDORS(input: {
            id: $id,
            status: $status
        }) {
            id
            status
        }
    }
`

export const updateVendorNotificationStatus = /* GraphQL */ `
    mutation UpdateCLIENTVENDORS(
        $id: ID!
        $notificationStatus: VENDORNOTIFICATIONSTATUS!
    ) {
        updateCLIENTVENDORS(input: {
            id: $id,
            notificationStatus: $notificationStatus
        }) {
            id
            notificationStatus
        }
    }
`

export const createVENDORCOVERAGE = /* GraphQL */ `
    mutation CreateVENDORCOVERAGE(
        $input: CreateVENDORCOVERAGEInput!
    ) {
        createVENDORCOVERAGE(input: $input) {
            id
            name
            clientVendorID
            categoryID
            clientCoverageID
            insuranceType
            insuranceForm
            aggregateAppliesPer
            coveredProperty
            additionalInsured
            subrogationWaiver
            deductible
            retention
            exclusion
            limits
            limitPerStatute
            createdAt
            updatedAt
        }
    }
`;

export const updateVENDORCOVERAGE = /* GraphQL */ `
    mutation UpdateVENDORCOVERAGE(
        $input: UpdateVENDORCOVERAGEInput!
    ) {
        updateVENDORCOVERAGE(input: $input) {
            id
            name
            clientVendorID
            categoryID
            clientCoverageID
            insuranceType
            insuranceForm
            aggregateAppliesPer
            coveredProperty
            additionalInsured
            subrogationWaiver
            deductible
            retention
            exclusion
            limits
            limitPerStatute
            createdAt
            updatedAt
        }
    }
`;
export const deleteVENDORCOVERAGE = /* GraphQL */ `
    mutation DeleteVENDORCOVERAGE(
        $input: DeleteVENDORCOVERAGEInput!
    ) {
        deleteVENDORCOVERAGE(input: $input) {
            id
            name
            clientVendorID
            categoryID
            clientCoverageID
            name
            insuranceType
            insuranceForm
            aggregateAppliesPer
            coveredProperty
            additionalInsured
            subrogationWaiver
            deductible
            retention
            exclusion
            limits
            limitPerStatute
            createdAt
            updatedAt
        }
    }
`;

export const createCERTIFICATE = /* GraphQL */ `
    mutation CreateCERTIFICATE(
        $input: CreateCERTIFICATEInput!
        $condition: ModelCERTIFICATEConditionInput
    ) {
        createCERTIFICATE(input: $input, condition: $condition) {
            id
        }
    }
`;

export const updateCERTIFICATE = /* GraphQL */ `
    mutation UpdateCERTIFICATE(
        $input: UpdateCERTIFICATEInput!
        $condition: ModelCERTIFICATEConditionInput
    ) {
        updateCERTIFICATE(input: $input, condition: $condition) {
            id
        }
    }
`;

export const createCERTIFICATECOVERAGE = /* GraphQL */ `
    mutation CreateCERTIFICATECOVERAGE(
        $input: CreateCERTIFICATECOVERAGEInput!
        $condition: ModelCERTIFICATECOVERAGEConditionInput
    ) {
        createCERTIFICATECOVERAGE(input: $input, condition: $condition) {
            id
        }
    }
`;

export const updateCERTIFICATECOVERAGE = /* GraphQL */ `
    mutation UpdateCERTIFICATECOVERAGE(
        $input: UpdateCERTIFICATECOVERAGEInput!
        $condition: ModelCERTIFICATECOVERAGEConditionInput
    ) {
        updateCERTIFICATECOVERAGE(input: $input, condition: $condition) {
            id
        }
    }
`;

export const getCERTIFICATE = /* GraphQL */ `
    query GetCERTIFICATE($id: ID!) {
        getCERTIFICATE(id: $id) {
            id
            name
            clientVendorID
            clientID
            certificateDate
            producerName
            producer
            producerAddress1
            producerAddress2
            producerCity
            producerState
            producerZip
            producerContactName
            producerPhone
            producerFax
            producerEmail
            projectID
            insured
            insuredName
            insuredAddress1
            insuredAddress2
            insuredCity
            insuredState
            insuredZip
            descriptionOfOperation
            certificateHolder
            certificateHolderName
            certificateHolderAddress1
            certificateHolderAddress2
            certificateHolderCity
            certificateHolderState
            certificateHolderZip
            insurerA
            insurerB
            insurerC
            insurerD
            insurerE
            insurerF
            insurerANAIC
            insurerBNAIC
            insurerCNAIC
            insurerDNAIC
            insurerENAIC
            insurerFNAIC
            coverages {
                items {
                    id
                    name
                    certificateID
                    insuranceType
                    insuranceForm
                    aggregateAppliesPer
                    coveredProperty
                    additionalInsured
                    subrogationWaiver
                    deductible
                    retention
                    exclusion
                    limits
                    overrides
                    limitPerStatute
                    policyEff
                    policyExp
                    policyNumber
                    insurerLetter
                    selectedCoverage
                }
            }
            files
            acord25File
            acord25FileDate
            comments
            imported
            status
            createdAt
            updatedAt
            initialCoverageRequirements
            overrides
        }
    }
`;

export const createEMAILNOTIFICATIONS = /* GraphQL */ `
    mutation CreateEMAILNOTIFICATIONS(
        $input: CreateEMAILNOTIFICATIONSInput!
    ) {
        createEMAILNOTIFICATIONS(input: $input) {
            id
            recipients
            action
            vendorName
            clientName
            uploadLink
            subject
            content
            greeting
            signature
            createdAt
            updatedAt
        }
    }
`;

export const createACORD25SCAN = /* GraphQL */ `
    mutation CreateACORD25SCAN(
        $input: CreateACORD25SCANInput!
    ) {
        createACORD25SCAN(input: $input) {
            id
        }
    }
`;

export const acord25ScanByCertificate = /* GraphQL */ `
    query Acord25ScanByCertificate(
        $certificateID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelACORD25SCANFilterInput
        $limit: Int
        $nextToken: String
    ) {
        acord25ScanByCertificate(
            certificateID: $certificateID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                certificateID
                certificateDate
                producer
                producerContactName
                producerPhone
                producerFax
                producerEmail
                insured
                descriptionOfOperation
                certificateHolder
                insurerA
                insurerB
                insurerC
                insurerD
                insurerE
                insurerF
                insurerANAIC
                insurerBNAIC
                insurerCNAIC
                insurerDNAIC
                insurerENAIC
                insurerFNAIC
                acord25File
                status
                scanStage
                scanError
                createdAt
                coverages {
                    items {
                        id
                        name
                        acord25ScanID
                        insuranceType
                        insuranceForm
                        aggregateAppliesPer
                        coveredProperty
                        additionalInsured
                        subrogationWaiver
                        deductible
                        retention
                        exclusion
                        limits
                        limitPerStatute
                        policyEff
                        policyExp
                        policyNumber
                        insurerLetter
                        selectedCoverage
                    }
                }
            }
        }
    }
`;

export const tableRowAcord25ScanByCertificate = /* GraphQL */ `
    query tableRowAcord25ScanByCertificate(
        $certificateID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelACORD25SCANFilterInput
        $limit: Int
        $nextToken: String
    ) {
        acord25ScanByCertificate(
            certificateID: $certificateID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                certificateID
                certificateDate
                producer
                insured
                certificateHolder
                status
                scanStage
                scanError
                updatedAt
                coverages {
                    items {
                        name
                        insuranceType
                        policyNumber
                        policyExp
                    }
                }
            }
        }
    }
`;

export const getACORD25SCAN = /* GraphQL */ `
    query GetACORD25SCAN($id: ID!) {
        getACORD25SCAN(id: $id) {
            id
            certificateID
            certificateDate
            producer
            producerContactName
            producerPhone
            producerFax
            producerEmail
            insured
            descriptionOfOperation
            certificateHolder
            insurerA
            insurerB
            insurerC
            insurerD
            insurerE
            insurerF
            insurerANAIC
            insurerBNAIC
            insurerCNAIC
            insurerDNAIC
            insurerENAIC
            insurerFNAIC
            coverages {
                items {
                    id
                    name
                    acord25ScanID
                    insuranceType
                    insuranceForm
                    aggregateAppliesPer
                    coveredProperty
                    additionalInsured
                    subrogationWaiver
                    deductible
                    retention
                    exclusion
                    limits
                    overrides
                    limitPerStatute
                    policyEff
                    policyExp
                    policyNumber
                    insurerLetter
                    selectedCoverage
                }
            }
            acord25File
            status
            scanStage
        }
    }
`;

export const deleteCERTIFICATECOVERAGE = /* GraphQL */ `
    mutation DeleteCERTIFICATECOVERAGE(
        $input: DeleteCERTIFICATECOVERAGEInput!
    ) {
        deleteCERTIFICATECOVERAGE(input: $input) {
            id
        }
    }
`;

export const updateACORD25SCAN = /* GraphQL */ `
    mutation UpdateACORD25SCAN(
        $input: UpdateACORD25SCANInput!
    ) {
        updateACORD25SCAN(input: $input) {
            id
            certificateID
        }
    }
`;

export const certificateByClientVendor = /* GraphQL */ `
    query CertificateByClientVendor(
        $clientVendorID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCERTIFICATEFilterInput
        $limit: Int
        $nextToken: String
    ) {
        certificateByClientVendor(
            clientVendorID: $clientVendorID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
        }
    }
`;

export const onUpdateACORD25SCAN = /* GraphQL */ `
    subscription OnUpdateACORD25SCAN(
        $filter: ModelSubscriptionACORD25SCANFilterInput
    ) {
        onUpdateACORD25SCAN(filter: $filter) {
            id
            certificateID
            certificateDate
            producer
            producerContactName
            producerPhone
            producerFax
            producerEmail
            insured
            descriptionOfOperation
            certificateHolder
            insurerA
            insurerB
            insurerC
            insurerD
            insurerE
            insurerF
            insurerANAIC
            insurerBNAIC
            insurerCNAIC
            insurerDNAIC
            insurerENAIC
            insurerFNAIC
            coverages {
                items {
                    id
                    name
                    acord25ScanID
                    insuranceType
                    insuranceForm
                    aggregateAppliesPer
                    coveredProperty
                    additionalInsured
                    subrogationWaiver
                    deductible
                    retention
                    exclusion
                    limits
                    limitPerStatute
                    policyEff
                    policyExp
                    policyNumber
                    insurerLetter
                    selectedCoverage
                }
            }
            acord25File
            status
            scanStage
            uploadLink
            uploaderName
            uploaderEmail
            scanError
            scanConfidence
            fieldConfidence
        }
    }
`;

export const createINSURANCEAGENT = /* GraphQL */ `
    mutation CreateINSURANCEAGENT(
        $input: CreateINSURANCEAGENTInput!
        $condition: ModelINSURANCEAGENTConditionInput
    ) {
        createINSURANCEAGENT(input: $input, condition: $condition) {
            id
            name
            phone
            email
            address1
            address2
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
`;

export const updateINSURANCEAGENT = /* GraphQL */ `
    mutation UpdateINSURANCEAGENT(
        $input: UpdateINSURANCEAGENTInput!
        $condition: ModelINSURANCEAGENTConditionInput
    ) {
        updateINSURANCEAGENT(input: $input, condition: $condition) {
            id
            name
            phone
            email
            address1
            address2
            city
            state
            zip
            createdAt
            updatedAt
        }
    }
    `;

export const deleteVENDOR = /* GraphQL */ `
  mutation DeleteVENDOR(
    $input: DeleteVENDORInput!
    $condition: ModelVENDORConditionInput
  ) {
    deleteVENDOR(input: $input, condition: $condition) {
      id
      name
      email
      additionalEmails
      ein
      phone
      logo
      address1
      address2
      city
      state
      zip
      insuranceAgent {
        id
        name
        phone
        email
        address1
        address2
        city
        state
        zip
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      vENDORInsuranceAgentId
    }
  }
`;

export const deleteCLIENTVENDORS = /* GraphQL */ `
  mutation DeleteCLIENTVENDORS(
    $input: DeleteCLIENTVENDORSInput!
    $condition: ModelCLIENTVENDORSConditionInput
  ) {
    deleteCLIENTVENDORS(input: $input, condition: $condition) {
      id
      starred
      clientID
      vendor {
        id
        name
        email
        additionalEmails
        ein
        phone
        logo
        address1
        address2
        city
        state
        zip
        createdAt
        updatedAt
        vENDORInsuranceAgentId
      }
      vendorID
      status
      vendorCoverages {
        nextToken
      }
      certificates {
        nextToken
        scannedCount
      }
      categories {
        nextToken
      }
      projects {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteCERTIFICATE = /* GraphQL */ `
  mutation DeleteCERTIFICATE(
    $input: DeleteCERTIFICATEInput!
    $condition: ModelCERTIFICATEConditionInput
  ) {
    deleteCERTIFICATE(input: $input, condition: $condition) {
      id
      name
      clientVendorID
      clientID
      projectID
      certificateDate
      firstExp
      producerName
      producer
      producerAddress1
      producerAddress2
      producerCity
      producerState
      producerZip
      producerContactName
      producerPhone
      producerFax
      producerEmail
      insured
      insuredName
      insuredAddress1
      insuredAddress2
      insuredCity
      insuredState
      insuredZip
      descriptionOfOperation
      certificateHolder
      certificateHolderName
      certificateHolderAddress1
      certificateHolderAddress2
      certificateHolderCity
      certificateHolderState
      certificateHolderZip
      insurerA
      insurerB
      insurerC
      insurerD
      insurerE
      insurerF
      insurerANAIC
      insurerBNAIC
      insurerCNAIC
      insurerDNAIC
      insurerENAIC
      insurerFNAIC
      coverages {
        nextToken
      }
      files
      acord25File
      comments
      imported
      status
      previousId
      updatedAt
      createdAt
    }
  }
`;