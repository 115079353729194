import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { Image } from "semantic-ui-react";
import Modal from "components/shared/Modal";

export default function PrivacyPolicy({ onConfirm = () => {} }) {
  return (
    <Modal
      maxWidth="sm"
      open={true}
      content={
        <Box display="flex" flexDirection="column" alignItems="center">
          <Image style={{ margin: "2em" }} size="medium" src={window.location.origin + "/app-logo-h110.png"} />
          <Typography variant="h6" as="span" pl={2}>
            To continue, you are agreeing to the{" "}
            <Link className="clickable" href="https://www.manacert.com/privacy-policy" target="_blank" underline="false">
              MANACERT Privacy Policy
            </Link>
          </Typography>
        </Box>
      }
      actions={
        <Button variant="contained" onClick={onConfirm} sx={{margin: "2em"}}>
          CONTINUE
        </Button>
      }
    />
  );
}
