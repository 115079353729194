import React, {useEffect, useMemo, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {certificatesState, clientActions} from "redux/clientSlice"
import {vendorActions, vendorState} from "redux/vendorSlice";
import {organizationState} from "redux/organizationSlice";

import CertificateTable from "components/CertificateTable";
import {getNavigationPath, useNavPath, NAVIGATED_FROM} from "constants/routes";
import {client_mode} from "constants/client_properties";
import { getTableRowsPerPage } from "constants/organization_types";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userOrgType, selectedClient } = useSelector(organizationState)
  const defaultTableRowsPerPage = getTableRowsPerPage(selectedClient);
  const {certificates, certificatesCount, paginatedCertificates} = useSelector(certificatesState)
  const isProjectBased = selectedClient?.mode === client_mode.project
  const projects = useSelector(state => state.client.projects[selectedClient?.clientID]) || []
  const { vendors } = useSelector(vendorState)
  const NAV_PATH = useNavPath(userOrgType)
  const [initialLoading, setInitialLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nextTokens, setNextTokens] = useState([null])
  const serverSide = true;

  useEffect(() => {
    if(selectedClient?.clientID) {
      loadInitialData()
    }
  }, [selectedClient?.clientID])

  async function loadInitialData() {
    setInitialLoading(true)
    if(!vendors) {
      await dispatch(vendorActions.fetchVendors(selectedClient.clientID))
    }
    const initialPaginatedCerts = await dispatch(clientActions.getPaginatedClientCertificates({
      clientID: selectedClient?.clientID,
      sortBy: "lastModifiedDate",
      sortAsc: false,
      nextToken: null,
      rowsPerPage: serverSide ? defaultTableRowsPerPage : undefined,
    }))
    setTokenNextPage(initialPaginatedCerts?.payload?.nextToken,1)
    // only get all certs and count on the initial load
    dispatch(clientActions.getAllClientCertificates(selectedClient.clientID))
    setInitialLoading(false)
  }

  const setTokenNextPage = (nextToken, page) => {
    const newNextTokens = [...nextTokens]
    if (nextToken) {
      newNextTokens[page] = nextToken || null
    }
    setNextTokens(newNextTokens)
  }

  async function onFetchData({
    sortBy,
    sortAsc,
    page,
    rowsPerPage,
    status,
    statusFilter,
  }) {
    if(page === 0) {
      await setNextTokens([null])
    }
    if(selectedClient?.clientID) {
      setLoading(true)
      const certResult = await dispatch(clientActions.getPaginatedClientCertificates({
        clientID: selectedClient?.clientID,
        sortBy,
        sortAsc,
        nextToken: nextTokens[page],
        rowsPerPage,
        status,
        statusFilter
      }))
      setTokenNextPage(certResult?.payload?.nextToken, page+1)
      setLoading(false)
    }
  }

  const projectMap = useMemo(() => {
    const mapToReturn = {}
    if(isProjectBased && projects?.length) {
      projects.forEach(project => {
        mapToReturn[project.id] = project.name
      })
    }
    return mapToReturn
  }, [projects, isProjectBased])

  function navToCertificate(id) {
    const certificatePath = getNavigationPath(NAV_PATH.CLIENT_CERTIFICATE_DETAILS, {
      clientId: selectedClient.id,
      vendorId: NAVIGATED_FROM.CERTIFICATES,
      certificateId: id
    })
    navigate(certificatePath, {
      state: {
        referrer: location.pathname
      }
    })
  }
  const columns = [
    "id",
    "status",
    "vendor",
    "subcontractorStatus",
    "producer",
    "certificateDate",
    "lastModifiedDate",
    "createdDate",
    "policyExp"
  ]
  if(isProjectBased) {
    columns.push("project")
  }
  columns.push("scan")

  return (
    <CertificateTable
      loading={initialLoading || loading}
      certificates={[...(certificates || [])]}
      paginatedCertificates={paginatedCertificates}
      columns={columns}
      onView={navToCertificate}
      projects={projectMap}
      serverSide={serverSide}
      onFetchData={onFetchData}
      certificatesCount={certificatesCount}
      nextTokens={nextTokens}
      setNextTokens={setNextTokens}
      subcontractors={vendors}
    />
  )
}
