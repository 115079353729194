import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { organizationState } from "redux/organizationSlice";
import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Checkbox,
  Button
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import {Dropdown, Label, Menu, Popup, Placeholder,} from "semantic-ui-react";
import { client_mode } from "constants/client_properties";
import { getProjectLabel, getVendorLabel } from "constants/organization_types"

import TableLoadingIndicator from "components/shared/TableLoadingIndicator";
import StatusIndicator from "components/shared/StatusIndicator";
import SortableTHeadCell from "components/shared/SortableTHeadCell";
import {formatPhoneNumber} from "utils/formUtils"
import {findInStringsIgnoreCase} from "utils/stringUtils"

const columnConfig = {
  requested: {label: '',noSort: true,align: "center"},
  selected: {label: "", noSort: true},
  starred: {label: "", noSort: true},
  name: {clickable: true},
  status: {align: "center", noSort: true},
  categories: {"client_mode": client_mode.category, noSort: true},
  projects: {"client_mode": client_mode.project, noSort: true},
  createdAt: {label: "Created"},
  phone: {formatter: formatPhoneNumber}
}

const defaultColumns = ["starred", "name", "status", "categories", "projects", "city", "state", "createdAt", "email", "phone"]

const vendorStatusLabel = {
  COMPLIANT: "Compliant",
  NOT_COMPLIANT: "Not Compliant",
  ARCHIVED: "Archived",
}

function Row(props) {
  const {
    row = {},
    columns = [],
    onClick,
    rowIndex,
    loading = false,
    clientMode = client_mode.category,
    onPreferredClick,
    projects = [],
    categories = [],
    selected = {},
    onSelectionChange = () => { },
    hasMultipleSelectionPermissions = false,
  } = props;
  const hasNotificationsDisabled = row?.notificationStatus === 'DISABLED';
  const { vendor = {}, starred = false, status, categories: vendorCategories, projects: vendorProjects, id } = row;
  const categoryNames = []
  let firstCategoryName = ""
  if(clientMode === client_mode.category) {
    vendorCategories?.items?.forEach(vendorCategory => {
      const categoryName = categories?.find(category => category.id === vendorCategory.categoryID)?.name
      categoryNames[vendorCategory.primary ? "unshift" : "push"](categoryName)
    })
    firstCategoryName = categoryNames.shift()
  }
  const projectNames = []
  if(clientMode === client_mode.project) {
    vendorProjects?.items?.forEach(vendorProject => {
      const projectName = projects?.find(project => project.id === vendorProject.projectID)?.name
      projectNames.push(projectName)
    })
  }

  return (
    <React.Fragment>
      <TableRow>
        {
          columns.map((column, index) => {
            const config = columnConfig[column]
            if(config?.client_mode && clientMode !== config.client_mode) {
              return false;
            }
            const cellConfig = {
              key: "vendors-"+rowIndex+"-cell"+index,
              align: config?.align || "left",
              className: config?.clickable ? "clickable" : ""
            }
            config?.clickable && (cellConfig.onClick = ()=>onClick(vendor.id))

            if (column === 'selected' || column === 'requested') {
              if (!hasMultipleSelectionPermissions) {
                return null;
              }
              return (
                <TableCell padding="checkbox" {...cellConfig}>
                  <Checkbox disabled={hasNotificationsDisabled} checked={!!selected?.[id] && !hasNotificationsDisabled} onChange={(event)=> onSelectionChange(id, event.target.checked)}/>
                </TableCell>
              )
            }

            if(column === "starred") {
              return (
                <TableCell padding="checkbox" {...cellConfig}>
                  <IconButton
                    disabled={loading}
                    size="small"
                    onClick={() => onPreferredClick && onPreferredClick(row)}
                  >
                    <StarIcon sx={{color: starred ? "rgb(255, 180, 0)": "rgba(0, 0, 0, 0.26)"}}/>
                  </IconButton>
                </TableCell>
              )
            }
            if(column === "status") {
              return (
                <TableCell {...cellConfig}>
                  {
                    status &&
                    <StatusIndicator label={vendorStatusLabel[status]} size="small"/>
                  }
                </TableCell>
              )
            }
            if(column === "createdAt") {
              return (
                <TableCell {...cellConfig}>{new Date(vendor.createdAt).toLocaleDateString("en-US")}</TableCell>
              )
            }
            if(column === "categories") {
              return (
                <TableCell {...cellConfig}>
                  {
                    categoryNames.length > 0
                      ? (
                        <Popup
                          trigger={
                            <div>{firstCategoryName}
                              <Label style={{float: "right"}}>+{categoryNames.length}</Label>
                            </div>
                          }
                          position='bottom right'
                        >
                          <div>
                            {
                              categoryNames.join(", ")
                            }
                          </div>
                        </Popup>
                      )
                      : (
                        <div>{firstCategoryName}</div>
                      )
                  }
                </TableCell>
              )
            }
            if(column === "projects") {
              return (
                <TableCell {...cellConfig}>
                  { projectNames.length === 1 && projectNames[0] }
                  {
                    projectNames.length > 1 &&
                    <Popup
                      trigger={<div>{projectNames[0]} <Label style={{float: "right"}}>+{projectNames.length-1}</Label></div>}
                      position='bottom right'
                    >
                      <div>
                        {
                          projectNames.slice(1).join(", ")
                        }
                      </div>
                    </Popup>
                  }
                </TableCell>
              )
            }

            return (
              <TableCell {...cellConfig}>
                { config?.formatter ? config.formatter(vendor[column]) : vendor[column] }
              </TableCell>
            )
          })
        }
      </TableRow>
    </React.Fragment>
  );
}

const VendorsTable = ({
  columns = defaultColumns,
  vendors = [],
  clientMode,
  categories = [],
  projects = [],
  navToVendor = ()=>{},
  onPreferredClick = ()=>{},
  saving,
  compact = true,
  selected = {},
  onSelectionChange = ()=>{},
  loading,
  onRequestHandler = () => { },
  hasMultipleSelectionPermissions = false,
}) => {
  const { selectedClient } = useSelector(organizationState);
  const vendorLabel = getVendorLabel(selectedClient);
  const projectLabel = getProjectLabel(selectedClient);
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [statusFilter, setStatusFilter] = useState("ALL")
  const [sortBy, setSortBy] = useState("createdAt")
  const [sortAsc, setSortAsc] = useState(false)
  const theadProps = {sortBy, setSortBy, sortAsc, setSortAsc}
  const [searchPhrase, setSearchPhrase] = useState("")

  const filteredVendors = useMemo(() => {
    if(vendors?.length && (sortBy || statusFilter)) {
      let toSort = [...vendors]
      if(statusFilter !== "ALL" || searchPhrase) {
        toSort = toSort.filter(vendor => {
          let classificationString = null;
          if(client_mode.project === clientMode && vendor.projects?.items?.length) {
            classificationString = vendor.projects.items.map(vendorProject => projects.find(project => vendorProject.projectID === project.id)?.name).join("")
          } else if(client_mode.category === clientMode && vendor.categories?.items?.length) {
            classificationString = vendor.categories.items.map(vendorCategory => categories.find(category => vendorCategory.categoryID === category.id)?.name).join("")
          }
          return (
            (statusFilter === "ALL" || vendor.status === statusFilter) &&
            (!searchPhrase || findInStringsIgnoreCase(searchPhrase, [
              vendor.vendor.name,
              vendor.vendor.email,
              vendor.vendor.phone,
              vendor.vendor.city,
              vendor.vendor.state,
              formatPhoneNumber(vendor.vendor.phone),
              new Date(vendor.vendor.createdAt).toLocaleDateString('en-US'),
              classificationString,
            ]))
          )
        })
      }

      const starredVendorIndex = 0
      const nonStarredVendorIndex = 1
      const splitVendors = [[], []]
      toSort.forEach(vendor => splitVendors[vendor.starred ? starredVendorIndex : nonStarredVendorIndex].push(vendor))

      splitVendors.forEach(vendorList => {
        vendorList.sort((a,b) => {
          let larger = (a?.vendor?.[sortBy] || "") > (b?.vendor?.[sortBy] || "")
          if(sortBy === "createdAt") {
            larger = (new Date(a?.vendor?.createdAt)) > (new Date(b?.vendor?.createdAt))
          }
          if(larger) {
            return sortAsc ? 1 : -1
          }
          return sortAsc ? -1 : 1
        })
      })
      return splitVendors.flat()
    }
    return vendors
  }, [vendors?.length, sortBy, sortAsc, statusFilter, searchPhrase])


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer style={{overflow: "visible"}} className={`vendors-table ${compact ? "compact-table" : ""}`}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} style={{border: "none"}}>
                <Menu text className="status-filter">
                  <Menu.Item active={statusFilter === 'ALL'} onClick={() => setStatusFilter('ALL')} >
                    All
                  </Menu.Item>
                  {
                    Object.keys(vendorStatusLabel).map(statusKey => (
                      <Menu.Item active={statusFilter === statusKey} onClick={() => setStatusFilter(statusKey)} key={"statusFilter"+statusKey}>
                        {vendorStatusLabel[statusKey]}
                      </Menu.Item>
                    ))
                  }
                </Menu>
              </TableCell>
              <TableCell colSpan={1} sx={{border: "none"}}/>
              <TableCell colSpan={columns.length - 4} sx={{border: "none", pr: 0}}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={`Search ${vendorLabel} by Name, ${projectLabel}, Address, Email...`}
                  id="fullWidth"
                  value={searchPhrase}
                  onChange={e => setSearchPhrase(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              {
                columns.map((column, index) => {
                  const config = columnConfig[column]
                  const label = config?.label === "" ? "" : (config?.label || column)
                  if(config?.client_mode && clientMode !== config.client_mode) {
                    return false;
                  }

                  let columnProps = {
                    key: "vendor-column-header-" + index,
                    sx: { textTransform: "capitalize" },
                    align: config?.align || "left",
                  }
                  if (column === "requested") {
                    if (!hasMultipleSelectionPermissions) {
                      return null;
                    }
                    return <TableCell {...columnProps}>
                      <Button size="small" disabled={Object.keys(selected).length < 1} onClick={onRequestHandler}>
                        <ExpandMore />
                        Action
                      </Button>
                    </TableCell>
                  } else if (column === "projects") {
                    config.label = `${projectLabel}s`;
                  }
                  if(config?.noSort) {
                    return <TableCell {...columnProps}>{label}</TableCell>
                  }
                  return (
                    <SortableTHeadCell
                      {...{
                        sortId: column,
                        ...columnProps,
                        label,
                        ...theadProps,
                      }}
                    />
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading &&
              <TableLoadingIndicator colSpan={columns.length} />
            }
            {
              !loading &&
              filteredVendors
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, rowIndex) => (
                <Row
                  key={rowIndex}
                  row={row}
                  columns={columns}
                  onClick={navToVendor}
                  rowIndex={rowIndex}
                  clientMode={clientMode}
                  onPreferredClick={onPreferredClick}
                  loading={saving}
                  projects={projects}
                  categories={categories}
                  selected={selected}
                  onSelectionChange={onSelectionChange}
                  hasMultipleSelectionPermissions={hasMultipleSelectionPermissions}
                />
              ))}
          </TableBody>
        </Table>

      </TableContainer>
      {
        !loading &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={vendors?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </>
  )
}

export default VendorsTable;
