import { Typography } from "@mui/material"

export default (props) => (
  <Typography
    variant="subtitle2"
    color={props.secondary ? "text.secondary" : "default"}
    sx={{fontWeight: 600, fontSize: "0.9em", ...(props.sx || {})}}
  >
    {props.children}
  </Typography>
)
