import React from "react";
import { Stack, FormControl, InputLabel, Select, MenuItem, TextField, Link, Typography } from "@mui/material";
import us_states from "constants/us_states";
import { SubtitleInline, SectionTitle } from "components/shared/Typography";

import { formatPhoneNumber, getPhoneNumberDigits, removeFormat, getZipCodeDigits } from "utils/formUtils";

export default function OrganizationAddressForm({
  organizationDetails = {},
  setOrganizationDetails = () => {},
  organizationDetailsError = {},
  setOrganizationDetailsError = () => {},
  adminLabelProp,
  businessLabelProp,
  textFieldStyles = {},
  variant,
  isEditable = true,
  showDetails = true,
  showAddress = true,
}) {
  const { website, adminName, address1, address2, phone, adminEmail, zip, city, state } = {
    adminName: "",
    address1: "",
    address2: "",
    phone: "",
    adminEmail: "",
    zip: "",
    city: "",
    state: "",
    website: "",
    ...organizationDetails,
  };
  const adminLabel = adminLabelProp || "Administrator";
  const businessLabel = businessLabelProp || "Business";

  function onStateFieldKeyPress(event) {
    const { key } = event;
    const findState = us_states.find((state) => state.startsWith(key.toUpperCase()));

    if (findState) {
      setOrganizationDetails({
        ...organizationDetails,
        organizationDetails: {
          ...organizationDetails?.organizationDetails,
          state: findState,
        },
      });
    }
  }

  function updateFormValue(event) {
    const { name: fieldName, value } = event.target;
    let updatedValue = removeFormat({ name: fieldName, value });
    if (fieldName === "email") {
      if (!event.target.validity?.valid) {
        setOrganizationDetailsError({
          ...organizationDetailsError,
          organizationDetailsEmail: event.type === "blur" ? "Please enter a valid email address" : true,
        });
      } else {
        setOrganizationDetailsError({
          ...organizationDetailsError,
          organizationDetailsEmail: false,
        });
      }
    }

    //form is only valid if both name and email are present or absent
    if (!!adminName != !!adminEmail || (fieldName === "adminEmail" && !event.target.validity?.valid)) {
      setOrganizationDetailsError({
        ...organizationDetailsError,
        invalidorganizationDetailsForm: true,
      });
    } else {
      setOrganizationDetailsError({
        ...organizationDetailsError,
        invalidorganizationDetailsForm: false,
      });
    }

    //IMPORTANT: we can't send empty AWS types like AWS Email, Int
    //this can introduce break of API calls
    
    //set organization details if there is value, otherwise 
    // delete empty fields
    if (value) {
      setOrganizationDetails({
        ...organizationDetails,
        [fieldName]: updatedValue,
      });
    } else {
      delete organizationDetails[fieldName]
      setOrganizationDetails({
        ...organizationDetails,
      });
    }
  }

  return (
    <>
      {showDetails && (
        <Stack spacing={2} my={2}>
          {
            <>
              {isEditable ? (
                <TextField
                  disabled={!isEditable}
                  variant={variant}
                  sx={textFieldStyles}
                  className="vendor-info-input"
                  label={"Website URL"}
                  value={website}
                  name={"website"}
                  onChange={updateFormValue}
                  onBlur={updateFormValue}
                />
              ) : (
                <>
                  <SubtitleInline>Website URL</SubtitleInline>
                  <Link href={website} target="_blank">
                    {website}
                  </Link>
                </>
              )}
              <TextField
                disabled={!isEditable}
                variant={variant}
                sx={textFieldStyles}
                className="vendor-info-input"
                label={adminLabel + " Name"}
                value={adminName}
                name={"adminName"}
                onChange={updateFormValue}
                onBlur={updateFormValue}
                error={!!adminEmail != !!adminName}
                helperText={adminName === "" ? `Please specify ${String(adminLabel).toLowerCase()}'s name` : ""}
              />
              <Stack direction="row" spacing={2}>
                <TextField
                  disabled={!isEditable}
                  variant={variant}
                  sx={textFieldStyles}
                  className="vendor-info-input"
                  label={adminLabel + " Email"}
                  value={adminEmail}
                  name={"adminEmail"}
                  onChange={updateFormValue}
                  onBlur={updateFormValue}
                  type="email"
                  inputProps={{ pattern: "^\\S+@\\S+\\.\\S+$" }}
                  error={(isEditable && !!adminEmail != !!adminName) || typeof organizationDetailsError.email === "string"}
                  helperText={
                    (isEditable && adminEmail === "") || typeof organizationDetailsError.email === "string" ? "Please enter a valid email address" : ""
                  }
                />
                <TextField
                  disabled={!isEditable}
                  variant={variant}
                  sx={textFieldStyles}
                  className="vendor-info-input"
                  label={businessLabel + " Phone"}
                  value={formatPhoneNumber(phone)}
                  name={"phone"}
                  onChange={updateFormValue}
                  error={isEditable && phone && (parseInt(getPhoneNumberDigits(phone)) === 0 || getPhoneNumberDigits(phone).length < 10)}
                  helperText={
                    isEditable && phone && (parseInt(getPhoneNumberDigits(phone)) === 0 || getPhoneNumberDigits(phone).length < 10)
                      ? "Please enter a valid phone number"
                      : ""
                  }
                />
              </Stack>
            </>
          }
        </Stack>
      )}
      {showAddress && (
        <Stack spacing={2}>
          <TextField
            disabled={!isEditable}
            variant={variant}
            sx={textFieldStyles}
            className="vendor-info-input"
            label={businessLabel + " Address Line 1"}
            value={address1}
            name={"address1"}
            onChange={updateFormValue}
          />
          <TextField
            disabled={!isEditable}
            variant={variant}
            sx={textFieldStyles}
            className="vendor-info-input"
            label={businessLabel + " Address Line 2"}
            value={address2}
            name={"address2"}
            onChange={updateFormValue}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={!isEditable}
              variant={variant}
              sx={textFieldStyles}
              className="vendor-info-input"
              label="City"
              value={city}
              name={"city"}
              onChange={updateFormValue}
            />
            <FormControl sx={{ minWidth: 80 }}>
              <InputLabel sx={textFieldStyles} id="state-input-label">
                State
              </InputLabel>
              <Select
                sx={textFieldStyles}
                disabled={!isEditable}
                labelId="state-input-label"
                className="vendor-info-input"
                label="State"
                variant={variant}
                value={state || ""}
                name={"state"}
                onChange={updateFormValue}
                onKeyPress={onStateFieldKeyPress}
              >
                <MenuItem value={""}>-</MenuItem>
                {us_states.map((usState) => (
                  <MenuItem value={usState} key={usState}>
                    {usState}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              disabled={!isEditable}
              variant={variant}
              sx={textFieldStyles}
              className="vendor-info-input"
              label="Zip Code"
              value={getZipCodeDigits(zip)}
              name={"zip"}
              onChange={updateFormValue}
              error={isEditable && zip && getZipCodeDigits(zip).length !== 5}
              helperText={isEditable && zip && getZipCodeDigits(zip).length !== 5 ? "Please enter valid Zip Code" : ""}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
}
