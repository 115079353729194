import {Box, Divider, Typography} from "@mui/material";
import {formatCurrencyInt} from "utils/formUtils";
import React from "react";

export default ({name, amount=0, sx={}}) => (
  <Box sx={sx}>
    <Box sx={{display: "flex", flexDirection: "row"}}>
      <Typography variant="body1" sx={{fontWeight: 500, display: "inline", flexGrow: 1}}>
        {name}
      </Typography>
      <span style={{whiteSpace: "nowrap"}}>$ {formatCurrencyInt(amount)}</span>
    </Box>
    <Divider sx={{mt: 0.5, mb: 0.5}}/>
  </Box>
)
