import { useEffect } from "react";
import "./scan-result.scss"
import {Typography} from "@mui/material"
import useScanData from "hooks/useScanData";

export default function ScanResultTableCell ({id, onUpdateScanData}) {
  const { scanData } = useScanData(id, true, true)

  useEffect(() => {
    onUpdateScanData(scanData);
  }, [scanData])
  
  if(scanData?.status === "PENDING") {
    return (
      <Typography
        className="blink"
        sx={{textTransform: "capitalize"}}
      >
        {String(`${scanData.status}${scanData?.scanStage ? ` - ${scanData.scanStage}` : ""}`).toLowerCase()}
      </Typography>
    )
  } else if(scanData?.status === "COMPLETED") {
    return (
      <Typography
        className="blink"
        sx={{textTransform: "capitalize"}}
      >
        Completed - saving result
      </Typography>
    )
  } else if(scanData?.status === "FAILED") {
    return (
      <Typography
        sx={{
          color: "darkred",
          textTransform: "capitalize"
        }}
      >
        {String(scanData.status).toLowerCase()}
      </Typography>
    )
  } else if(scanData?.status === "SUBMITTED") {
    return (
      <Typography
        sx={{
          color: "green",
          textTransform: "capitalize"
        }}
      >
        Saved
      </Typography>
    )
  }
  return null
}
