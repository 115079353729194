import {TableCell, TableRow} from "@mui/material";
import {Placeholder} from "semantic-ui-react";
import React from "react";

export default ({colSpan}) => (
  <TableRow>
    <TableCell colSpan={colSpan} sx={{borderBottom: "none"}}>
      <Placeholder fluid={true}>
        <Placeholder.Line/>
        <Placeholder.Line/>
      </Placeholder>
    </TableCell>
  </TableRow>
)
