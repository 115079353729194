import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux"
import { organizationState } from "redux/organizationSlice";

import OrganizationSettings from "components/OrganizationSettings"
import { Loader } from 'semantic-ui-react'

export default ({logo}) => {
  const [snackOpen, setSnackOpen] = useState(false)
  const [serviceMessage, setServiceMessage] = useState();

  const {
    userOrganization: fetchedOrganization
  } = useSelector(organizationState)
  const [organization, setOrganization] = useState()


  useEffect(() => {
    if(serviceMessage) {
      setSnackOpen(true)
    }
  }, [serviceMessage])

  useEffect(()=>{
    if(!snackOpen) {
      setServiceMessage(undefined)
    }
  }, [snackOpen,])

  useEffect(() => {
    setOrganization(fetchedOrganization)
    
  }, [fetchedOrganization])

  return (
    organization ? 
    <OrganizationSettings isClient={true} logo={logo} organization={organization} setOrganization={setOrganization}/>
    :
    <Loader active  indeterminate size='huge' />
  )
}
