import React, {useMemo} from 'react'
import "./navigation-menu.scss"
import { Menu } from 'semantic-ui-react'
import {Typography, Chip, Divider, Container, Button, Stack} from "@mui/material";
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import {useLocation, useNavigate} from "react-router-dom";
import ORGANIZATION_TYPES, { getProjectLabel, getVendorLabel } from "constants/organization_types";
import {
  ADMIN_NAVIGATION,
  AGENCY_NAVIGATION,
  CLIENT_NAVIGATION,
  getNavigationPath
} from "constants/routes"

import { organizationState } from "redux/organizationSlice";
import { loggedInUserState } from "redux/userSlice";
import { useSelector } from "react-redux";
import AppContentWrapper from "components/shared/AppContentWrapper";

const navType = {
  [ORGANIZATION_TYPES.SYSTEM]: ADMIN_NAVIGATION,
  [ORGANIZATION_TYPES.AGENCY]: AGENCY_NAVIGATION,
  [ORGANIZATION_TYPES.CLIENT]: CLIENT_NAVIGATION,
}

const Navigation = ({ children, onAddVendor, clientMode, orgType }) => {
  const { isAdmin } = useSelector(loggedInUserState)
  const location = useLocation()
  const organizationStates = useSelector(organizationState)
  const navigate = useNavigate()
  const getPathReplacementParts = () => {
    const pathReplacements = {}
    organizationStates?.selectedAgency && (pathReplacements.agencyId = organizationStates.selectedAgency.id)
    organizationStates?.selectedClient && (pathReplacements.clientId = organizationStates.selectedClient.id)
    return pathReplacements
  }
  const handleNav = (path) => {
    navigate(getNavigationPath(path, getPathReplacementParts()))
  }

  const navItems = navType[orgType]
  const navMenuItems = useMemo(() => {
    let menuItems = null;
    navItems.forEach(navItem => {
      if(!navItem.stateKey || !!organizationStates[navItem.stateKey]) {
        menuItems = navItem.excludedPaths?.includes(location.pathname) ? [] : navItem.items
      }
    })
    return menuItems?.filter(menuItem => !!!menuItem.isAdmin || isAdmin).
      map(menuItem => {
        const item = {...menuItem}
        if (menuItem.name === 'Subcontractors') {
        item.name = `${getVendorLabel(organizationStates?.selectedClient)}s`
        } else if (menuItem.name === 'Projects') {
          item.name = `${getProjectLabel(organizationStates?.selectedClient)}s`
        }
        return item
    })
  }, [organizationStates, navItems])
  
  const MenuItem = ({navItem}) => {
    const navPath = navItem.path || ""
    const screen = location && location.pathname?.split("/").pop()
    let pathMatch = navPath.substring(1).split("/").pop()

    let active = (screen === pathMatch)
    if(navItem.matchPartial) {
      pathMatch = getNavigationPath(navPath, getPathReplacementParts())
      active = location.pathname.includes(pathMatch)
    }

    return (
      <Chip
        label={navItem.name}
        variant={active ? "filled" : "outlined"}
        onClick={() => handleNav(navPath)}
        className={`top-menu-item ${active ? "active" : ""}`}
      />
    )
  }

  return (
    <>
      {
        navMenuItems?.length &&
        <Menu
          className={"top-menu fixed"}
          direction="top"
          fluid
        >
          <Container maxWidth="xl" sx={{display: "flex"}}>
            {
              navMenuItems?.map((navItem, index) => {
                return (
                  (!navItem.client_mode || navItem.client_mode === clientMode) &&
                  <MenuItem navItem={navItem} key={"nav-" + index}/>
                )
              })
              }
              <Stack textTransform="none" sx={{marginLeft: "auto"}} flexDirection="row">

              <Button
                onClick={() => {
                  window.showCollectorDialog();
                }}
              >
                Request Support
              </Button>
            <Button  onClick={() => window.location.reload()}>
              <RefreshOutlined color="success" />
            </Button>
              </Stack>
          </Container>
        </Menu>
      }
      <AppContentWrapper maxWidth="xl">
        { children }
      </AppContentWrapper>

    </>
  )
}

export default Navigation
