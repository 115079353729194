import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import { API } from "aws-amplify"
import {
  getClientCategories,
  updateClientCategory,
  createClientCategory,
  getClientProjects,
  createClientProject,
  updateClientProject,
  makeProjectActive,
  makeProjectInactive,
  makeProjectArchived,
  updateCLIENTCOVERAGE,
  createCLIENTCOVERAGE,
  updateCategoryCoverage,
  deleteClientCategory,
  certificateByProject,
  getProject,
  updateCLIENTTemplates,
  certificateByClient,
  certificateByClientQueries,
} from "graphql/client"
import {
  createPROJECTVENDORS,
  deletePROJECTVENDORS,
} from "graphql/vendor"
import {usToAWSDate} from "utils/dateUtils";
import {organizationActions} from "./organizationSlice"
import {client_mode} from "constants/client_properties";
import _ from "underscore";

const adapter = createEntityAdapter()
const initialState = adapter.getInitialState({
  status: "idle",
  categories: {},
  projects: {},
  certificates: null,
  paginatedCertificates: null,
  certificatesCount: 0,
})

const updateProjectSuccess = (state, action) => {
  state.status = 'success'
  state.projects[action.payload.clientID] = state.projects[action.payload.clientID]?.map(project => {
    if(action.payload?.project?.id === project.id) {
      return {
        ...project,
        ...action.payload.project,
      }
    }
    return project
  })
}

const actions = {
  fetchCategories: createAsyncThunk('client/fetchCategories', async (clientID) => {
    const { data } = await API.graphql({
      query: getClientCategories,
      variables: {
        id: clientID,
      }
    })
    return {
      clientID: clientID,
      categories: data?.getCLIENT?.clientCategories?.items
    }
  }),
  updateCategory: createAsyncThunk('client/updateCategory', async ({id, name, clientID, requiredCoverages}) => {
    let query = updateClientCategory
    const variables = {id}
    if(name && !requiredCoverages) {
      variables.name = name
    } else if(requiredCoverages) {
      query = updateCategoryCoverage
      let requiredCoveragesToSave = requiredCoverages
      if(typeof requiredCoveragesToSave !== "string") {
        delete requiredCoveragesToSave.index
        requiredCoveragesToSave = JSON.stringify(requiredCoveragesToSave)
      }
      variables.requiredCoverages = requiredCoveragesToSave
    }
    const {data} = await API.graphql({ query, variables,})
    return {
      category: data?.updateCATEGORY,
      success: true,
      clientID,
    }
  }),
  deleteCategory: createAsyncThunk('client/deleteCategory', async ({id, clientID}) => {
    let query = deleteClientCategory
    const variables = { id }

    const {data} = await API.graphql({ query, variables,})
    return {
      categoryID: id,
      success: true,
      clientID,
    }
  }),
  createCategory: createAsyncThunk('client/createCategory', async ({name, clientID}) => {
    const {data} = await API.graphql({
      query: createClientCategory,
      variables: {
        name,
        clientID
      }
    })
    return {
      success: true,
      clientID,
      category: data?.createCATEGORY
    }
  }),
  fetchProjects: createAsyncThunk('client/fetchProjects', async (clientID) => {
    const { data } = await API.graphql({
      query: getClientProjects,
      variables: {
        id: clientID,
      }
    })
    return {
      projects: data?.getCLIENT?.clientProjects?.items,
      clientID: clientID
    }
  }),
  createProject: createAsyncThunk('client/createProject', async ({projectData, clientID}) => {
    const startDate = usToAWSDate(projectData?.startDate)
    const endDate = usToAWSDate(projectData?.endDate)

    const {data} = await API.graphql({
      query: createClientProject,
      variables: {
        ...projectData,
        startDate,
        endDate,
        clientID,
      }
    })
    return {
      success: true,
      clientID,
      project: data?.createPROJECT
    }
  }),
  updateProject: createAsyncThunk('client/updateProject', async ({projectData, clientID}) => {
    const {id, name, description, zip, address, state} = projectData
    const input = {id, name, description, address, zip, state};
    projectData?.startDate && (input.startDate = usToAWSDate(projectData.startDate))
    projectData?.endDate && (input.endDate = usToAWSDate(projectData?.endDate))
    if(projectData?.requiredCoverages) {
      let requiredCoverages = projectData.requiredCoverages
      if(typeof requiredCoverages !== 'string') {
        delete requiredCoverages.index
        requiredCoverages = JSON.stringify(projectData.requiredCoverages)
      }
      input.requiredCoverages = requiredCoverages;
    }
    const {data} = await API.graphql({
      query: updateClientProject,
      variables: {
        input
      }
    })
    return {
      success: true,
      clientID,
      project: data?.updatePROJECT
    }
  }),
  updateProjectStatus: ({id, status, clientID}) => async (dispatch) => {
    const queryMap = {
      ACTIVE: makeProjectActive,
      INACTIVE: makeProjectInactive,
      ARCHIVED: makeProjectArchived
    }
    const query = queryMap[status]
    if(id && query) {
      const {data} = await API.graphql({
        query,
        variables: {
          id
        }
      })
      dispatch(clientSlice.actions.updateProjectSuccess({ project: data?.updatePROJECT, clientID  }))
      return {
        payload: {
          success: true
        }
      }
    }
  },
  updateClientEmailTemplates: ({clientID, organizationID, templates}) => async (dispatch) => {
    const {data} = await API.graphql({
      query: updateCLIENTTemplates,
      variables: {
        input: {
          id: clientID,
          templates,
        },
      }
    })
    await dispatch(organizationActions.updateSelectedClient({
      id: organizationID,
      updates: {
        templates: data?.updateCLIENT?.templates
      }
    }))
  },
  saveClientCoverage: ({coverages=[], clientID, organizationID}) => async (dispatch) => {
    let updated = []
    await Promise.all(coverages.map(async (coverage) => {
      const input = { ...coverage }
      if(typeof coverage.limits === 'object') {
        input.limits = JSON.stringify(coverage.limits)
      }
      delete input.createdAt;
      delete input.updatedAt;
      delete input.index;
      if(!coverage.id) {
        input.clientID = clientID
      }

      const {data} = await API.graphql({
        query: coverage.id ? updateCLIENTCOVERAGE : createCLIENTCOVERAGE,
        variables: {
          input,
        }
      })
      updated.push(coverage.id ? data.updateCLIENTCOVERAGE : data.createCLIENTCOVERAGE)
      return Promise.resolve()
    }))
    updated = _.sortBy(updated, "createdAt")
    await dispatch(organizationActions.updateSelectedClient({
      id: organizationID,
      updates: {
        clientCoverages: {
          items: updated
        }
      }
    }))
    return {
      payload: {
        coverages: updated,
        success: true
      }
    }
  },
  getAllClientCertificates: createAsyncThunk('client/getAllClientCertificates', async (clientID) => {
    let nextToken = null;
    let runningCount = 0;
    let initialRun = true;
    let certificates = [];
    do {
      const { data } = await API.graphql({
        query: certificateByClient,
        variables: {
          clientID,
          nextToken,
          filter: {
            status: {
              ne: "REQUESTED"
            }
          }
        }
      })
      initialRun = false;
      if(data?.certificateByClient?.items?.length) {
        runningCount += (data.certificateByClient.items.length)
        certificates = certificates.concat(data.certificateByClient.items)
      }
      nextToken = (data?.certificateByClient?.nextToken || null)
    } while (initialRun || nextToken)

    return {
      success: true,
      certificatesCount: runningCount,
      certificates
    }
  }),
  getPaginatedClientCertificates: createAsyncThunk('client/getPaginatedClientCertificates', async ({
    clientID,
    sortBy = "lastModifiedDate",
    sortAsc = false,
    nextToken,
    rowsPerPage,
    status,
    statusFilter,
  }) => {
    const sortQueriesMapper = {
      activeBylastModifiedDate: "getActiveCertificates",
    }
    
    const indexNameMapper = {
      default: "byCLIENT",
      certificateDate: "byCLIENTsortCERTIFICATEDATE",
      insured: "byCLIENTsortINSURED",
      producer: "byCLIENTsortPRODUCER",
      policyExp: "byCLIENTsortFirstExp",
      lastModifiedDate: "byCLIENTsortUPDATEDAT",
      createdDate: "byCLIENTsortCREAEDAT"
    }
    
    // const queryName = sortQueriesMapper[sortBy] || sortQueriesMapper.default
    const queryName = sortQueriesMapper.activeBylastModifiedDate
    let rowsCount = rowsPerPage;
    let result = [];
    let resultNextToken = null;

    do {
    const { data } = await API.graphql({
      query: certificateByClientQueries[queryName],
      variables: {
        clientID,
        sortDirection: sortAsc ? "ASC" : "DESC",
        limit: rowsCount - result.length,
        nextToken: resultNextToken || nextToken,
        indexName: indexNameMapper[sortBy] || indexNameMapper.default,
        ...(status && { status: status}),
        ...(statusFilter && { statusFilter: statusFilter}),
      }
    })
      
      result.push(...data?.[queryName]?.items);
      resultNextToken = data?.[queryName]?.nextToken;
    } while(result.length < rowsCount && resultNextToken)

    return {
      certificates: result,
      nextToken: resultNextToken,
      success: true,
    }
  }),
  getProjectCertificates: createAsyncThunk('client/getProjectCertificates', async (projectID) => {
    const {data} = await API.graphql({
      query: certificateByProject,
      variables: {
        projectID,
        filter: {
          status: {
            ne: "REQUESTED"
          }
        }
      }
    })

    return {
      certificates: data.certificateByProject.items,
      success: true
    }
  }),
  updateProjectVendors: createAsyncThunk('client/updateProjectVendors', async ({projectID, selectedVendors = {}, existingProjectVendors}) => {
    let projectVendorsToAdd = {...selectedVendors}
    const projectVendorsToRemove = []
    existingProjectVendors?.forEach(existingProjectVendor => {
      if(projectVendorsToAdd[existingProjectVendor.clientVendor?.id]) {
        delete projectVendorsToAdd[existingProjectVendor.clientVendor.id]
      } else {
        projectVendorsToRemove.push(existingProjectVendor.id)
      }
    })
    projectVendorsToAdd = Object.keys(projectVendorsToAdd).map(clientVendorID => ({
      clientVendorID,
      projectID
    }))

    if(projectVendorsToAdd.length) {
      await Promise.all(projectVendorsToAdd.map(async (projectVendor) => {
        await API.graphql({
          query: createPROJECTVENDORS,
          variables: {
            input: {
              ...projectVendor
            }
          }
        })
        return Promise.resolve()
      }))
    }
    if(projectVendorsToRemove.length) {
      await Promise.all(projectVendorsToRemove.map(async (projectVendorID) => {
        await API.graphql({
          query: deletePROJECTVENDORS,
          variables: {
            input: {
              id: projectVendorID
            }
          }
        })
        return Promise.resolve()
      }))
    }
    const result = await API.graphql({
      query: getProject,
      variables: {
        id: projectID
      }
    })
    return {
      success: true,
      clientID: result?.data?.getPROJECT?.clientID,
      project: result?.data?.getPROJECT,
    }
  }),
}

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.categories = {};
      state.projects = {};
      state.certificates = null;
      state.paginatedCertificates = null;
      state.certificatesCount = 0;
    },
    updateProjectSuccess
  },
  extraReducers: builder => {
    builder
      // fetching categories
      .addCase(actions.fetchCategories.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.fetchCategories.fulfilled, (state, action) => {
        state.categories[action.payload.clientID] = action.payload.categories || []
        state.status = 'success'
      })
      .addCase(actions.fetchCategories.rejected, (state, action) => {
        state.status = 'failure'
      })
      // update category
      .addCase(actions.updateCategory.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.updateCategory.fulfilled, (state, action) => {
        state.status = 'success'
        state.categories[action.payload.clientID] = state.categories[action.payload.clientID]?.map(category => {
          if(action.payload?.category?.id === category.id) {
            return {
              ...category,
              ...action.payload.category,
            }
          }
          return category
        })
      })
      .addCase(actions.updateCategory.rejected, (state, action) => {
        state.status = 'failure'
      })
      // delete category
      .addCase(actions.deleteCategory.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.deleteCategory.fulfilled, (state, action) => {
        state.status = 'success'
        state.categories[action.payload.clientID] = state.categories[action.payload.clientID].filter(category => category.id !== action.payload.categoryID)
      })
      .addCase(actions.deleteCategory.rejected, (state, action) => {
        state.status = 'failure'
      })
      // create category
      .addCase(actions.createCategory.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.createCategory.fulfilled, (state, action) => {
        state.status = 'success'
        if(action.payload?.category) {
          if(!state.categories[action.payload.clientID]?.length) {
            state.categories[action.payload.clientID] = [action.payload.category]
          } else {
            state.categories[action.payload.clientID].push(action.payload.category)
          }
        }
      })
      .addCase(actions.createCategory.rejected, (state, action) => {
        state.status = 'failure'
      })
      // fetching projects
      .addCase(actions.fetchProjects.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.fetchProjects.fulfilled, (state, action) => {
        state.projects[action.payload.clientID] = action.payload.projects || []
        state.status = 'success'
      })
      .addCase(actions.fetchProjects.rejected, (state, action) => {
        state.status = 'failure'
      })
      // create project
      .addCase(actions.createProject.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.createProject.fulfilled, (state, action) => {
        state.status = 'success'
        if(action.payload?.project) {
          if(state.projects[action.payload.clientID]?.length) {
            state.projects[action.payload.clientID].push(action.payload.project)
          } else {
            state.projects[action.payload.clientID] = [action.payload.project]
          }
        }
      })
      .addCase(actions.createProject.rejected, (state, action) => {
        state.status = 'failure'
      })
      // update project
      .addCase(actions.updateProject.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.updateProject.fulfilled, updateProjectSuccess)
      .addCase(actions.updateProject.rejected, (state, action) => {
        state.status = 'failure'
      })
      // get client certificates
      .addCase(actions.getPaginatedClientCertificates.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.getPaginatedClientCertificates.fulfilled, (state, action) => {
        state.paginatedCertificates = action.payload?.certificates
        state.status = 'success'
      })
      .addCase(actions.getPaginatedClientCertificates.rejected, (state, action) => {
        state.status = 'failure'
      })
      // get client certificates count
      .addCase(actions.getAllClientCertificates.fulfilled, (state, action) => {
        state.certificatesCount = action.payload?.certificatesCount;
        state.certificates = action.payload?.certificates;
      })
      // update project vendors
      .addCase(actions.updateProjectVendors.pending, state => {
        state.status = 'loading'
      })
      .addCase(actions.updateProjectVendors.fulfilled, updateProjectSuccess)
      .addCase(actions.updateProjectVendors.rejected, (state, action) => {
        state.status = 'failure'
      })
  }
})

export const clientState = state => state.client
export const categoryState = state => state.client.categories
export const projectState = state => state.client.projects
export const clientClassificationsState = state => {
  if(state.organization?.selectedClient) {
    const clientMode = state.organization?.selectedClient?.mode;
    const classificationKey = clientMode === client_mode.category ? "categories" : "projects"
    return state.client?.[classificationKey]?.[state.organization.selectedClient.clientID]
  }
}
export const certificatesState = state => ({
  certificates: state.client.certificates,
  paginatedCertificates: state.client.paginatedCertificates,
  certificatesCount: state.client.certificatesCount
})
export const clientActions = {
  ...clientSlice.actions,
  ...actions,
}

export default clientSlice.reducer
