import React, {useEffect} from "react"
import {useSelector, useDispatch} from "react-redux";
import {Routes, useLocation, useParams} from "react-router-dom";
import {organizationActions, organizationState} from "redux/organizationSlice";

export default function({children}) {
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { selectedAgency, selectedClient } = useSelector(organizationState)

  const populateOrganizations = async () => {
    try {
      await dispatch(organizationActions.populateOrganizations({
        location,
        params
      }))
    } catch (e) {
      window.location.href = "/"
    }
  }

  useEffect(() => {
    if(!selectedAgency || !selectedClient) {
      populateOrganizations()
    }
  }, [selectedAgency, selectedClient])

  return (
    <Routes>
      {
        children
      }
    </Routes>
  )
}
