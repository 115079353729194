import React, {useRef} from "react";
import {Button} from "@mui/material";
import Modal from "components/shared/Modal";
import LoaderButton from "components/shared/LoaderButton";
import CoverageForm from "components/shared/CoverageForm";

export default ({
  title="Update Coverage Requirements",
  open,
  onClose,
  onSubmit,
  onUpdate,
  onAdd,
  loading=false,
  coverages=[],
  clientCoverages=[],
  classCoverages=[],
  clientMode,
  showHelperText=false,
  classificationID,
  classificationIDKey,
}) => {
  const formRef = useRef();
  function handleSubmit() {
    const coverageData = formRef.current.getCoverageData()
    onSubmit && onSubmit(coverageData)
  }
  return (
    <Modal
      open={open}
      maxWidth={"lg"}
      maxHeight={true}
      title={title}
      content={
        <CoverageForm
          open={open}
          ref={formRef}
          isEdit={true}
          coverages={coverages}
          clientCoverages={clientCoverages}
          classCoverages={classCoverages}
          onUpdate={onUpdate}
          onAdd={onAdd}
          clientMode={clientMode}
          showHelperText={showHelperText}
          classificationIDKey={classificationIDKey}
          classificationID={classificationID}
        />
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <LoaderButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
          >Update</LoaderButton>
        </>
      }
    />
  )
}
