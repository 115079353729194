import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {loggedInUserState} from "redux/userSlice";

import {
  TextField,
  Button,
  Box,
  Avatar,
  Collapse,
} from "@mui/material";
import {Feed, Menu, List} from "semantic-ui-react"
import {SubtitleInline} from "components/shared/Typography";
import DeleteConfirmationModal from "components/shared/Modal/DeleteConfirmationModal";
import LoaderButton from "components/shared/LoaderButton";
import Modal from "components/shared/Modal"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from '@mui/icons-material/Delete';


const TABS = ["COMMENTS", "ACTIVITY HISTORY"]

export default function CertificateOtherInfoSection({
  certificateData,
  handleCertificateSubmit,
  loading,
  certificateActivities,
  onDeleteCallback=()=>{},
}) {

  const comments = useMemo(() => {
    let unsorted = certificateData?.comments || []
    if(typeof unsorted === "string") {
      unsorted = JSON.parse(unsorted)
    }
    return unsorted?.sort((a,b) => a.time > b.time ? -1 : 1)

  }, [certificateData?.comments])

  const [addingComment, setAddingComment] = useState(false)
  const [viewTab, setViewTab] = useState("COMMENTS")
  const [newComment, setNewComment] = useState("")
  const [commentToDelete, setCommentToDelete] = useState()
  const { loggedInUser } = useSelector(loggedInUserState)
  const [showActivityDetail, setShowActivityDetail] = useState({ "0": true })
  const [deleteCertificate, setDeleteCertificate] = useState(false);
  const [deleting, setDeleting] = useState(false)


  function onCancelDelete () {
    setDeleteCertificate(false);
  }

  async function onDeleteCertificate () {
    setDeleting(true)
    await onDeleteCallback();
    setDeleting(false)
    setDeleteCertificate(false)
  }

  async function onSaveComment() {
    if(newComment) {
      const commentsToSave = [...comments]
      const firstName = loggedInUser?.firstName || ""
      const lastName = loggedInUser?.lastName ? ` ${loggedInUser.lastName}` : ""
      commentsToSave.push({
        time: Date.now(),
        content: newComment,
        author: `${firstName}${lastName}`
      })
      const result = await handleCertificateSubmit({comments: JSON.stringify(commentsToSave)})
      if(result?.success) {
        onCancelComment()
      }
    } else {
      onCancelComment()
    }
  }

  function onCancelComment() {
    setAddingComment(false)
    setNewComment("")
  }

  function onDeleteComment(timestamp) {
    setCommentToDelete(timestamp)
  }

  async function confirmDeleteComment() {
    const commentsToSave = [...comments].filter(comment => comment.time !== commentToDelete)
    const result = await handleCertificateSubmit({comments: JSON.stringify(commentsToSave)})
    if(result?.success) {
      onCancelComment()
      setCommentToDelete(undefined)
    }
  }

  return (
    <Box className={"other-info-section"}>
      <DeleteConfirmationModal
        message={"certificate"}
        open={deleteCertificate}
        onClose={onCancelDelete}
        onDelete={onDeleteCertificate}
        loading={deleting}
      />
      <Modal
        open={!!commentToDelete}
        title={"Delete comment?"}
        content={"Once deleted, you will not be able to get it back."}
        actions={
          <Box>
            <Button onClick={()=>setCommentToDelete(undefined)}>Cancel</Button>
            <LoaderButton loading={loading} variant={"contained"} onClick={confirmDeleteComment}>Delete</LoaderButton>
          </Box>
        }
      />
      <Box sx={{mt: 1}}>
        <Menu text className="status-filter" style={{alignItems: "center", marginLeft: 0, marginTop: 0}}>
          <Menu.Item>Showing:</Menu.Item>
          {
            TABS.map(tab => (
              <Menu.Item active={viewTab === tab} onClick={() => setViewTab(tab)} key={tab}>
                <SubtitleInline>{tab}</SubtitleInline>
              </Menu.Item>
            ))
          }
          <Button variant="text" color="error" startIcon={<DeleteIcon />} disabled={deleteCertificate}  style={{margin: "0 1rem 0 auto"}} onClick={() => setDeleteCertificate(true)}>Delete Certificate</Button>
        </Menu>
      </Box>
      {
        viewTab === TABS[0] &&
        <>
          <TextField
            sx={{mt:1}}
            fullWidth
            multiline
            rows={addingComment ? 4 : 1}
            placeholder={"Add a new comment..."}
            name="comments"
            value={newComment}
            onChange={event => setNewComment(event.target.value)}
            onFocus={()=>setAddingComment(true)}
          />
          {
            addingComment &&
            <Box sx={{mt: 1, textAlign: "right"}}>
              <Button variant={"outlined"} sx={{mr: 1}} onClick={onCancelComment}>Cancel</Button>
              <LoaderButton variant={"contained"} loading={loading} onClick={onSaveComment}>Save</LoaderButton>
            </Box>
          }
          <Box
            sx={{
              mt: 2,
              maxHeight: "1000px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "2px"
              }
            }}
          >
            <Feed>
              {
                !!comments?.length && comments.map(comment => {
                  const nameParts = String(comment.author).split(/\s/)
                  const commentTime = new Date(comment.time)
                  return (
                    <Feed.Event key={comment.time}>
                      <Feed.Label>
                        <Avatar sx={{ width: 35, height: 35, mt: "0.5em" }}>
                          {
                            comment.author &&
                            (
                              String(nameParts?.[0]).charAt(0).toUpperCase()
                              + String(nameParts?.[1] || "").charAt(0).toUpperCase()
                            )
                          }
                        </Avatar>
                      </Feed.Label>
                      <Feed.Content>
                        <Feed.Summary>
                          {comment.author}
                          <Feed.Date>{`${commentTime.toLocaleDateString()} at ${commentTime.toLocaleTimeString()}`}</Feed.Date>
                        </Feed.Summary>
                        <Feed.Extra text>
                          {comment.content}
                        </Feed.Extra>
                        <Feed.Meta>
                          <Feed.Like
                            className={"delete-btn"}
                            onClick={()=>onDeleteComment(comment.time)}
                          >Delete</Feed.Like>
                        </Feed.Meta>
                      </Feed.Content>
                    </Feed.Event>
                  )
                })
              }
            </Feed>
          </Box>
        </>
      }
      {
        viewTab === TABS[1] && !!certificateActivities?.length &&
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: "1200px",
            "&::-webkit-scrollbar": {
              width: "2px"
            }
          }}
        >
          <Feed>
            {
              certificateActivities.map((activity, index) => {
                let updates = JSON.parse(activity.field || "{}")
                const certificateUpdates = updates.certificate;
                const coverageUpdates = updates.coverages;
                const fileUpdates = updates.files;
                const projectUpdates = updates.project;
                const updateSections = []
                let updatedSectionDesc;
                certificateUpdates && updateSections.push("information");
                coverageUpdates && updateSections.push("coverages");
                fileUpdates && updateSections.push("files");
                projectUpdates && updateSections.push("project");

                if(updateSections.length === 1) {
                  updatedSectionDesc = updateSections[0]
                } else if(updateSections.length === 2) {
                  updatedSectionDesc = `${updateSections[0]} and ${updateSections[1]}`
                } else if(updateSections.length === 2) {
                  updatedSectionDesc = `${updateSections[0]}, ${updateSections[1]} and ${updateSections[2]}`
                }
                return (
                  <Feed.Event key={"activity-" + index}>
                    <Feed.Label>
                      <Avatar>
                        {
                          String(activity.userFullName)
                            .split(" ")
                            .map(part => part.charAt(0))
                            .join("")
                            .toUpperCase()
                        }
                      </Avatar>
                    </Feed.Label>
                    <Feed.Content>
                      <Feed.Date>{(new Date(activity.createdAt)).toLocaleString("en-us")}</Feed.Date>
                      <Feed.Summary style={{display: "flex", alignItems: "baseline"}}>
                        <Box>
                          <span style={{fontWeight: "600"}}>{activity.userFullName}</span>
                          {
                            activity.action === "CREATE" ? ` created certificate` : ` updated certificate's ${updatedSectionDesc}`
                          }
                        </Box>
                        <Button
                          size="small"
                          sx={{ml: "auto", flexShrink: 0}}
                          onClick={()=>setShowActivityDetail({...showActivityDetail, [index]: !showActivityDetail[index]})}
                        >
                          {`${!!showActivityDetail[index] ? "Hide" : "Show"} details`}
                        </Button>
                      </Feed.Summary>
                      {
                        !!Object.keys(updates).length &&
                        <Collapse in={!!showActivityDetail[index]}>
                          <Feed.Extra text>
                            <List bulleted={true}>
                              {
                                Object.entries({
                                  ...(certificateUpdates || {}),
                                  ...(coverageUpdates || {}),
                                  ...(fileUpdates || {}),
                                  ...(projectUpdates || {}),
                                }).map(([fieldName, updates]) => {
                                  if(fieldName !== "requestedNewValidCertificateURL")  {
                                  return (
                                    <List.Item key={`${activity.id}_${fieldName}`}>
                                      <p style={{textTransform: "capitalize", fontWeight: "600"}}>{fieldName}</p>
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {
                                        updates.prev
                                          ? <p style={{flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere"}}>{updates.prev}</p>
                                          : <p style={{color: "darkgray"}}>{"blank"}</p>
                                      }
                                      <ArrowForwardIcon sx={{fontSize: 16, ml: 1, mr: 1}}/>
                                      {
                                        updates.next
                                          ? <p style={{flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere"}}>{updates.next}</p>
                                          : <p style={{color: "darkgray"}}>{"blank"}</p>
                                      }
                                      </Box>
                                    </List.Item>
                                  )
                                  } else {
                                    return (
                                      <List.Item key={`${activity.id}_${fieldName}`}>
                                        <p style={{textTransform: "capitalize", fontWeight: "600"}}><a href={updates.next}>{fieldName}</a></p>
                                      </List.Item>
                                    )
                                }
                                })
                              }
                            </List>
                          </Feed.Extra>
                        </Collapse>
                      }
                    </Feed.Content>
                  </Feed.Event>
                )
              })
            }
          </Feed>
        </Box>
      }
    </Box>
  )
}
