import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';

import { Provider as ReduxProvider } from "react-redux"
import {Amplify}  from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import awsconfig from "./aws-exports";


import store from "redux/store"
import App from './App';

Amplify.configure(awsconfig)

const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans", "Roboto", "Arial", "Helvetica", "sans-serif"'
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </ReduxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('app')
);
