import React, {useState, useMemo} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
} from "@mui/material";
import SortableTHeadCell from "components/shared/SortableTHeadCell";
import ActionMenu from "components/shared/ActionMenu";
import cognito_groups from "constants/cognito_groups";
import {findInStringsIgnoreCase} from "utils/stringUtils";

const columns = {
  "firstName": "First Name",
  "lastName": "Last Name",
  "email": "Email",
  "role": "Role",
  "status": "Status",
  "actions": ""
}

const roleDisplayMapping = {
  [cognito_groups.SystemAdmin]: "Admin",
  [cognito_groups.AgencyAdmin]: "Admin",
  [cognito_groups.ClientAdmin]: "Admin",
  [cognito_groups.ClientViewer]: "Viewer",
}

function UserRow(props={}) {
  const {
    onViewUser = () => {},
    disableUser = () => {},
    enableUser = () => {},
    deleteUser = () => {},
  } = props
  return (
    <TableRow>
      <TableCell>
        <Avatar>
          {
            (props.firstName || props.lastName)
            ? props.firstName?.charAt(0).toUpperCase() + props.lastName?.charAt(0).toUpperCase()
            : props.email?.charAt(0).toUpperCase()
          }
        </Avatar>
      </TableCell>
      {
        Object.keys(columns).map(columnKey => {
          if(columnKey === 'actions') {
            const actions = [
              {label: "Update User", onClick: () => onViewUser(props.email)},
              {
                label: `${props.status === "DISABLED" ? "Enable" : "Disable"} User`,
                onClick: () => {
                  props.status === "DISABLED" ? enableUser(props) : disableUser(props)
                }
              },

            ]
            if(props.status === "DISABLED") {
              actions.push({label: "Delete User", onClick: () => { deleteUser(props) }})
            }
            return props.canEdit
              ? <TableCell>
                  <ActionMenu
                    actions={actions}
                  />
                </TableCell>
              : undefined
          }
          return (
            <TableCell align="left" key={columnKey + "Cell"}>
              {
                columnKey === 'role' && roleDisplayMapping[props[columnKey]]
              }
              {
                columnKey === 'status' && String(props[columnKey] || "active").toLowerCase()
              }
              {
                !(['role', 'status'].includes(columnKey)) && props[columnKey]
              }
            </TableCell>
          )
        })
      }
    </TableRow>
  )
}

export default ({
  users,
  onViewUser,
  searchPhrase,
  canEdit = false,
  disableUser,
  enableUser,
  deleteUser,
}) => {
  const [sortBy, setSortBy] = useState("lastName")
  const [sortAsc, setSortAsc] = useState(true)
  const theadProps = {sortBy, setSortBy, sortAsc, setSortAsc}
  const filteredUsers = useMemo(() => {
    if(users?.length) {
      let toSort = [...users]
      if(searchPhrase) {
        toSort =  toSort.filter(user => findInStringsIgnoreCase(searchPhrase, [
          String(user.firstName),
          String(user.lastName),
          String(user.email),
          String(user.role),
          String(user.status || "active"),
        ]))
      }
      return toSort.sort((a,b) => {
        let larger = (a?.[sortBy] || "") > (b?.[sortBy] || "")
        if(larger) {
          return sortAsc ? 1 : -1
        }
        return sortAsc ? -1 : 1
      })
    }
    return users
  }, [users, sortBy, sortAsc, searchPhrase])

  return (
    <TableContainer style={{overflow: "visible"}} className="compact-table">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {
              Object.keys(columns).map((column, i) => {
                if(column === "actions") {
                  return canEdit ? <TableCell align={"center"} /> : undefined
                }
                return (
                  <SortableTHeadCell
                    align={"left"}
                    label={columns[column]}
                    key={"header" + i}
                    sortId={column}
                    {...theadProps}
                  />
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredUsers?.map((user, index) => (
              <UserRow
                key={"user_row" + index}
                {...user}
                onViewUser={onViewUser}
                disableUser={disableUser}
                enableUser={enableUser}
                deleteUser={deleteUser}
                canEdit={canEdit}
              />
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
