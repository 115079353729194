import React from "react";
import {TableCell} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import {Icon} from "semantic-ui-react";

export default function SortableTHeadCell({sortId, label, align, sortBy, sortAsc, setSort, setSortBy, setSortAsc, setNextTokens, ...props}) {
  return (
    <TableCell
      align={align || "left"}
      sortDirection={sortBy === sortId ? (sortAsc ? "asc" : "desc") : false}
      {...props}
    >
      <TableSortLabel
        IconComponent={sortBy === sortId ? undefined : () => <Icon disabled name='sort' style={{marginBottom: "0.40em"}}/>}
        className={sortBy === sortId ? "sort-icon-active" : "sort-icon-inactive"}
        hideSortIcon={false}
        active={sortBy === sortId}
        direction={sortBy === sortId ? (sortAsc ? "asc" : "desc") : "desc"}
        onClick={() => {
          if(setSort) {
            setSort(sortId, sortBy === sortId ? !sortAsc : true, 0)
            if (setNextTokens) {
              setNextTokens([null])
            }
          } else if(sortBy === sortId) {
            setSortAsc && setSortAsc(!sortAsc)
          } else {
            setSortBy && setSortBy(sortId)
            setSortAsc && setSortAsc(true)
          }
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  )
}
