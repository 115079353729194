import React from "react";
import "./modal.scss";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export default ({open, className, onClose, title, content, actions, maxWidth, maxHeight}) => (
  <Dialog
    fullWidth={true}
    maxWidth={maxWidth}
    open={open}
    className={`${className} ${maxHeight ? "max-height" : ""}`}
  >
    {
      title &&
      <DialogTitle component="header">
        {
          (typeof title) === "string"
          ?
          <Typography variant="h5" style={{display: "inline"}}>
            {title}
            {
              !!onClose &&
              <IconButton
                variant="outlined"
                onClick={()=>onClose()}
                sx={{float: "right"}}
              >
                <CloseIcon/>
              </IconButton>
            }
          </Typography>
          :
          <>{ title }</>
        }
      </DialogTitle>
    }
    <DialogContent>

      {content}
    </DialogContent>
    {
      actions &&
      <DialogActions>
        {actions}
      </DialogActions>
    }
  </Dialog>
)
