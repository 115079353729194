import React, {useState} from "react";

import {Menu, MenuItem, Box} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import StatusIndicator from "components/shared/StatusIndicator";

export default ({
  currentStatus = "",
  statuses = {} , // {INACTIVE: {optionLabel: "Make Inactive", label: "Inactive} }
  onUpdateStatus = () => {},
  sx = {},
  size,
  disableSelection
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={sx} component="span">
      <StatusIndicator
        label={statuses[currentStatus]?.label || "No Status"}
        onClick={handleClick}
        icon={!disableSelection ? <KeyboardArrowDownIcon /> : null}
        size={size}
      />
      {
        !disableSelection &&
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          id="demo-simple-select"
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {
            Object.entries(statuses).map(([key, props]) => (
              currentStatus !== key &&
              <MenuItem onClick={() => onUpdateStatus(key)} key={"status-indicator-option-" + (props.optionLabel || props.label)}>
                {props.optionLabel || props.label}
              </MenuItem>
            ))
          }
        </Menu>
      }
    </Box>
  )
}
