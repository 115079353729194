import React from "react";
import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";

const Wrapper = styled('div')`
  background-color: rgba(219, 40, 40, 0.04);
  font-family: sans-serif;
  padding: 20px 25px;
`
const ImageWrapper = styled('div')`
  width: 100%;
  padding-bottom: 10px;
`
const ContentWrapperOuter = styled('div')`
  background-color: white;
  width: 100%;
`

const ContentWrapperInner = styled('div')`
  padding: 20px;
`

const H1 = styled('h1')`
  font-weight: normal;
`

export default function EmailTemplate({
  template = {},
  isEdit,
  onFieldUpdate,
  vendorName = "[Vendor Name]",
  uploadLink
}) {
  let { subject = "", projectList, content = "", signature="" } = template
  const lineBreak = "<br />"
  content = content.replace(/\n/g, `${lineBreak}`)
  signature = signature.replace(/\n/g, `${lineBreak}`)

  return (
    <Wrapper>
      <ImageWrapper>
        <img height="60" src="https://dev.coiguard.com/app-logo-h110.png" />
      </ImageWrapper>
      <ContentWrapperOuter>
        <ContentWrapperInner>
          {
            !isEdit && <H1>{subject}</H1>
          }
          {
            isEdit &&
            <TextField
              name={"subject"}
              fullWidth
              variant={"outlined"}
              label={"Subject"}
              value={subject}
              sx={{mb: 3}}
              onChange={onFieldUpdate}
            />
          }
          <br/>
          <div>
            <p>Hello {vendorName},</p>
            {
              projectList && 
              <div style={{marginTop: '2rem', marginBottom: '1rem'}} dangerouslySetInnerHTML={{__html: projectList}} />
            }
            {
              !isEdit &&
              <div dangerouslySetInnerHTML={{__html: content}} />
            }
            {
              isEdit &&
              <TextField
                name={"content"}
                fullWidth
                multiline
                label={"Main Content"}
                rows={8}
                value={template?.content}
                sx={{mt: 2, mb: 1}}
                onChange={onFieldUpdate}
              />
            }
            {
              uploadLink &&
              <>
                <br />
                <p>Please upload your certificate file using <a href={uploadLink}>this link</a>.</p>
              </>
            }
            <br />
            {
            !isEdit &&
            <div dangerouslySetInnerHTML={{__html: signature}} />
          }
          {
            isEdit &&
            <TextField
              name={"signature"}
              fullWidth
              multiline
              variant={"outlined"}
              label={"Signature"}
              value={template?.signature}
              sx={{mb: 3}}
              onChange={onFieldUpdate}
            />
          }
          </div>
        </ContentWrapperInner>
      </ContentWrapperOuter>
    </Wrapper>
  )
}
