// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Insuranceform = {
  "CLAIMS_MADE": "CLAIMS_MADE",
  "OCCURRENCE": "OCCURRENCE",
  "OCCUR": "OCCUR"
};

const Insurancetype = {
  "GL": "GL",
  "AU": "AU",
  "WC": "WC",
  "UMB": "UMB",
  "XL": "XL",
  "CY": "CY",
  "PL": "PL",
  "CUSTOM": "CUSTOM"
};

const Certificatestatus = {
  "APPROVED": "APPROVED",
  "PENDING": "PENDING",
  "EXPIRED": "EXPIRED",
  "REVIEW": "REVIEW",
  "REQUESTED": "REQUESTED",
  "NOT_APPROVED": "NOT_APPROVED",
  "INACTIVE": "INACTIVE"
};

const Projectstatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "ARCHIVED": "ARCHIVED"
};

const Vendorstatus = {
  "COMPLIANT": "COMPLIANT",
  "NOT_COMPLIANT": "NOT_COMPLIANT",
  "ARCHIVED": "ARCHIVED"
};

const Vendornotificationstatus = {
  "ACTIVE": "ACTIVE",
  "DISABLED": "DISABLED"
};

const Organizationtype = {
  "SYSTEM": "SYSTEM",
  "AGENCY": "AGENCY",
  "CLIENT": "CLIENT"
};

const Clientmode = {
  "PROJECT": "PROJECT",
  "CATEGORY": "CATEGORY",
  "HYBRID": "HYBRID"
};

const Userstatus = {
  "ACTIVE": "ACTIVE",
  "DISABLED": "DISABLED",
  "DELETED": "DELETED"
};

const Scanstatus = {
  "PENDING": "PENDING",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED",
  "SUBMITTED": "SUBMITTED",
  "FAILED": "FAILED"
};

const Activecertificatesndexname = {
  "BY_CLIEN_TSORT_UPDATEDAT": "byCLIENTsortUPDATEDAT",
  "BY_CLIEN_TSORT_CREAEDAT": "byCLIENTsortCREAEDAT",
  "BY_CLIEN_TSORT_FIRST_EXP": "byCLIENTsortFirstExp",
  "BY_CLIEN_TSORT_PRODUCER": "byCLIENTsortPRODUCER",
  "BY_CLIEN_TSORT_INSURED": "byCLIENTsortINSURED",
  "BY_CLIEN_TSORT_CERTIFICATEDATE": "byCLIENTsortCERTIFICATEDATE",
  "BY_CLIENT": "byCLIENT"
};

const { CERTIFICATE, CERTIFICATECOVERAGE, VENDORCOVERAGE, CLIENTCOVERAGE, PROJECT, PROJECTVENDORS, CATEGORY, CATEGORYVENDORS, CLIENTVENDORS, INSURANCEAGENT, VENDOR, USER, CLIENT, ORGANIZATION, EMAILNOTIFICATIONS, ACORD25SCAN, ACORD25SCANCOVERAGE, ACTIVITY, ModelCERTIFICATEConnection } = initSchema(schema);

export {
  CERTIFICATE,
  CERTIFICATECOVERAGE,
  VENDORCOVERAGE,
  CLIENTCOVERAGE,
  PROJECT,
  PROJECTVENDORS,
  CATEGORY,
  CATEGORYVENDORS,
  CLIENTVENDORS,
  INSURANCEAGENT,
  VENDOR,
  USER,
  CLIENT,
  ORGANIZATION,
  EMAILNOTIFICATIONS,
  ACORD25SCAN,
  ACORD25SCANCOVERAGE,
  ACTIVITY,
  Insuranceform,
  Insurancetype,
  Certificatestatus,
  Projectstatus,
  Vendorstatus,
  Vendornotificationstatus,
  Organizationtype,
  Clientmode,
  Userstatus,
  Scanstatus,
  Activecertificatesndexname,
  ModelCERTIFICATEConnection
};